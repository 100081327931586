import { StoreDispatch } from '@app/store/dispatch'
import { createThunk, ThunkAction } from '@app/store/thunk'

import { Context } from './Context'

export function getContext() {
  return createThunk((_dispatch, _getState, context) => {
    if (!context[CurrentContextSymbol]) {
      context[CurrentContextSymbol] = new Context()
    }
    const ctx: Context = context[CurrentContextSymbol]
    return ctx
  })
}

export function withIsolatedContext<R = unknown>(action: ThunkAction<R>) {
  return createThunk(dispatch => {
    const ctx = new Context()
    return dispatch(withContext(ctx, action))
  })
}

export function withCurrentContext<R = unknown>(action: ThunkAction<R>) {
  return createThunk(dispatch => {
    return dispatch(withContext(dispatch(getContext()), action))
  })
}

export function withChildContext<R = unknown>(action: ThunkAction<R>) {
  return createThunk(dispatch => {
    return dispatch(withContext(dispatch(getContext()).getChildContext(), action))
  })
}

export function withContext<R = unknown>(ctx: Context, action: ThunkAction<R>) {
  return createThunk((dispatch, getState, context) => {
    const currentContext = { ...context, [CurrentContextSymbol]: ctx, [ParentContextSymbol]: context }
    const pdispatch: StoreDispatch = (action: any) => (typeof action === 'function' ? action(pdispatch, getState, currentContext) : dispatch(action))
    return pdispatch(action)
  })
}

export function withOriginalContext<R = unknown>(action: ThunkAction<R>) {
  return createThunk((dispatch, getState) => {
    const rootContext = dispatch(getRootContext())
    const pdispatch: StoreDispatch = (action: any) => (typeof action === 'function' ? action(pdispatch, getState, rootContext) : dispatch(action))
    return pdispatch(action)
  })
}

export function getRootContext() {
  return createThunk((_dispatch, _getState, context) => {
    while (true) {
      if (!context[ParentContextSymbol]) return context
      context = context[ParentContextSymbol]
    }
  })
}

const ParentContextSymbol = Symbol('ParentContextSymbol')
const CurrentContextSymbol = Symbol('CurrentContextSymbol')
