import React, { FunctionComponent } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { Modal } from '@app/components/ModalV2/Modal'
import { ModalContent, ModalSeparator } from '@app/components/ModalV2/ModalLayout'
import { MountedComponentProps } from '@app/components/Mount/types'
import { Spinner } from '@app/components/Spinner/Spinner'

export const LoadingModal: FunctionComponent<MountedComponentProps & { name: string }> = ({ mount, name }) => {
  const { formatMessage } = useIntl()

  return (
    <Modal contentWidth={350} mount={mount} name={name} titleType="invisible">
      <ModalSeparator />
      <ModalContent>
        <Spinner animating={true}>{formatMessage(messages.loading)}</Spinner>
      </ModalContent>
      <ModalSeparator />
    </Modal>
  )
}

const messages = defineMessages({
  loading: 'Загрузка...',
})
