import queryString from 'query-string'

import { getApplicationAgent } from '@app/store/actions/applicationAgent'
import { createThunk } from '@app/store/thunk'

export function headers(extraHeaders?: { [key: string]: string }, { formData }: { formData?: boolean } = {}) {
  return createThunk((dispatch, getState) => {
    const { session, config, server, locale } = getState()
    return Object.assign(
      {
        'X-Kidsout-Session-Id': session.uuid,
        'X-Kidsout-App-Request-Id': config.appRequestId,
        'accept-language': locale,
        'Application-Agent': dispatch(getApplicationAgent()).agent,
      },
      formData ? {} : { 'content-type': 'application/json' },
      server ? { 'x-forwarded-for': server.ips.length ? server.ips.join(', ') : server.ip } : null,
      session.access_token ? { Authorization: `Bearer ${session.access_token}` } : null,
      extraHeaders
    )
  })
}

export function path(pathname: string, query?: { [key: string]: any }) {
  return createThunk((_dispatch, getState) => {
    return getPath(pathname, query, getState().config)
  })
}

export function getPath(pathname: string, query: { [key: string]: any } | undefined, config: { apiUrl: string; proxy?: boolean; port: string }) {
  const fullPath = pathname + (query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : '')
  if (config.proxy) {
    if (IS_BROWSER) {
      return fullPath
    } else if (config.proxy) {
      return `http://localhost:${config.port}${fullPath}`
    }
  }
  return `${config.apiUrl}${fullPath}`
}

export function internal(pathname: string, query?: { [key: string]: any }) {
  return createThunk((_dispatch, getState) => {
    const { config } = getState()
    const fullPath = '/internal' + pathname + (query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : '')
    if (IS_BROWSER) return fullPath
    return `http://localhost:${config.port}${fullPath}`
  })
}

export const api = {
  headers,
  path,
  internal,
  getPath,
}
