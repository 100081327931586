import { CLIENT_ERROR_CODE } from '@app/constants/Misc'

export class AppError extends Error {
  localizedMessage?: string
  status: number = CLIENT_ERROR_CODE
  expose: boolean = false
  handled: boolean = false

  withStatus(status: number) {
    this.status = status
    return this
  }

  withLocalizedMessage(localizedMessage: string) {
    this.localizedMessage = localizedMessage
    return this
  }

  withExpose(expose: boolean) {
    this.expose = expose
    return this
  }

  withHandled(handled: boolean) {
    this.handled = handled
    return this
  }

  withCause(cause: unknown, { liftStatus = true, liftHandled = true }: { liftStatus?: boolean; liftHandled?: boolean } = {}) {
    this.cause = cause
    if (typeof cause === 'object' && cause) {
      if (liftHandled && 'handled' in cause && typeof cause?.handled === 'boolean') this.handled = cause.handled
      if (liftStatus && 'status' in cause && typeof cause?.status === 'number' && cause.status !== CLIENT_ERROR_CODE) this.status = cause.status
    }
    return this
  }
}
