import { v4 } from 'uuid'

import { omit } from '@app/utils/omit'

import { createThunk } from '@app/store/thunk'

const setReduxSliceType = '@REDUX_SLICE/setReduxSlice'
export type SetReduxSliceAction<T = unknown> = { type: typeof setReduxSliceType; payload: { container: string; key: string; data: T } }
const setReduxSlice = (payload: { container: string; key: string; data: any }): SetReduxSliceAction => ({ type: setReduxSliceType, payload })

export const isSetReduxSliceAction = (action: { type: string }): action is SetReduxSliceAction => action.type === setReduxSliceType

export class ReduxSliceContainer {
  keys: string[] = []
  subreducers: { key: string; actionType: string; reducer: (state: any, action: any) => any }[] = []
  reducerKey: string

  constructor(reducerKey?: string) {
    this.reducerKey = reducerKey ?? `redux_slice_container_${v4()}`
  }

  reducer = (state: Record<string, any> = {}, action: { type: string }) => {
    if (isSetReduxSliceAction(action) && action.payload.container === this.reducerKey) {
      return { ...state, [action.payload.key]: action.payload.data }
    }
    for (const subreducer of this.subreducers) {
      state = subreducer.reducer(state, action)
    }
    return state
  }

  createSlice = <T>(key: string) => {
    if (this.keys.includes(key)) throw new Error(`Redux slice with key ${key} already exists`)
    this.keys.push(key)

    const obj = {
      key,
      selector: (state: unknown) => (state as any)[this.reducerKey]?.[key] as T | undefined,
      get: () => createThunk((_dispatch, getState) => getState()[this.reducerKey][key] as T | undefined),
      set: (value: T) => setReduxSlice({ container: this.reducerKey, key, data: value }),
      update: (cb: (val: T | undefined) => T | undefined) =>
        createThunk(dispatch => dispatch(setReduxSlice({ container: this.reducerKey, key, data: cb(dispatch(obj.get())) }))),
      remove: () => setReduxSlice({ container: this.reducerKey, key, data: undefined }),
      addCase: <A extends { type: string }>(targetAction: A, cb: (val: T | undefined, action: A) => T | undefined) => {
        if (this.subreducers.find(r => r.key === key && r.actionType === targetAction.type))
          throw new Error(`Reducer for ${key}:${targetAction.type} already exists`)

        this.subreducers.push({
          key,
          actionType: targetAction.type,
          reducer: (state, action) => {
            if (action.type !== targetAction.type) return state
            const val = state[key] as T | undefined
            const newVal = cb(val, action)
            if (typeof newVal === 'undefined') return omit(state, key)
            return { ...state, [key]: newVal }
          },
        })

        return obj
      },
      addCases: <A extends { type: string }>(targetAction: A[], cb: (val: T | undefined, action: A) => T | undefined) => {
        for (const action of targetAction) {
          obj.addCase(action, cb)
        }
        return obj
      },
      cleanup: () => {
        this.keys = this.keys.filter(k => k !== key)
        this.subreducers = this.subreducers.filter(r => r.key !== key)
      },
      [Symbol.dispose]: () => obj.cleanup(),
    }

    return obj
  }
}
