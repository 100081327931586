import { createIntl, IntlShape } from 'react-intl'

import { DEFAULT_LOCALE } from '@app/constants/Locales'

import { INTL_FORMATS } from '@app/utils/IntlUtils'

type IntlMessagesMap = { [key: string]: string }

export class IntlService {
  private fetchTranslations: (locale: string) => Promise<IntlMessagesMap>

  constructor(fetchTranslations: (locale: string) => Promise<IntlMessagesMap>) {
    this.fetchTranslations = fetchTranslations
  }

  async createIntl(locale: string = DEFAULT_LOCALE): Promise<IntlShape> {
    if (locale === DEFAULT_LOCALE) {
      return this.createDefaultIntl()
    }

    const messages = await this.fetchTranslations(locale)

    return createIntl({
      defaultFormats: INTL_FORMATS,
      defaultLocale: DEFAULT_LOCALE,
      formats: INTL_FORMATS,
      locale,
      messages,
      onError: (err: Error) => console.error(err.message),
      onWarn: (msg: string) => console.warn(msg),
    })
  }

  createDefaultIntl(): IntlShape {
    return createIntl({
      defaultFormats: INTL_FORMATS,
      defaultLocale: DEFAULT_LOCALE,
      formats: INTL_FORMATS,
      locale: DEFAULT_LOCALE,
      messages: undefined,
      onError: (err: Error) => console.error(err.message),
      onWarn: (msg: string) => console.warn(msg),
    })
  }
}
