import { createSelector } from 'reselect'

import { createCookie } from '@app/utils/cookies'
import moment from '@app/utils/moment'

import { createReduxSlice } from '@app/store/redux_slice'
import { createThunk } from '@app/store/thunk'
import { BannerLabel, BannersState } from '@app/store/types/banners'

const uiBannersSlice = createReduxSlice<BannersState>('ui_banners')

export const bannersStateSelector = createSelector(uiBannersSlice.selector, (state): BannersState => state ?? {})

export function restoreBannersState() {
  return createThunk((dispatch, _getState, { cookies }) => {
    try {
      const state = bannersStateCookie.get(cookies)
      dispatch(uiBannersSlice.set(state))
    } catch {
      dispatch(uiBannersSlice.set({}))
    }
  })
}

export function updateBannersState(state: Partial<BannersState>) {
  return createThunk((dispatch, _getState, { cookies }) => {
    try {
      const prevState = bannersStateCookie.get(cookies)
      bannersStateCookie.set(cookies, { ...prevState, ...state })
    } catch {}
    dispatch(uiBannersSlice.update(s => ({ ...s, ...state })))
  })
}

export function clearBannersState() {
  return createThunk((dispatch, _getState, { cookies }) => {
    try {
      bannersStateCookie.rm(cookies)
    } catch {}
    dispatch(uiBannersSlice.set({}))
  })
}

const shortlabels = {
  add_telegram: 'adt' as const,
  app_store: 'aps' as const,
  commission_payment: 'cp' as const,
  online_announcement: 'oa' as const,
  push_notifications: 'pn' as const,
  social_network_bind: 'snb' as const,
} satisfies Record<BannerLabel, string>

const bannersStateCookie = createCookie<BannersState>(
  'kidsout__banners-state',
  data =>
    Object.fromEntries(
      data.split('\n').flatMap<[string, { value: boolean; expire?: number }][]>(line => {
        try {
          const [key, value, expire] = line.split(':')
          for (const [longkey, shortkey] of Object.entries(shortlabels)) {
            if (key === shortkey) {
              return [[longkey, { value: value === '1', expire: expire ? parseInt(expire, 10) : undefined }]]
            }
          }
          return []
        } catch {
          return []
        }
      })
    ),
  raw =>
    Object.entries(raw)
      .map(([key, value]) => {
        for (const [longkey, shortkey] of Object.entries(shortlabels)) {
          if (key === longkey) {
            return `${shortkey}:${value.value ? '1' : '0'}:${value.expire || ''}`
          }
        }
        return ''
      })
      .filter(Boolean)
      .join('\n'),
  () => ({ expires: moment().add(1, 'year').toDate() })
)
