import { validate } from 'isemail'

// eslint-disable-next-line no-control-regex
const emailNameRegex = /[^\u{0000}-\u{007F}]+/u

export function isValidEmail(email: string) {
  if (!validate(email)) return false
  const [name] = email.split('@')
  return !emailNameRegex.test(name)
}
