import { ErrorResponse, ErrorResponseItem } from '@app/constants/ApiTypes/misc'

import { AppError } from '@app/errors/AppError'

import { isApiError } from '@app/store/apiMiddleware/errors'

import { SerializableError } from './SerializableError'

/** extracts errors from error payload, and returns list of strings */
export function extractErrors(...errors: (Error | null | undefined)[]): string[] {
  return errors.flatMap(extractError).reduce<string[]>((c, e) => {
    if (!e) return c
    if (c.indexOf(e) === -1) c.push(e)
    return c
  }, [])
}

export const extractMetaErrors = (e: Error | undefined | null, label: string): string[] => {
  if (!e) return []
  if (!isApiError(e)) return []

  const err = e.response?.errors?.map(e => e.meta[label]).flatMap(x => (!x ? [] : Array.isArray(x) ? x.filter(x => !!x) : [x])) ?? []

  return err
}

export const extractMetaError = (e: Error | undefined | null, label: string): string | null => {
  const arr = extractMetaErrors(e, label)
  if (!arr.length) return null

  return arr.join(', ')
}

function detailOrTitle(e: ErrorResponseItem): string[] {
  if (e.title && e.detail) return [[e.title, e.detail].join(': ')]
  return e.detail || e.title ? [e.detail || e.title] : []
}

/** extracts errors from error payload, and returns list of strings */
function extractError(error?: Error | null): string[] {
  if (!error) return []

  if (error instanceof SerializableError) return extractError((error.cause as any) ?? null)

  if (error instanceof AppError && error.localizedMessage) return [error.localizedMessage]
  const response: ErrorResponse = (error as any)?.response
  if (response) {
    const list = response.errors.flatMap(detailOrTitle)
    if (list.length) return list
  }

  return [error.message]
}
