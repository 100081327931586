/*
 * This file gets bundled and placed in `/build/assets` along with it's assets
 */

import 'react-dates/initialize'
import './rootStyles'

import { createBrowserHistory } from 'history'
import queryString from 'query-string'
import { createRoot } from 'react-dom/client'
import Cookies from 'universal-cookie'
import { v4 } from 'uuid'

import config from '@app/config'
import { IMPORT_MAP } from '@app/importMap'
import renderApp from '@app/renderers/main'
import { routesSelector } from '@app/routes_list/routes_list'
import { AnalyticsUser } from '@app/types/analytics'

import { CLIENT_ERROR_CODE, IS_PRODUCTION } from '@app/constants/Misc'

import { isAbortError } from '@app/errors/AbortError'

import { AbortControllerService } from '@app/services/AbortControllerService'
import { AmoWidgetService } from '@app/services/AmoWidgetService'
import { Amplitude } from '@app/services/Amplitude'
import { analyticsIdentify, AnalyticsPageviewEvent } from '@app/services/AnalyticsEvent'
import { ApplicationAgent } from '@app/services/ApplicationAgent'
import { ClipboardManager } from '@app/services/ClipboardManager'
import { DataRefreshService } from '@app/services/DataRefreshService'
import { DOMService } from '@app/services/DOMService'
import { ErrorReportController } from '@app/services/ErrorReportController'
import { FacebookLoader } from '@app/services/FacebookLoader'
import { IconsCache } from '@app/services/IconsCache'
import { IntlService } from '@app/services/IntlService'
import { Progress } from '@app/services/Progress/Progress'
import { PromiseManager } from '@app/services/PromiseManager'
import { TitleService } from '@app/services/TitleService'
import type { TwilioManager } from '@app/services/TwilioManager'

import { AmoWidgetApiLoadError } from '@app/utils/AmoWidgetApi'
import { Cache } from '@app/utils/cache'
import { Channel } from '@app/utils/channel'
import * as errorReport from '@app/utils/errorReport/errorReport'
import { init as sentryInit } from '@app/utils/errorReport/errorReportInitClient'
import { loadScript } from '@app/utils/loadScript'
import { RouteDataExtractor } from '@app/utils/RouteDataExtractor'
import { processRoutes } from '@app/utils/routing/actions'
import { RouterFactory, RouterTrackableState } from '@app/utils/routing/BrowserRouter'
import { AsyncRoute, History, LocationDescriptorObject, MatchedRoute } from '@app/utils/routing/types'

import { asError } from '@app/packages/asError/asError'
import { ReactiveValue } from '@app/packages/reactives/ReactiveValue'
import { intoResultAsync, Result, resultOk } from '@app/packages/Result/Result'

import { setApplicationAgent } from '@app/store/actions/applicationAgent'
import { getApplicationBridge, getContext, setApplicationBridge, setLocale } from '@app/store/actions/initial'
import { routerRaiseError, sendBrowserInit } from '@app/store/actions/misc.descriptors'
import { beforeNavigate, navigate } from '@app/store/actions/router.descriptors'
import { ApiInterceptor } from '@app/store/apiMiddleware/ApiInterceptor'
import { StoreContext } from '@app/store/reduxMiddleware/types'
import { profileUserSelector } from '@app/store/selectors/profile'
import { availableRegionsSlugsSelector } from '@app/store/selectors/regions'
import { requestsWithDebtSyncSlice } from '@app/store/selectors/requests'
import createStore, { Store, storeMonitoring, StoreState, waitForValue } from '@app/store/store'

import { reducerWithMutator } from '@app/routes/Polygon/helpers'

export class ClientRunner {
  router!: ReturnType<typeof RouterFactory>
  store!: Store
  cookies!: Cookies
  rootNode!: HTMLElement
  storeContext!: StoreContext
  history!: History
  stateReactive!: ReactiveValue<RouterTrackableState>
  stateReactiveAbortControler: AbortController | undefined
  private twilioManagerChannel = new Channel<TwilioManager | null>()
  private initial = false
  private loadTimestamp: number = (window as any).loadTimestamp

  async load(initialState: StoreState) {
    await this.configureProxy()
    sentryInit({ requestId: initialState.config.appRequestId })

    this.rootNode = document.getElementById('Kidsout')!

    this.cookies = new Cookies(document.cookie)

    this.storeContext = {
      intlService: this.getIntlService(),
      apiInterceptor: new ApiInterceptor(),
      abort: new AbortControllerService(),
      router: null,
      cookies: this.cookies,
      promiseManager: new PromiseManager(),
      twilio: this.twilioManagerChannel.wait(),
      progress: Progress.shared,
      iconCache: new IconsCache().restore((window as any).iconsCache),
    }

    this.history = createBrowserHistory({ forceRefresh: false })
    this.store = createStore(this.storeContext, initialState, initialState.config.isPolygon ? reducerWithMutator : undefined)

    this.store.dispatch(sendBrowserInit())

    ClipboardManager.shared.isApp = this.store.getState().config.appProtocol >= 2
    ClipboardManager.shared.store = this.store

    this.parseUtmCookie()
    await this.configureIntlService()
    await this.configureApiInterceptor()
    this.sendOriginalLocationDataLayer()
    this.configureThirdParty()
    await this.configureApplicationBridge()
    this.configureApplicationAgent()
    this.configureTitleService()
    this.configureDOMService()
    this.configureDataRefresh()
    this.configureDebug()
    this.configureAmplitude()
    this.configureTwilio()
    this.configureFirebase()
    this.configureAmoWidget()

    await this.configureRouter()
  }

  async run() {
    if (this.initial) {
      this.initial = false
    } else {
      this.loadTimestamp = new Date().getTime()
    }

    try {
      const component = await renderApp({
        router: this.router,
        history: this.history,
        store: this.store,
        cookies: this.cookies,
        afterRender: this.handleAfterRender,
      })
      const root = createRoot(this.rootNode)
      root.render(component)
      await waitIdle()
    } catch (error) {
      this.handleError(error)
    }
  }

  errorReporter = new ErrorReportController()

  private handleError = (e: unknown) => {
    this.errorReporter.report(e)

    const error = e ? asError(e) : null
    const errorCode = error && 'status' in error && typeof error.status === 'number' ? error.status : CLIENT_ERROR_CODE
    const message = error?.message
    const localizedMessage = error && 'localizedMessage' in error && typeof error.localizedMessage === 'string' ? error.localizedMessage : message
    this.store.dispatch(routerRaiseError({ errorCode, message, localizedMessage }))
  }

  private async configureRouter() {
    const regions = availableRegionsSlugsSelector(this.store.getState())

    const routingStateResult = await intoResultAsync(
      this.store.dispatch(processRoutes({ location: this.history.location, action: this.history.action, initial: true, performFetch: true }))
    )
    if (routingStateResult.error) {
      this.stateReactive = new ReactiveValue<RouterTrackableState>({
        type: 'error',
        error: routingStateResult.value,
      })
    } else {
      if (!routingStateResult.value) throw new Error('Routing state is missing')
      if (routingStateResult.value.type === 'redirect') {
        const loc = routingStateResult.value.redirect.location
        window.location.replace([loc.pathname, loc.search, loc.hash].join(''))
        return
      }

      this.stateReactive = new ReactiveValue<RouterTrackableState>({
        type: 'success',
        value: routingStateResult.value.state,
      })
    }

    this.history.listen(async (location, action) => {
      if (this.store.getState().config.build.needsReload) {
        this.stateReactive.update(() => ({ type: 'pending', promise: redirectToLocation(location) }))
      } else {
        this.updateStateReactive(location, action)
      }
    })

    const getHash = (routes: Result<AsyncRoute[]>) => {
      if (routes.error) return 'error'
      return routes.value.map(r => (r.routes ? getHash(resultOk(r.routes)) : [r.path, r.event_id].join(':'))).join('\n')
    }

    storeMonitoring(this.store, [routesSelector], ([oldRoutes], [newRoutes]) => {
      if (getHash(oldRoutes) !== getHash(newRoutes)) {
        this.updateStateReactive(this.history.location, this.history.action)
      }
    })

    this.router = RouterFactory(regions, this.history, this.stateReactive)
  }

  private updateStateReactive(location: History['location'], action: History['action']) {
    this.stateReactiveAbortControler?.abort()
    const abortController = new AbortController()
    const statePromise = this.store.dispatch(
      processRoutes({
        location,
        action,
        initial: false,
        performFetch: action === 'POP' ? true : (location.state?.hard ?? true),
        beforeNavigate: state => {
          this.store.dispatch(beforeNavigate({ state, initial: false }))
        },
        onNavigate: state => {
          this.store.dispatch(navigate(state))
        },
        signal: abortController.signal,
      })
    )
    this.stateReactiveAbortControler = abortController
    this.stateReactive.update(() => ({ type: 'pending', promise: statePromise }))

    statePromise
      .then(stateResult => {
        if (abortController.signal.aborted) return
        if (!stateResult) return
        if (stateResult.type === 'redirect') {
          this.history.replace(stateResult.redirect.location)
          return
        }
        this.stateReactive.update(() => ({ type: 'success', value: stateResult.state }))
        if (stateResult.scroll) {
          if (stateResult.scroll === 'save') {
            // pass
          } else {
            window.scrollTo(stateResult.scroll[0], stateResult.scroll[1])
          }
        } else {
          window.scrollTo(0, 0)
        }
      })
      .catch(e => {
        if (abortController.signal.aborted) return
        if (isAbortError(e)) return
        this.stateReactive.update(() => ({ type: 'error', error: e }))
      })
  }

  private configureApplicationAgent() {
    this.store.dispatch(setApplicationAgent(new ApplicationAgent(this.store.getState().config.userAgentData!)))
  }

  private async configureApiInterceptor() {
    if (!this.store.getState().config.isPolygon) return
    const { apiInterceptor } = this.store.dispatch(getContext())
    const { ApiInterceptor } = await import('@app/routes/Polygon/ApiInterceptor')
    const polygonInterceptor = new ApiInterceptor(apiInterceptor)
    polygonInterceptor.interceptInitial()
  }

  private configureThirdParty() {
    if (!this.store.getState().config.isApp && !this.store.getState().config.isPolygon) {
      FacebookLoader.shared.load().catch(e => {
        console.error(e.message)
      })
      setTimeout(() => {
        if (!window.VK)
          loadScript('//vk.com/js/api/openapi.js').catch(e => {
            console.error(e.message)
          })
      }, 10000) // delay third-party scripts loading so they don't decrease pagespeed score
    }
  }

  private async configureProxy() {
    const proxyParam = queryString.parse(window.location.search).proxy
    const proxySwitcherKey = 'kidsout__proxy-enabled'

    /**
     * Due to a bug in early ios versions, attempting to extend built-in object resolves in TypeError,
     * so we disabling proxying as it using such technique (see ../utils/proxy module)
     *
     * https://stackoverflow.com/questions/26044056/typeerror-attempted-to-assign-to-readonly-property-in-angularjs-application-on
     * https://github.com/mozilla/pdf.js/issues/5353
     */
    const isWebSocketOverridePossible = /OS (8|9)_.*? like Mac OS X/.test(window.navigator.userAgent) === false

    if (typeof proxyParam === 'string') {
      localStorage?.setItem(proxySwitcherKey, proxyParam === 'on' ? 'true' : 'false')
    }
    const proxyEnabled = isWebSocketOverridePossible && localStorage?.getItem(proxySwitcherKey) === 'true'

    if (proxyEnabled) {
      const proxy = await import('@app/utils/proxy')
      proxy.enable()
    }
  }

  private configureAmoWidget() {
    AmoWidgetService.shared.store = this.store

    setTimeout(() => {
      AmoWidgetService.shared.start().catch(e => {
        if (e instanceof AmoWidgetApiLoadError) return
        throw e
      })
    }, 10000) // delay start for 10 seconds so it won't decrease pagespeed score
  }

  private getIntlService() {
    const translationsCache = new Cache<{ [key: string]: string }>(
      new Map(Object.entries(JSON.parse((window as any as { translations: string }).translations)).map(([key, data]) => [key, { data }]))
    )

    return new IntlService(async locale => {
      if (translationsCache.has(locale)) return translationsCache.get(locale)
      const resp = await fetch(`/internal/translations/${locale}`)
      if (resp.status !== 200) throw new Error('Failed to fetch translations')
      return resp.json()
    })
  }

  private async configureIntlService() {
    const state = this.store.getState()
    await this.store.dispatch(setLocale(state.locale))
  }

  private configureTitleService() {
    const titleService = new TitleService(this.store)
    titleService.start()
  }

  private configureDOMService() {
    const domService = new DOMService(this.store)
    domService.start()
  }

  private configureDataRefresh() {
    const dataRefreshService = new DataRefreshService(this.store)
    dataRefreshService.initBrowser().then(() => {
      if (document.hasFocus()) dataRefreshService.start()

      window.addEventListener('blur', () => {
        dataRefreshService.stop()
      })
      window.addEventListener('focus', () => {
        dataRefreshService.start()
      })
    })
  }

  private async configureApplicationBridge() {
    if (!this.store.getState().config.isApp) return
    const { ApplicationBridge } = await import('@app/utils/ApplicationBridge')
    const applicationBridge = new ApplicationBridge()
    applicationBridge.store = this.store
    applicationBridge.registerIncomingHandler()
    this.store.dispatch(setApplicationBridge(applicationBridge))
  }

  private configureDebug() {
    if (!IS_PRODUCTION || config.isStaging) {
      ;(window as any).ReduxStore = this.store
    }
  }

  private async configureTwilio() {
    const state = this.store.getState()
    const {
      config: { isPolygon },
      session: { supervisor },
    } = state
    if (!!supervisor || isPolygon) {
      this.twilioManagerChannel.resolve(null)
      return
    }

    await waitForValue(this.store, state => {
      const user = profileUserSelector(state)
      return user && user?.account_type !== 'visitor' ? user : null
    })

    const { TwilioManager } = await IMPORT_MAP.twilioManager()
    TwilioManager.initShared(this.store)
    TwilioManager.shared!.connect()
    this.twilioManagerChannel.resolve(TwilioManager.shared)
  }

  private async configureFirebase() {
    const state = this.store.getState()
    const {
      config: { isPolygon },
    } = state
    if (isPolygon) {
      this.storeContext.firebaseManager = null
    }

    await waitForValue(this.store, state => {
      const user = profileUserSelector(state)
      return user && user?.account_type !== 'visitor' ? user : null
    })

    IMPORT_MAP.firebase.manager().then(async m => {
      const manager = new m.FirebaseManager(this.store)
      manager.addEventListener(async e => {
        if (e.type === 'debt_changed') {
          this.store.dispatch(requestsWithDebtSyncSlice.set({ hash: v4() }))
        }
      })
      await manager.connect()
      this.storeContext.firebaseManager = manager
    })

    IMPORT_MAP.firebase.messagingManager().then(async ({ FirebaseMessagingManager }) => {
      new FirebaseMessagingManager().connect()
    })
  }

  private parseUtmCookie() {
    const params_array = window.location.search.substring(1).split('&')
    const params_result = {}
    for (let i = 0; i < params_array.length; i++) {
      const params_current = params_array[i].split('=')
      params_result[params_current[0]] = typeof params_current[1] === 'undefined' ? '' : params_current[1]
    }
    if (params_result['utm_source']) {
      const date = new Date()
      const postClick = 30
      date.setDate(date.getDate() + postClick)
      document.cookie = 'utm_source=' + params_result['utm_source'] + ';expires=' + date
      document.cookie = 'utm_medium=' + params_result['utm_medium'] + ';expires=' + date
      document.cookie = 'utm_campaign=' + params_result['utm_campaign'] + ';expires=' + date
      document.cookie = 'utm_content=' + params_result['utm_content'] + ';expires=' + date
      document.cookie = 'utm_term=' + params_result['utm_term'] + ';expires=' + date
    }
  }

  private sendOriginalLocationDataLayer() {
    const params_array = window.location.search.substring(1).split('&')
    const params_result = {}
    for (let i = 0; i < params_array.length; i++) {
      const params_current = params_array[i].split('=')
      params_result[params_current[0]] = typeof params_current[1] === 'undefined' ? '' : params_current[1]
    }
    const utm_source = !params_result['utm_source'] ? '' : 'utm_source=' + params_result['utm_source']
    const utm_medium = !params_result['utm_medium'] ? '' : '&utm_medium=' + params_result['utm_medium']
    const utm_campaign = !params_result['utm_campaign'] ? '' : '&utm_campaign=' + params_result['utm_campaign']
    const utm_term = !params_result['utm_term'] ? '' : '&utm_term=' + params_result['utm_term']
    const utm_content = !params_result['utm_content'] ? '' : '&utm_content=' + params_result['utm_content']
    const l_search = utm_source + utm_medium + utm_campaign + utm_term + utm_content

    const win = window as any
    win.dataLayer = win.dataLayer || []
    win.dataLayer.push({
      originalLocation: l_search,
    })
  }

  private configureAmplitude() {
    const state = this.store.getState()
    const {
      config: { isPolygon, amplitude: amplitudeKey, appProtocol, testFlights, appRequestId },
    } = state

    if (isPolygon) return

    Amplitude.shared.addInterceptor(event => !event.startsWith('debug.'))

    Amplitude.shared.init(amplitudeKey, appRequestId)

    if (appProtocol > 1) {
      Amplitude.shared.addInterceptor((event, event_properties) => {
        this.store.dispatch(getApplicationBridge())?.sendMessage({ type: 'amplitude_event', event, event_properties })
        return false
      })
      return
    }

    const user = profileUserSelector(this.store.getState())

    const identify = (user: AnalyticsUser | null) => {
      analyticsIdentify(user)
      errorReport.identify(user)
    }

    const analyticsUser: AnalyticsUser | null = user ? { ...user, test_flights: testFlights } : null
    identify(analyticsUser)

    let metricsUser = user

    this.store.subscribe(() => {
      const prevUser = metricsUser
      metricsUser = profileUserSelector(this.store.getState())

      if (prevUser === metricsUser) return

      if (metricsUser && metricsUser.id && prevUser?.id !== metricsUser.id) {
        const analyticsUser: AnalyticsUser | null = metricsUser ? { ...metricsUser, test_flights: testFlights } : null
        identify(analyticsUser)
      }
    })
  }

  private handleAfterRender = async (location: LocationDescriptorObject, matchedRoutes: MatchedRoute[]) => {
    console.info('[RENDERED]', `${new Date().getTime() - this.loadTimestamp}ms`)

    const state = this.store.getState()
    const {
      meta: { title },
    } = state

    const url = location.pathname! + location.search! + location.hash

    const match = matchedRoutes.at(-1)

    const extractor = new RouteDataExtractor(location, this.store.dispatch)
    const data = match ? await extractor.extract(match.route, match.match) : null
    if (data?.event_id) {
      new AnalyticsPageviewEvent(data.event_id, url, match?.route.path ?? '<unknown>', { ...data.event_params, ...data.event_data }, title)
        .sendDataLayer()
        .sendYandex()
        .sendGoogle()
        .sendAmplitude()
        .sendBreadcrumb()
    }
  }
}

const waitIdle = () =>
  new Promise<void>(resolve =>
    (window.requestIdleCallback ?? setTimeout)(() => {
      resolve()
    })
  )

async function redirectToLocation(location: LocationDescriptorObject): Promise<never> {
  window.location.href = `${location.pathname || ''}${location.search || ''}${location.hash ?? ''}`
  return await new Promise<never>(_resolve => {})
}
