import { createSelector } from 'reselect'

import { omit } from '@app/utils/omit'

import { createReduxSlice } from '@app/store/redux_slice'
import { createThunk } from '@app/store/thunk'

const uiClassNamesSlice = createReduxSlice<{ [key: string]: number }>('ui_classnames')

export const classNameSelector = createSelector(uiClassNamesSlice.selector, state => Object.keys(state ?? {}).join(' '))

export const addClassName = (className: string) =>
  createThunk(dispatch =>
    dispatch(
      uiClassNamesSlice.update(state => {
        state = state ?? {}
        const key = className
        return { ...state, [key]: (state[key] ?? 0) + 1 }
      })
    )
  )

export const removeClassName = (className: string) =>
  createThunk(dispatch =>
    dispatch(
      uiClassNamesSlice.update(state => {
        state = state ?? {}
        const key = className
        if (state[key] <= 1) return omit(state, key)
        if (!state[key]) return state
        return { ...state, [key]: state[key] - 1 }
      })
    )
  )
