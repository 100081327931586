import { useDeferredValue } from 'react'
import uniqBy from 'lodash/uniqBy'
import { useSelector } from 'react-redux'

import { isAbortError } from '@app/errors/AbortError'

import { useAppDispatch } from '@app/utils/redux'

import { withAbortSignal } from '@app/packages/abortContext/actions'
import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { ActionRequiredErrorState, CircularResolveError, resolveActionRequiredState } from '@app/packages/ActionRequiredError/resolveActionRequired'
import { Result } from '@app/packages/Result/Result'
import { StackContextValue, useStackContext } from '@app/packages/StackContext/StackContext'

import { ActionRequiredErrorStore } from './useSelectorResult.shared'

export const useSelectorResult = <T>(selector: (...args: any[]) => Result<T>) => {
  const dispatch = useAppDispatch()
  const svalue = useSelector(selector)
  const value = useDeferredValue(svalue)
  const stackContext = useStackContext()

  if (value.error) {
    const errors = getErrors(value.value)

    const notAction = errors.find(e => !(e instanceof ActionRequiredError))
    if (notAction) return value

    const actionErrors = uniqBy(
      errors.filter((e): e is ActionRequiredError => e instanceof ActionRequiredError),
      (e: ActionRequiredError) => [e.message, e.key].join(':')
    )

    const promises: (() => Promise<any>)[] = []
    for (const error of actionErrors) {
      const memoized = getMemoizedErrorState(stackContext, error)
      if (memoized) {
        if (memoized.resolved) {
          setTimeout(() => {
            clearError(stackContext, memoized.err)
          }, 10)
          if (memoized.resolved.error) {
            throw memoized.resolved.value
          } else {
            throw new CircularResolveError(`Circular resolve issue: ${memoized.err.message}`).withCause(memoized)
          }
        } else {
          promises.push(() => stackContext.handleAction(dispatch(withAbortSignal(stackContext.abortController.signal, resolveActionRequiredState(memoized)))))
        }
      } else {
        const state: ActionRequiredErrorState = { err: error }
        memoizeErrorState(stackContext, state)
        promises.push(() => stackContext.handleAction(dispatch(withAbortSignal(stackContext.abortController.signal, resolveActionRequiredState(state)))))
      }
    }
    if (promises.length) throw Promise.all(promises.map(p => p()))

    return value
  }

  return value
}

const getErrors = (err: any) => {
  if (err instanceof AggregateError) {
    return err.errors.flatMap(e => getErrors(e))
  }
  return [err]
}

const getMemoizedErrorState = (ctx: StackContextValue, error: ActionRequiredError) => {
  if (!ctx.store[ActionRequiredErrorStore]) {
    ctx.store[ActionRequiredErrorStore] = {}
  }

  const state = ctx.store[ActionRequiredErrorStore][error.message] as ActionRequiredErrorState
  if (!state || state.err.key !== error.key) return null
  if (state.resolved?.error && isAbortError(state.resolved.value)) return null

  return state
}

const memoizeErrorState = (ctx: StackContextValue, state: ActionRequiredErrorState) => {
  if (!ctx.store[ActionRequiredErrorStore]) {
    ctx.store[ActionRequiredErrorStore] = {}
  }

  ctx.store[ActionRequiredErrorStore][state.err.message] = state
  return state
}

const clearError = (ctx: StackContextValue, error: ActionRequiredError) => {
  if (!ctx.store[ActionRequiredErrorStore]) {
    ctx.store[ActionRequiredErrorStore] = {}
  }

  delete ctx.store[ActionRequiredErrorStore][error.message]
}

export const clearErrors = (ctx: StackContextValue) => {
  if (!ctx.store[ActionRequiredErrorStore]) ctx.store[ActionRequiredErrorStore] = {}
  clearObject(ctx.store[ActionRequiredErrorStore])
}

const clearObject = (obj: object) => {
  Reflect.ownKeys(obj).forEach(key => {
    delete obj[key]
  })
}
