import type { ReactNode } from 'react'

import { IMPORT_MAP } from '@app/importMap'

import { AbortError, isAbortError } from '@app/errors/AbortError'

import { asError } from '@app/packages/asError/asError'
import { Result, resultError, resultOk } from '@app/packages/Result/Result'

import { createThunk } from '@app/store/thunk'

import type { ActionButtonsType } from '@app/components/ActionButtonsModal/ActionButtonsType'
import { assertMount } from '@app/components/Mount/actions'

import { withProgress } from './initial'

export function showError(options: { error: Error | Error[]; onClose?: () => unknown }) {
  console.error(options.error)
  return createThunk(async (dispatch, _getState) => {
    return new Promise<void>(resolve => {
      dispatch(assertMount()).push(
        IMPORT_MAP.modals.ErrorModal().then(m => m.ErrorModal),
        { props: options, onClose: resolve }
      )
    })
  })
}

export function confirm({ name, title, content, closeButtonContent }: { name: string; title?: ReactNode; content: ReactNode; closeButtonContent?: ReactNode }) {
  return createThunk(async (dispatch, _getState) => {
    return new Promise<boolean>(resolve => {
      dispatch(assertMount()).push(
        IMPORT_MAP.modals.Confirm().then(m => m.Confirm),
        { props: { name, title, content, closeButtonContent, onClose: resolve } }
      )
    })
  })
}

export function ask<T>({
  name,
  title,
  text,
  actions,
}: {
  name: string
  title?: ReactNode
  text?: ReactNode
  actions: {
    name: string
    type?: ActionButtonsType
    text: string
    onClick: () => T
  }[]
}) {
  return createThunk((dispatch, _getState) => {
    return new Promise<T>((resolve, reject) => {
      let resolved = false
      const close = dispatch(assertMount()).push(
        import('@app/components/ActionButtonsModal/ActionButtonsModal').then(m => m.ActionButtonsModal),
        {
          props: {
            name,
            title,
            text,
            actions: actions.map(a => {
              return {
                ...a,
                onClick: async () => {
                  const res = await a.onClick()
                  resolved = true
                  await close?.()
                  resolve(res)
                },
              }
            }),
          },
          onClose: () => {
            if (!resolved) {
              reject(new AbortError('Modal closed without action'))
            }
          },
        }
      )
    })
  })
}

export const handleCallback = <T>(callback: () => T, blocking?: boolean) => {
  return createThunk(async (dispatch): Promise<Result<Awaited<T>>> => {
    try {
      const result = await dispatch(withProgress(callback(), blocking))
      return resultOk(result)
    } catch (e) {
      if (!isAbortError(e)) {
        dispatch(showError({ error: asError(e) }))
      }
      return resultError(asError(e))
    }
  })
}
