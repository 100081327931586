import { ApplicationAgent } from '@app/services/ApplicationAgent'

import { getRootContext } from '@app/packages/actionContext/actions'

import { createThunk } from '@app/store/thunk'

const applicationAgentSymbol = Symbol('applicationAgent')

export const setApplicationAgent = (agent: ApplicationAgent) =>
  createThunk(dispatch => {
    const context = dispatch(getRootContext())
    context[applicationAgentSymbol] = agent
  })

export const getApplicationAgent = () =>
  createThunk(dispatch => {
    const context = dispatch(getRootContext())
    if (!context[applicationAgentSymbol]) throw new Error('Application agent is not set')
    return context[applicationAgentSymbol] as ApplicationAgent
  })
