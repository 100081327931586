import { IntlShape } from 'react-intl'
import { createSelector } from 'reselect'

import { setLocaleAction } from '@app/store/actions/initial.descriptors'
import { createReduxSlice } from '@app/store/redux_slice'

export const intlSlice = createReduxSlice<IntlShape>('intl')
intlSlice.addCase(setLocaleAction.shape, (_state, action) => {
  return action.payload.intl
})

export const intlSelector = createSelector(intlSlice.selector, intl => {
  if (!intl) throw new Error('IntlService is not initialized')
  return intl
})
