import some from 'lodash/some'

import { excludeAbortError } from '@app/errors/AbortError'

import { deleteAuthDescriptor, postAuthSocialDescriptor, postAuthTelegramDescriptor } from '@app/store/actions/api/auth.descriptors'
import { getUsersMeDescriptor } from '@app/store/actions/api/users.descriptors'
import { postFirebaseTokensDescriptor } from '@app/store/actions/faye.descriptors'
import { setSessionAction, setShortSession } from '@app/store/actions/session.descriptors'
import { asApiError, isApiError } from '@app/store/apiMiddleware/errors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  /** short session uid */
  uuid: string
  access_token: string | null
  persistent?: boolean
  /**
   * indicates that login was completed via magic url,
   * e.g .../?access_token=...
   */
  viaURL?: boolean
  firebase_token: string | null
  supervisor: {
    access_token?: string
    viaURL?: boolean
  } | null
}>({ uuid: '', access_token: null, firebase_token: null, supervisor: null }, builder => {
  builder.addCase(setSessionAction.shape, (state, action) => {
    return { ...state, ...action.payload }
  })

  builder.addCase(getUsersMeDescriptor.shapes.rejected, (state, action) => {
    if (asApiError(action.payload)?.status === 401) {
      return { ...state, access_token: null, firebase_token: null, supervisor: null }
    }
    return state
  })

  builder.addCase(deleteAuthDescriptor.shapes.fulfilled, (state, _action) => {
    return { ...state, access_token: null }
  })

  builder.addCases([postAuthSocialDescriptor.shapes.rejected, postAuthTelegramDescriptor.shapes.rejected], (state, action) => {
    const errors = isApiError(action.payload) ? action.payload.response?.errors : []
    return { ...state, not_registered: some(errors, { code: 'NOT_REGISTERED' }), registration_error: excludeAbortError(action.payload) }
  })

  builder.addCase(postFirebaseTokensDescriptor.shapes.fulfilled, (state, action) => {
    if (action.payload.data?.id) return { ...state, firebase_token: action.payload.data.id }
    return state
  })

  builder.addCase(setShortSession.shape, (state, action) => {
    return { ...state, uuid: action.payload }
  })
})
