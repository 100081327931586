import { api } from '@app/utils/api'
import { urlEscaped } from '@app/utils/urlEscaped'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getKeyValuesByIdDescriptor, putKeyValuesByIdDescriptor } from './key_values.descriptors'

export const getKeyValuesById = new ApiActionBuilder(getKeyValuesByIdDescriptor)
  .setInit((id: string) => ({
    method: 'GET',
    endpoint: api.path(urlEscaped`/api/v2/key_values/${id}`),
    headers: api.headers(),
    meta: { id },
  }))
  .build()

export const putKeyValuesById = new ApiActionBuilder(putKeyValuesByIdDescriptor)
  .setInit((id: string, body: { value: string }) => ({
    method: 'PUT',
    endpoint: api.path(urlEscaped`/api/v2/key_values/${id}`),
    headers: api.headers(),
    body: JSON.stringify(body),
    meta: { id, body },
  }))
  .build()
