import React, { createContext, FunctionComponent, PropsWithChildren, useMemo } from 'react'

import { invariant } from '@app/utils/invariant'

import { useSafeContext } from '@app/hooks/useSafeContext'

type ContextUpdateContextType<P, CTX> = { type: 'set'; ctx: (args: P) => CTX } | { type: 'update'; ctx: (args: P, ctx: CTX) => CTX }

export const createUseContext = <CTX, P extends any[]>(
  creator: (...args: P) => CTX
): typeof creator & {
  updateContext: (updater: (args: P, ctx: CTX) => CTX) => FunctionComponent<PropsWithChildren>
  updateContextWithValue: () => FunctionComponent<PropsWithChildren<{ value: (args: P, ctx: CTX) => CTX }>>
  setContext: (useCtx: (args: P) => CTX) => FunctionComponent<PropsWithChildren>
  setContextWithValue: () => FunctionComponent<PropsWithChildren<{ value: (args: P) => CTX }>>
} => {
  const ComponentUpdateContext = createContext<ContextUpdateContextType<P, CTX>>(undefined as any)

  const hook = ((...args: P) => {
    const outerContext = useSafeContext(ComponentUpdateContext)
    return (() => {
      if (outerContext) {
        if (outerContext.type === 'set') return outerContext.ctx(args)
        if (outerContext.type === 'update') return outerContext.ctx(args, creator(...args))
      }
      return creator(...args)
    })()
  }) as any

  const SetContextProvider = ({ children, value: setter }) => {
    const parentContext = useSafeContext(ComponentUpdateContext)
    const value = useMemo<ContextUpdateContextType<P, CTX>>(
      () =>
        !parentContext
          ? { type: 'set', ctx: setter }
          : parentContext.type === 'set'
            ? parentContext
            : parentContext.type === 'update'
              ? { type: 'set', ctx: props => parentContext.ctx(props, setter(props)) }
              : invariant(new Error('Unexpected ctx mode')),
      [parentContext, setter]
    )
    return <ComponentUpdateContext.Provider value={value}>{children}</ComponentUpdateContext.Provider>
  }

  hook.setContextWithValue = () => {
    return SetContextProvider
  }

  hook.setContext = value => {
    return ({ children }) => hook.setContextWithValue()({ children, value })
  }

  const UpdateContextProvider = ({ children, value: updater }) => {
    const parentContext = useSafeContext(ComponentUpdateContext)
    const value = useMemo<ContextUpdateContextType<P, CTX>>(
      () =>
        !parentContext
          ? { type: 'update', ctx: (props, ctx) => updater(props, ctx) }
          : parentContext.type === 'set'
            ? parentContext
            : parentContext.type === 'update'
              ? { type: 'update', ctx: (props, ctx) => parentContext.ctx(props, updater(props, ctx)) }
              : invariant(new Error('Unexpected ctx mode')),
      [parentContext, updater]
    )

    return <ComponentUpdateContext.Provider value={value}>{children}</ComponentUpdateContext.Provider>
  }

  hook.updateContextWithValue = () => {
    return UpdateContextProvider
  }

  hook.updateContext = value => {
    return ({ children }) => hook.updateContextWithValue()({ children, value })
  }

  return hook
}
