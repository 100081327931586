import React, { forwardRef, useEffect, useState } from 'react'
import { createLocation } from 'history'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import { LinkProps, Link as ReactLink } from 'react-router-dom'

import { AnalyticsEvent } from '@app/services/AnalyticsEvent'

import { omit } from '@app/utils/omit'
import { createURLString } from '@app/utils/url'

import { useEvent } from '@app/hooks/useEvent'

import { profileRegionSelector } from '@app/store/selectors/profile'

import { useRouter } from './hooks'
import { LocationState } from './types'

type Props = Omit<LinkProps, 'to' | 'state'> & {
  /** Used for analytics. Use underscore_case */
  eventName: string
  to?: LinkProps['to']
  /** whether link should be prefixed with current region */
  region?: boolean
  state?: LocationState
  /** Use plain \<a> tag */
  external?: boolean
}

export type LinkRef = HTMLAnchorElement

/**
 * AppLink is a wrapper for `react-router.Link`
 * that supports regional link prefixing
 */
export const Link = forwardRef<HTMLAnchorElement, Props>(function Link({ eventName, to, onClick, region = false, external, children, ...rprops }, ref) {
  const currentRegion = useSelector(profileRegionSelector).attributes.slug
  const router = useRouter()
  if (!router) throw new Error('You should not use <Link> outside a <Router>')
  const [browser, setBrowser] = useState(false)

  const location = to ? createLocation(to) : null

  const handleClick = useEvent(e => {
    new AnalyticsEvent(`${eventName}.click`, {
      pathname: location?.pathname ?? null,
      search: location?.search ?? null,
      hash: location?.hash ?? null,
    }).sendInhouse()
    onClick?.(e)
  })

  useEffect(() => {
    setBrowser(true)
  }, [])

  if (isNil(location))
    return (
      <a onClick={eventName ? handleClick : onClick} {...rprops} ref={ref}>
        {children}
      </a>
    )

  if (region && !currentRegion) {
    location.pathname = `/${currentRegion}/${location.pathname}`.replace(/\/{2,}/, '/').replace(/\/$/, '')
  }

  if (!browser || external)
    return (
      <a href={createURLString(location)} onClick={eventName ? handleClick : onClick} {...omit(rprops, 'replace')} ref={ref}>
        {children}
      </a>
    )

  return (
    <ReactLink onClick={eventName ? handleClick : onClick} replace={location.pathname === router.location.pathname} to={location} {...rprops} ref={ref}>
      {children}
    </ReactLink>
  )
})
