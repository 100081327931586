import { ErrorEvent, Event } from '@sentry/node'

import { isAbortError } from '@app/errors/AbortError'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'

import { ApiError, isNetworkFailError } from '@app/store/apiMiddleware/errors'

import { errorWhiteList } from './errorWhitelist'

interface EventProcessor {
  <T extends Event | ErrorEvent>(event: T, hint: { originalException?: unknown }): Promise<T | null>
  id?: string
}

export const createEventProcessor =
  ({ requestId }: { requestId: string }): EventProcessor =>
  async (event, hint) => {
    try {
      try {
        const debug = window['__debug_sentry_event']
        if (debug && debug(event, hint)) return null
      } catch {}

      const frames = event.exception?.values?.at(0)?.stacktrace?.frames ?? []
      if (!frames.length || frames.at(0)?.filename === '<anonymous>') {
        return null
      }

      const err = hint.originalException
      if (err && matchError(err, errorWhiteList)) return null
      if (isNetworkFailError(err)) return null
      if (isAbortError(err)) return null

      event.extra = event.extra || {}
      event.extra.request_id = requestId
      event.extra.error_data = getErrorsExtra(err)
    } catch {
      event.tags = { ...event.tags, processing_failed: true }
    }

    return event
  }

function getErrorExtra(err: unknown) {
  if (!(err instanceof Error)) {
    return { type: 'unknown object' }
  }
  if (err instanceof ActionRequiredError) {
    return { type: 'ActionRequiredError', key: err.key, extra: err.extra }
  }
  if (err instanceof ApiError) {
    return { type: 'ApiError', message: err.message, response: err.response, status: err.status }
  }
  return { message: err.message }
}

function getErrorsExtra(err: unknown) {
  const root: any = getErrorExtra(err)
  const causes = getCauses(err)
  if (causes.length) {
    root.causes = causes.map(getErrorExtra)
  }
  return root
}

function getCauses(err: unknown) {
  const causes: unknown[] = []
  while (err instanceof Error && err.cause) {
    causes.push(err.cause)
    err = err.cause
  }
  return causes
}

function matchError(error: unknown, regex: RegExp[]) {
  if (!error || typeof error !== 'object') return false
  if ('message' in error) {
    const message = error.message as string
    if (regex.find(error => error.test(message))) return true
  }
  if ('cause' in error && error.cause) return matchError(error.cause, regex)
  return false
}
