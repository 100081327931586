import React, {
  createElement,
  CSSProperties,
  forwardRef,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import FocusLock from 'react-focus-lock'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { AnalyticsEvent } from '@app/services/AnalyticsEvent'

import { assertNever } from '@app/utils/assertNever'
import { debounce } from '@app/utils/debounce'
import { isKeyboardKeyEvent } from '@app/utils/isKeyboardKeyEvent'
import { noop } from '@app/utils/noop'
import { useOverflowContext } from '@app/utils/OverflowContext'
import { useAppDispatch } from '@app/utils/redux'
import { sleep } from '@app/utils/sleep'

import { useClickHandler } from '@app/hooks/useClickHandler'
import { useDeviceType } from '@app/hooks/useDeviceType'
import { useEvent } from '@app/hooks/useEvent'

import { confirm } from '@app/store/actions/ui'
import { configSelector } from '@app/store/selectors/misc'
import { getThemeColor, setThemeColor } from '@app/store/slices/ui_theme_color'

import { Icon } from '@app/components/Icon/Icon'
import { sideMenuModifiers } from '@app/components/LayoutMilk/utils'
import { MountedComponentProps } from '@app/components/Mount/Mount'
import { Typography } from '@app/components/Typography/Typography'

// Placed here to keep button styles with lower priority than modal styles
import '@app/components/Button/Button.module.scss'
import classes from './Modal.module.scss'
import mountClasses from '../Mount/Mount.module.scss'

export type ModalProps = (StandardProps | SheetdProps | InPlaceProps) & PropsWithChildren<{}> & MountedComponentProps

export type ModalLockReason = { type: 'forceable'; message: string } | { type: 'forbidden'; message: string } | { type: 'hidden' }

interface BaseProps {
  startTransition?: boolean
  endTransition?: boolean
  className?: string
  onClose?: () => void
  lockReason?: ModalLockReason
  theme?: 'dark'
  autoFocus?: boolean
  /* string in kebab-case. used for analytics */
  name: string
  footer?: ReactNode
  footerType?: 'standard' | 'fixed'
  contentWidth?: number
}

interface StandardProps extends BaseProps {
  type?: 'standard'
  title?: ReactNode
  titleType?: 'small' | 'header' | 'large' | 'invisible'
  leftButton?: ReactNode
  closeType?: 'default' | 'monochrome'
}

interface SheetdProps extends BaseProps {
  type: 'sheet'
  title?: ReactNode
  titleType?: 'small' | 'header' | 'large' | 'invisible'
  leftButton?: ReactNode
  closeType?: 'default' | 'monochrome'
}

/**
 * props for inplace modal which rendered as a small popup
 * with triangle pointing to area of interest
 */
interface InPlaceProps extends BaseProps {
  type: 'inPlace'
  /** element to bind */
  element?: HTMLElement
  /** x position, if element provided, then acts as offset */
  x?: number
  /** y position, if element provided, then acts as offset */
  y?: number
  pointerPosition?: 'top' | 'right' | 'bottom' | 'left'
}

export interface ModalRef {
  scrollTo: typeof window.scrollTo
}

/**
 * @example
 * <Modal name='component-name' mount={mount}>
 *   <ModalSeparator/>
 *   <ModalContent>{content}</ModalContent>
 *   <ModalSeparator/>
 * </Modal>
 */
export const Modal = forwardRef<ModalRef, ModalProps>(function Modal(props, ref) {
  const dispatch = useAppDispatch()
  const startTransition = props.startTransition ?? true
  const endTransition = props.endTransition ?? true
  const { name, type = 'standard', onClose = noop, lockReason, mount, theme, autoFocus = false, className, children } = props
  const pointerPosition = (props.type === 'inPlace' && props.pointerPosition) || 'top'
  const overflowContext = useOverflowContext()
  const mouseDownStartedOnBackdropRef = useRef(false)
  const mouseDownAbortController = useRef<AbortController | null>(null)
  const device = useDeviceType()
  const [shown, setShown] = useState(!startTransition)
  const closeHandledByApp = useSelector(configSelector).appProtocol >= 3

  const rootRef = useRef<HTMLDivElement>(null)
  const lockRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const transition = useMemo(
    () => ({
      classNames: {
        enter: startTransition ? classes.modal_enter : undefined,
        enterActive: startTransition ? classes.modal_enter_active : undefined,
        exit: endTransition ? classes.modal_exit : undefined,
        exitActive: endTransition ? classes.modal_exit_active : undefined,
        exitDone: endTransition ? classes.modal_exit_done : undefined,
      },
      timeout: {
        enter: startTransition ? defaultTransition.timeout.enter : 0,
        exit: endTransition ? defaultTransition.timeout.exit : 0,
      },
    }),
    [endTransition, startTransition]
  )

  const closeModal = useEvent(async () => {
    if (lockReason) {
      switch (lockReason.type) {
        case 'hidden':
          return
        case 'forceable': {
          const success = await dispatch(confirm({ name: 'model-close-confirm', content: lockReason.message }))
          if (!success) return
          break
        }
        case 'forbidden':
          alert(lockReason.message)
          return
      }
    }
    mount.close()
  })

  const closeModalHandler = useClickHandler(async () => {
    closeModal()
  })

  const handleBackdropMouseDown = useEvent<MouseEventHandler<HTMLDivElement>>(e => {
    if (e.target !== lockRef.current) return

    mouseDownAbortController.current?.abort()
    const cnt = new AbortController()
    mouseDownAbortController.current = cnt
    mouseDownStartedOnBackdropRef.current = true
    setTimeout(() => {
      if (cnt.signal.aborted) return
      mouseDownStartedOnBackdropRef.current = false
    }, 700)
  })

  const handleBackdropMouseUp = useEvent((): void => {
    if (mouseDownStartedOnBackdropRef.current) closeModal()
    mouseDownStartedOnBackdropRef.current = false
  })

  useWindowResize()

  useEffect(() => {
    const abortController = new AbortController()
    setShown(true)

    sleep(transition.timeout.enter).then(() => {
      if (abortController.signal.aborted) return
      mount.registerMount(async () => {
        if (abortController.signal.aborted) return
        setShown(false)
        await sleep(transition.timeout.exit)
      })
    })

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    mount.registerOnClose(onClose ?? null)
  }, [mount, onClose])

  useEffect(() => {
    overflowContext.increment()

    AnalyticsEvent.create({ id: 'open_modal', name }).sendGAYS()

    return () => {
      overflowContext.decrement()

      AnalyticsEvent.create({ id: 'close_modal', name }).sendGAYS()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mount.top) return
    if (theme !== 'dark') return
    const initial = dispatch(getThemeColor())
    dispatch(setThemeColor('#000'))

    return () => {
      dispatch(setThemeColor(initial))
    }
  }, [dispatch, mount.top, theme])

  useEffect(() => {
    if (!mount.top) return

    const handleKeydown = (event: KeyboardEvent) => {
      if (mount.top && isKeyboardKeyEvent('Escape', event)) {
        event.stopPropagation()
        event.preventDefault()
        closeModal()
      }
    }

    window.addEventListener('keydown', handleKeydown, true)
    return () => {
      window.removeEventListener('keydown', handleKeydown, true)
    }
  }, [mount.top, closeModal, mount.close])

  useImperativeHandle(
    ref,
    () => ({
      scrollTo: (...args: any[]) => containerRef.current?.scrollTo(...args),
    }),
    []
  )

  useEffect(() => {
    const activeElement = document.activeElement as HTMLElement

    return () => {
      window.setTimeout(() => {
        if (activeElement && 'focus' in activeElement) {
          try {
            activeElement.focus()
          } catch {}
        }
      }, 100)
    }
  }, [])

  let classNames = cn(classes.modal, classes[`type_${type.toLocaleLowerCase()}`])
  if (props.type === 'inPlace') {
    classNames = cn(classNames, classes[`p_position_${pointerPosition}`])
  }
  if (theme === 'dark') {
    classNames = cn(classNames, classes.theme_dark)
  }

  const leftButton = 'leftButton' in props ? props.leftButton : undefined
  let style: CSSProperties | undefined = props.type !== 'inPlace' ? undefined : calculatePosition(props.element, props.x ?? 0, props.y ?? 0)
  if (props.contentWidth && device !== 'mobile') style = { ...style, width: `${props.contentWidth}px` }

  const title = 'title' in props ? props.title : undefined
  const titleType = ('titleType' in props ? props.titleType : undefined) ?? 'small'
  const hel = titleType === 'large' ? 'h1' : 'h2'
  const footer = 'footer' in props ? props.footer : undefined
  const footerType = ('footerType' in props ? props.footerType : undefined) ?? 'standard'
  const closeType = ('closeType' in props ? props.closeType : undefined) ?? 'default'

  const handleLayoutModalClick = useEvent((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  })

  return (
    <CSSTransition mountOnEnter={true} nodeRef={rootRef} unmountOnExit={false} {...transition} in={shown}>
      <div className={mountClasses.layer} ref={rootRef}>
        <div className={classNames} {...sideMenuModifiers.preventSideMenuClose} onMouseDown={handleBackdropMouseDown} onMouseUp={handleBackdropMouseUp}>
          <div className={classes.backdrop} />
          <div className={classes.container} ref={containerRef}>
            <div className={classes.layout}>
              <FocusLock
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                className={classes.content}
                crossFrame={false}
                disabled={!mount.top}
                ref={lockRef}
                whiteList={IFRAME_WHITELIST}
              >
                <div className={cn(classes.inner, className)} onClick={handleLayoutModalClick} style={style}>
                  {!!leftButton && (
                    <div className={classes.top_left_cnt} data-autofocus role="button">
                      {leftButton}
                    </div>
                  )}
                  {!closeHandledByApp && lockReason?.type !== 'hidden' && (
                    <div
                      className={cn(classes.top_right_cnt, classes.close, classes[`close_theme_${closeType}`])}
                      {...closeModalHandler}
                      aria-label="Закрыть"
                      data-autofocus
                      role="button"
                    >
                      <Icon className={classes.close_icon} icon="close-2" />
                    </div>
                  )}
                  {titleType !== 'invisible' &&
                    props.type !== 'inPlace' &&
                    createElement(
                      hel,
                      { className: cn(Typography.Header, Typography.SubheaderMobile, classes.title_header) },
                      !!title && titleType === 'header' ? title : '\u00a0'
                    )}
                  <div className={classes.inner_content}>
                    {!!title &&
                      titleType !== 'header' &&
                      titleType !== 'invisible' &&
                      createElement(
                        hel,
                        {
                          className:
                            titleType === 'large'
                              ? cn(Typography.Header1, classes.title_large)
                              : titleType === 'small'
                                ? cn(Typography.Header, classes.title_small)
                                : assertNever(titleType),
                        },
                        title
                      )}
                    {children}
                    {footerType !== 'fixed' && footer && <div className={classes.filler} />}
                    {footerType !== 'fixed' && footer}
                  </div>
                  {footerType === 'fixed' && footer}
                </div>
              </FocusLock>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
})

function calculatePosition(
  el: HTMLElement | undefined | null,
  x: number,
  y: number
): {
  top: number
  left: number
} {
  if (!el) return { top: x, left: y }
  const rect = el.getBoundingClientRect()
  return {
    top: rect.top + y,
    left: rect.left + x,
  }
}

const defaultTransition = {
  classNames: {
    enter: classes.modal_enter,
    enterActive: classes.modal_enter_active,
    exit: classes.modal_exit,
    exitActive: classes.modal_exit_active,
    exitDone: classes.modal_exit_done,
  },
  timeout: {
    enter: 250,
    exit: 250,
  },
}

/** triggers rerender on window width changed */
const useWindowResize = () => {
  if (!IS_BROWSER) return

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, setState] = useState(0)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    const onResize = debounce(() => {
      setState(window.innerWidth)
    }, 200)
    window.addEventListener('resize', onResize, true)

    return () => {
      window.removeEventListener('resize', onResize, true)
    }
  }, [])
}

const IFRAME_WHITELIST = (el: HTMLElement) => el.nodeName !== 'IFRAME'
