import { ThunkAction } from '@app/store/thunk'

export class ActionRequiredError extends Error {
  action!: ThunkAction<Promise<void>>
  private promise: Promise<void> | undefined
  /** key to distinct vary */
  key!: string
  extra: any

  private constructor(message: string) {
    super(message)
  }

  withExtra(extra: any) {
    this.extra = extra
    return this
  }

  static create(message: string, key: string, action: ThunkAction<Promise<void>>) {
    const err = new ActionRequiredError(message)
    err.key = key
    err.action = action
    return err
  }
}
