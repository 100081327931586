export function asError(e: unknown) {
  if (e instanceof Error) {
    return e
  }
  if (typeof e === 'string') {
    return new Error(e)
  }
  if (e && typeof e === 'object') {
    const keys = Reflect.ownKeys(e)
    return wrapError(new Error(`Unexpected error object with keys: ${keys.join(', ')}`), e)
  }
  return wrapError(new Error('Unexpected error object'), e)
}

export function wrapError(e: Error, previousError: unknown): Error {
  e.cause = previousError
  return e
}
