import { createSelector } from 'reselect'

import { AppError } from '@app/errors/AppError'

import { Raw, urlEscaped } from '@app/utils/urlEscaped'

import { intoResult, Result, resultOk, unwrapOr, unwrapResult } from '@app/packages/Result/Result'

import { StoreState } from '@app/store/store'

/** Returns map of users */
export const usersStateSelector = (state: StoreState) => state.users
export const usersSelector = (state: StoreState) => state.users.models
export const usersMetaSelector = (state: StoreState) => state.users.meta

/** Returns list of users */
export const usersListSelector = createSelector([usersSelector], userMap => Object.values(userMap))

export const makeUserByIdSelector = (userIdSelector: (state: StoreState, props?: any) => string | null) =>
  createSelector([usersSelector, userIdSelector], (users, userId) => (!userId ? null : users[userId] || null))

export const makeParentByIdSelector = (userIdSelector: (state: StoreState, props?: any) => string | null) =>
  createSelector([usersSelector, userIdSelector], (users, userId) => {
    if (!userId) return null
    const user = users[userId]
    if (!user) return null
    if (user.account_type !== 'parent') return null
    return user
  })

export const makeUsersByIdSelector = (userIdsSelector: (state: StoreState, props?: any) => string[]) =>
  createSelector([usersSelector, userIdsSelector], (users, ids) =>
    ids
      .map(id => {
        const user = users[id]
        if (!user && process.env.NODE_ENV === 'development') console.error(`user with id ${id} not found`)
        return user
      })
      .filter(u => !!u)
  )

export const metaTagsSelector = (state: StoreState) => state.users.meta_tags

export const createUserResultSelector = (idSelector: (state: StoreState) => Result<string>) =>
  createSelector([usersSelector, idSelector], (users, idResult) =>
    intoResult(() => {
      const userId = unwrapResult(idResult)
      const user = users[userId]
      if (!user) throw new AppError(`User with id ${userId} is missing`).withExpose(true)
      return user
    })
  )

export const createSitterResultSelector = (idSelector: (state: StoreState) => Result<string>) =>
  createSelector([createUserResultSelector(idSelector), idSelector], userResult =>
    intoResult(() => {
      const user = unwrapResult(userResult)
      if (user.account_type !== 'sitter') throw new Error('User is not sitter')
      return user
    })
  )

/** @deprecated use createSitterResultSelector */
export const createSitterSelector = (id: string) =>
  createSelector([createSitterResultSelector(() => resultOk(id))], sitterResult => unwrapOr(sitterResult, null))

export const createSitterMetaTagsResultSelector = (idSelector: (state: StoreState) => Result<string>) =>
  createSelector([metaTagsSelector, idSelector], (meta, sitterIdResult) =>
    intoResult(() => {
      const sitterId = unwrapResult(sitterIdResult)
      const tags = meta[sitterId] ? meta[sitterId] : null
      if (!tags) throw new Error('Meta tags are missing')
      return tags
    })
  )

export const createUserURLResultSelector = (idSelector: (state: StoreState) => Result<string>) => {
  const userSelector = createUserResultSelector(idSelector)
  const metaSelector = createSitterMetaTagsResultSelector(idSelector)

  return createSelector([userSelector, metaSelector], (userResult, metaResult) =>
    intoResult(() => {
      const user = unwrapResult(userResult)
      if (user.account_type === 'sitter') {
        if (metaResult.error) return urlEscaped`/${user.token}/`
        const meta = unwrapResult(metaResult)
        return user && meta ? urlEscaped`/${user.token}/${new Raw(meta.attributes.hurl_suffix)}` : user ? urlEscaped`/${user.token}` : '/'
      }
      if (user.account_type === 'parent') {
        return urlEscaped`/p/${user.token}`
      }
      throw new Error('Unsopported account type')
    })
  )
}

/** @deprecated use createUserURLResultSelector */
export const createUserURLSelector = (id: string) => createSelector([createUserURLResultSelector(() => resultOk(id))], urlResult => unwrapResult(urlResult))
