import type Cookies from 'universal-cookie'

import { DEFAULT_LOCALE, Locale, LOCALES_LIST } from '@app/constants/Locales'

import { createCookie } from './cookies'
import moment from './moment'

export const COOKIE_NAME = 'kidsout_locale'

function ensureLocale(locale: string): Locale {
  return LOCALES_LIST.indexOf(locale as any) === -1 ? DEFAULT_LOCALE : (locale as Locale)
}

const localeCookie = createCookie(
  'kidsout_locale',
  x => ensureLocale(x),
  x => x,
  () => ({ expires: moment().add(10, 'years').toDate(), path: '/', httpOnly: false })
)

export function setLocale(locale: string, cookies: Cookies) {
  const supportedLocale = ensureLocale(locale)

  localeCookie.set(cookies, supportedLocale)
}

export function getLocale(cookies: Cookies) {
  const supportedLocale = ensureLocale(localeCookie.get(cookies) || DEFAULT_LOCALE)
  // let country = sharedRequest.request.cookies.get(COOKIE_NAME)
  // if (!country) {
  //   const geoip = require('geoip-ultralight')
  //   country = geoip.lookupCountry(sharedRequest.request.ip)
  //   console.log(`Detected country based on ip ${sharedRequest.request.ip}: ${country}`)
  // }
  // let supportedLocale = find(locales, { 'country': country }) || DEFAULT_LOCALE
  return supportedLocale
}
