import React, { Component, ComponentType, createRef, FunctionComponent, lazy, ReactNode, Ref, Suspense, useMemo } from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import dropRightWhile from 'lodash/dropRightWhile'
import omit from 'lodash/omit'
import uniqueId from 'lodash/uniqueId'
import without from 'lodash/without'
import { connect } from 'react-redux'

import { AbortError, isAbortError } from '@app/errors/AbortError'

import { KeyboardNavigation } from '@app/services/KeyboardNavigation'

import { AsyncQueue } from '@app/utils/AsyncQueue'
import { Channel } from '@app/utils/channel'
import { createSortHandler } from '@app/utils/createSortHandler'
import { captureMessage } from '@app/utils/errorReport/errorReport'
import { isKeyboardKeyEvent } from '@app/utils/isKeyboardKeyEvent'
import { noop } from '@app/utils/noop'
import { sleep } from '@app/utils/sleep'
import { waitMax, WaitMaxError } from '@app/utils/waitMax'

import { clearErrors } from '@app/packages/selectorResult/useSelectorResult'
import { StackContextProvider, StackContextValue, useStackContext } from '@app/packages/StackContext/StackContext'

import { getApplicationBridge, withProgress, withProgressAction } from '@app/store/actions/initial'
import { WithDispatchProp } from '@app/store/dispatch'

import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'
import { ErrorBoundary } from '@app/components/ErrorBoundary'
import { LoadingModal } from '@app/components/LoadingModal/LoadingModal'
import { RenderOnce } from '@app/components/RenderOnce/RenderOnce'

import { setMount, unsetMount } from './actions'
import { MountContext } from './MountContext'
import { AsyncPushFunction, MountContextInterface, MountedComponentProps, PushFunction } from './types'

import classes from './Mount.module.scss'

export type { MountedComponentProps } from './types'

export const mountDummy: MountedComponentProps['mount'] = {
  close: () => Promise.resolve(),
  push: () => () => Promise.resolve(),
  top: true,
  registerMount: noop,
  registerOnClose: () => {},
}

export type Props = {
  className?: string
  contentClassName?: string
  children?: ReactNode
} & WithDispatchProp

export interface State {
  stack: StackItem[]
  mounted: { [key: string]: boolean }
}

class MountContainer extends Component<Props, State> {
  state: State = {
    stack: [],
    mounted: {},
  }

  private unmountActions: (() => unknown)[] = []
  private mobileRef = createRef<HTMLDivElement>()
  private mountContext: MountContextInterface
  private queue = new AsyncQueue()
  private topLock: null | number = null

  constructor(props) {
    super(props)

    this.mountContext = {
      push: this.pushAsync.bind(this),
      pop: this.pop.bind(this),
      autoclose: this.autoclose.bind(this),
      isMounted: this.checkIsMounted.bind(this),
    }
  }

  componentDidMount() {
    this.props.dispatch(setMount(this.mountContext))
    this.unmountActions.push(() => {
      this.props.dispatch(unsetMount())
    })

    const onFirstTab = (e: KeyboardEvent) => {
      if (isKeyboardKeyEvent('Tab', e)) {
        KeyboardNavigation.shared.increment()
      }
    }
    window.addEventListener('keydown', onFirstTab)
    this.unmountActions.push(() => window.removeEventListener('keydown', onFirstTab))

    const bridge = this.props.dispatch(getApplicationBridge())
    if (bridge) {
      const unsubFromApplicationBridge = bridge.addMessageListener(message => {
        if (message.type === 'pop_modal') {
          this.pop()
        }
      })
      this.unmountActions.push(unsubFromApplicationBridge)
    }
  }

  componentWillUnmount() {
    const actions = this.unmountActions
    this.unmountActions = []
    actions.forEach(a => a())
  }

  render() {
    const isMounted = this.state.stack.length > 0

    return (
      <MountContext.Provider value={this.mountContext}>
        <section className={cn(classes.mount, ControlClasses.max_height, this.props.className)}>
          <div className={ControlClasses.only_mobile} ref={this.mobileRef} />
          <div
            aria-hidden={isMounted || undefined}
            className={cn(classes.content, ControlClasses.max_height, this.props.contentClassName, { [classes.is_mounted]: isMounted })}
            style={this.topLock !== null ? { top: `-${this.topLock}px` } : undefined}
          >
            {this.props.children}
          </div>
          <RenderOnce cond={!!this.state.stack.length}>
            <div className={classes.stack}>{this.state.stack.map(this.renderStackItem)}</div>
          </RenderOnce>
        </section>
      </MountContext.Provider>
    )
  }

  private renderStackItem = (item: StackItem, _itemIndex: number) => {
    const StackItem = item.component

    return (
      <StackContextProvider key={item.key} value={item.context}>
        <Suspense fallback={<LoadingModal mount={item.props.mount} name="loading-modal" />}>
          <ErrorBoundary ctx={{ mount: item.props.mount }} fallback={StackErrorFallback}>
            <StackItem {...item.props} />
          </ErrorBoundary>
        </Suspense>
      </StackContextProvider>
    )
  }

  private push: PushFunction = (component: any, options = {} as any) => {
    let afterClose: (() => unknown) | null = null
    const mountChannel = new Channel<void>()
    const onClose = async () => {
      await sleep(200)
      afterClose?.()
      options.onClose?.()
    }
    const stackItem: StackItem = {
      autoclose: true,
      ...options,
      onClose,
      scrollTop: window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0,
      component,
      key: uniqueId('mount-component-'),
      mountAbort: new AbortController(),
    }

    stackItem.context = {
      stackId: stackItem.key,
      store: {},
      abortController: stackItem.mountAbort,
      handleAction: promise => this.props.dispatch(withProgress(promise)),
    }

    let closeTr = async () => {}
    const close = async () => {
      await closeTr()
      return this.popStackItem(stackItem)
    }
    const mount: MountedComponentProps['mount'] = {
      close,
      push: this.pushAsync,
      top: false,
      registerMount: pCloseTr => {
        closeTr = pCloseTr
        mountChannel.resolve()
      },
      registerOnClose: fn => {
        afterClose = fn
      },
    }

    stackItem.props = { ...stackItem.props, mount }

    this.queue.queued(async () => {
      let stack = [...this.state.stack, stackItem].sort(createSortHandler(c => [c.zIndex || 0]))

      stack = stack.map((item, index) => ({ ...item, props: { ...item.props, mount: { ...item.props.mount, top: index === stack.length - 1 } } }))

      if (this.topLock === null) {
        this.topLock = window.scrollY
      }

      await new Promise<void>(resolve => {
        this.setState({ stack }, () => {
          resolve()
          this.props.dispatch(getApplicationBridge())?.sendMessage({ type: 'mount_stack_change', stack_length: stack.length })
        })
      })

      stackItem.mountAbort.signal.addEventListener('abort', () => {
        mountChannel.reject(new AbortError())
      })

      await waitMax(mountChannel.wait(), 5000).catch(e => {
        if (e instanceof WaitMaxError) {
          captureMessage(
            `registerMount was not called in 5 seconds, check that every mount has registerMount call: ${
              stackItem.component.displayName ?? stackItem.component.name ?? 'unknown component'
            }`
          )
        } else if (isAbortError(e)) {
          //
        } else {
          throw e
        }
      })

      if (stackItem.mountAbort.signal.aborted) return
      this.didMount(stack)
    })()

    return close
  }

  private pushAsync: AsyncPushFunction = (componentPromise: any, options = {} as any) => {
    const close = (async () => {
      const component: any = await this.props.dispatch(withProgress(componentPromise))
      if ('preload' in component) {
        await this.props.dispatch(withProgressAction(component.preload()))
      }
      return this.push(component, options)
    })()
    return () => close.then(close => close())
  }

  private autoclose = this.queue.queued(async () => {
    if (this.state.stack.length === 0) return

    const nextStack = dropRightWhile(this.state.stack, s => !!s.autoclose)
    if (nextStack.length === this.state.stack.length) return

    const resolvedItems: StackItem[] = without(this.state.stack, nextStack as any)
    const nextMounted = omit(
      this.state.mounted,
      resolvedItems.map(item => item.key)
    )
    const leftResolvedItem = resolvedItems.at(0)

    await new Promise<void>(resolve => {
      this.setState({ stack: nextStack, mounted: nextMounted }, () => {
        resolve()
        this.props.dispatch(getApplicationBridge())?.sendMessage({ type: 'mount_stack_change', stack_length: nextStack.length })
      })
    })

    await sleep(1)
    if (leftResolvedItem) {
      window.scrollTo(0, leftResolvedItem.scrollTop)
    }

    resolvedItems.forEach(item => item.onClose())
  })

  private popStackItem = this.queue.queued(async (itemGetter: StackItem | (() => StackItem | null)) => {
    if (this.state.stack.length === 0) return

    const item = typeof itemGetter === 'function' ? itemGetter() : itemGetter
    if (!item) return
    const stackItem = this.state.stack.find(i => i.key === item.key)
    if (!stackItem) return
    stackItem.mountAbort.abort()

    const mounted = omit(this.state.mounted, stackItem.key)

    let stack = this.state.stack.filter(i => i !== stackItem)

    stack = stack.map((item, index) => ({ ...item, props: { ...item.props, mount: { ...item.props.mount, top: index === stack.length - 1 } } }))

    if (stack.length === 0) {
      this.topLock = null
    }

    await new Promise<void>(resolve => {
      this.setState({ stack, mounted }, () => {
        resolve()
        this.props.dispatch(getApplicationBridge())?.sendMessage({ type: 'mount_stack_change', stack_length: stack.length })
      })
    })

    await sleep(1)
    window.scrollTo(window.scrollX, stackItem.scrollTop)

    stackItem.onClose()
  })

  private pop = async (): Promise<void> => {
    return this.popStackItem(() => this.state.stack.at(-1)!)
  }

  private checkIsMounted = () => {
    return this.state.stack.length > 0
  }

  private didMount = stack => {
    const lastStackItem = stack.at(-1)
    if (!lastStackItem) return
    const mounted = { ...this.state.mounted, [lastStackItem.key]: true }

    this.setState({ mounted }, () => {
      const ref = this.mobileRef.current
      if (!ref) return
      const style = window.getComputedStyle(ref, null)
      // scrolls modal to top when it opens
      if (style['display'] !== 'none') {
        window.setTimeout(() => {
          window.scrollTo(0, 0)
        }, 1)
      }
    })
  }
}

export default connect(null)(MountContainer)

export interface WithMountProp {
  mount: MountContextInterface
}

export function injectMount<P extends WithMountProp>(Component: ComponentType<P>): FunctionComponent<Omit<P, 'mount'> & { forwardedRef?: Ref<any> }> {
  const CP = (props: Omit<P, 'mount'> & { forwardedRef?: Ref<any> }) => (
    <MountContext.Consumer>{mount => <Component mount={mount} {...(props as any)} />}</MountContext.Consumer>
  )
  CP.displayName = `injectMount(${Component.displayName || Component.name || 'Component'})`
  CP.WrappedComponent = Component
  hoistNonReactStatics(CP, Component)
  return CP
}

const StackErrorFallback = lazy(() => import('./StackErrorFallback').then(m => ({ default: m.StackErrorFallback })))

interface StackItem {
  autoclose: boolean
  scrollTop: number
  key: string
  onClose: () => Promise<unknown>
  zIndex?: number

  component: ComponentType
  props?: any
  mountAbort: AbortController
  context: StackContextValue
}

export const useMountStackContext = () => {
  const ctx = useStackContext()
  return useMemo(
    () => ({
      stackId: ctx.stackId,
      resetErrors: () => {
        clearErrors(ctx)
      },
    }),
    [ctx]
  )
}
