import React, { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react'
import { ParameterizedContext } from 'koa'
import Cookies, { CookieChangeListener, CookieSetOptions } from 'universal-cookie'

import { createDisposable, Disposable } from '@app/packages/disposable/disposable'

import { tryOr } from './tryOr'

export function createServerCookies(ctx: ParameterizedContext): Disposable<Cookies> {
  const cookies = new Cookies(ctx.request.header.cookie)

  const listener: CookieChangeListener = change => {
    if (change.value === undefined) {
      ctx.cookies.set(change.name)
    } else {
      ctx.cookies.set(change.name, change.value, change.options)
    }
  }

  cookies.addChangeListener(listener)

  return createDisposable(cookies, () => {
    cookies.removeChangeListener(listener)
  })
}

const optionsDefaults = IS_BROWSER ? { path: '/' } : { path: '/', httpOnly: false, overwrite: true }

interface UseCookie {
  <T = string>(
    name: string,
    unmarshall: (input: string) => T,
    marshall: (input: T) => string,
    options?: () => CookieSetOptions
  ): {
    get: (context: Cookies) => T
    set: (context: Cookies, value: T) => void
    rm: (context: Cookies) => void
  }
}

export const createCookie: UseCookie = <T extends any = string>(
  name: string,
  unmarshall: (input: string) => T,
  marshall: (input: T) => string,
  options?: () => CookieSetOptions
) => {
  return {
    get: (context: Cookies) =>
      unmarshall(
        tryOr(
          () =>
            decodeURIComponent(
              context.get(name, {
                doNotParse: true,
              }) ?? ''
            ),
          ''
        )
      ),
    set: (context: Cookies, value: any) => {
      context.set(name, encodeURIComponent(marshall(value)), { ...optionsDefaults, ...options?.() })
    },
    rm: (context: Cookies) => {
      context.remove(name, { ...optionsDefaults, ...options?.() })
    },
  }
}

export const CookiesContext = createContext<Cookies>(new Cookies())

export const CookiesProvider: FunctionComponent<PropsWithChildren<{ cookies: Cookies }>> = ({ cookies, children }) => {
  return <CookiesContext.Provider value={cookies}>{children}</CookiesContext.Provider>
}

export const useCookies = () => useContext(CookiesContext)
