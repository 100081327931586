import { createSelector } from 'reselect'

import { ApiContact, ApiRegion } from '@app/constants/ApiTypes/entities'
import { DEFAULT_SCHOOL_PHONE, ISO8601, SUPPORT_OFFICE_HOURS } from '@app/constants/Misc'

import { assertNever } from '@app/utils/assertNever'
import { createSortHandler } from '@app/utils/createSortHandler'
import moment from '@app/utils/moment'
import { normalize } from '@app/utils/normalizer'
import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { getContacts } from '@app/store/actions/api/contacts'
import { createReduxSlice } from '@app/store/redux_slice'
import { profileRegionIdSelector } from '@app/store/selectors/profile'
import { regionsModelsSelector } from '@app/store/selectors/regions'
import { routingDateSelector } from '@app/store/selectors/routing'

const contactsSlice = createReduxSlice<{ date: ISO8601; contacts: { [key: string]: ApiContact } }>('contacts')

export const contactsModelsResultSelector = createSelector([contactsSlice.selector, routingDateSelector], (meta, date) =>
  intoResult(() => {
    if (!meta || moment(meta.date).add(30, 'minutes').isBefore(date)) {
      throw ActionRequiredError.create('Contacts must be fetched', date.format(), async dispatch => {
        const resp = await dispatch(getContacts()).then(assertApiActionResponse(dispatch, 'Contacts fetch failed'))
        const { contacts } = normalize(resp)
        dispatch(contactsSlice.set({ date: date.format(), contacts: contacts ?? {} }))
      })
    }
    return meta.contacts
  })
)

export const contactsListResultSelector = createSelector([contactsModelsResultSelector, regionsModelsSelector], (modelsResult, regions) =>
  intoResult(() => {
    const models = unwrapResult(modelsResult)
    const contacts = Object.values(models)
      .map(c => {
        const regionId = c.relationships.region.data?.id
        const region = regionId ? regions[regionId] : null
        if (regionId && !region) throw new Error("Can't find region")

        return {
          id: c.id,
          attributes: c.attributes,
          region,
        }
      })
      .sort(createSortHandler(a => [a.region?.id ? getRegionWeight(a.region) : -1, a.region?.id ? -parseFloat(a.region.id) : 1, -parseFloat(a.id)]))

    return contacts
  })
)

export const contactsSchoolsResultSelector = createSelector([contactsListResultSelector], contactsResult =>
  intoResult(() => {
    const contacts = unwrapResult(contactsResult)
    return contacts.reduce<Partial<Record<string, ApiContact['attributes']>>>((acc, c) => {
      if (c.attributes.department === 'school' && !!c.region) {
        acc[c.region.id] = c.attributes
      }
      return acc
    }, {})
  })
)

export const contactsAcademySelector = createSelector([contactsListResultSelector], contactsResult =>
  intoResult(() => unwrapResult(contactsResult).find(c => c.attributes.department === 'academy'))
)

export const createContactDataSelector = (area: 'main' | 'sitter' | 'school') =>
  createSelector([profileRegionIdSelector, contactsListResultSelector], (regionId, contactsResult) =>
    intoResult(() => {
      const contacts = unwrapResult(contactsResult)
      const phone = (() => {
        const defaultPhone = contacts.find(c => c.attributes.department === 'support')?.attributes.phone ?? DEFAULT_SCHOOL_PHONE
        switch (area) {
          case 'main':
            return defaultPhone
          case 'sitter':
            return defaultPhone
          case 'school':
            return (
              (
                contacts.find(c => c.attributes.department === 'school' && c.region && c.region.id === regionId && c.attributes.phone) ??
                contacts.find(c => c.attributes.department === 'school' && !c.region && c.attributes.phone)
              )?.attributes.phone ?? defaultPhone
            )
          default:
            return assertNever(area)
        }
      })()

      return { phone, officeHours: SUPPORT_OFFICE_HOURS }
    })
  )

function getRegionWeight(region: ApiRegion) {
  return REGION_WEIGHTS.indexOf(region.attributes.type) + 1
}

const REGION_WEIGHTS = ['world', 'country', 'city']
