import { api } from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getFeaturesDescriptor } from './features.descriptors'

export const getFeatures = new ApiActionBuilder(getFeaturesDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/features'),
    headers: api.headers(),
  }))
  .build()
