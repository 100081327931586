import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import { AppError } from '@app/errors/AppError'

import { RouteRenderer } from '@app/utils/routing/RouteRenderer'
import { RouteConfigComponentProps } from '@app/utils/routing/types'

import { StoreState } from '@app/store/store'

import MountContainer from './Mount/Mount'

const Root: FunctionComponent<RouteConfigComponentProps> = ({ route }) => {
  const error = useSelector(errorSelector)

  if (error) {
    const err = new AppError(error.message ?? 'Unknown Error').withStatus(error.code)
    if (error.localizedMessage) {
      err.withLocalizedMessage(error.localizedMessage)
    }
    throw err
  }
  if (!route) throw new RouteNotFoundError()

  return (
    <MountContainer>
      <RouteRenderer routes={route.routes} />
    </MountContainer>
  )
}

export default Root

const errorSelector = (state: StoreState) => state.error

class RouteNotFoundError extends Error {
  status = 404

  constructor() {
    super('Route not found')
  }
}
