import { createSelector } from 'reselect'

import { ApiCurrency } from '@app/constants/ApiTypes/entities'

import { createReduxSlice } from '@app/store/redux_slice'

const uiCurrencySlice = createReduxSlice<ApiCurrency>('ui_currency')

export const uiCurrencySelector = createSelector(uiCurrencySlice.selector, state => state || null)

export const setCurrency = (c: ApiCurrency) => uiCurrencySlice.set(c)
