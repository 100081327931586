import { api } from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getContactsDescriptor } from './contacts.descriptors'

export const getContacts = new ApiActionBuilder(getContactsDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/contacts'),
    headers: api.headers(),
  }))
  .build()
