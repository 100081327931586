import { invariant } from '@app/utils/invariant'

import { getRootContext } from '@app/packages/actionContext/actions'

import { createThunk } from '@app/store/thunk'

import { MountContextInterface } from './types'

const MOUNT_SYMBOL = Symbol('MOUNT_SYMBOL')

export const getMount = () =>
  createThunk((_dispatch, _getState, context): MountContextInterface | null => {
    return context[MOUNT_SYMBOL] ?? null
  })

export const assertMount = () =>
  createThunk((dispatch, _getState) => {
    return dispatch(getMount()) ?? invariant(new Error('Mount context is null'))
  })

export const setMount = (mount: MountContextInterface) =>
  createThunk(dispatch => {
    const context = dispatch(getRootContext())
    context[MOUNT_SYMBOL] = mount
  })

export const unsetMount = () =>
  createThunk(dispatch => {
    const context = dispatch(getRootContext())
    context[MOUNT_SYMBOL] = null
  })
