import { ApiTrainingRequest } from '@app/constants/ApiTypes/entities'

import {
  getTrainingRequestDescriptor,
  postTrainingRequestDescriptor,
  putTrainingRequestByIdDescriptor,
} from '@app/store/actions/api/training_request.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ data: ApiTrainingRequest | null } | null>(null, builder => {
  builder.addCases(
    [getTrainingRequestDescriptor.shapes.fulfilled, postTrainingRequestDescriptor.shapes.fulfilled, putTrainingRequestByIdDescriptor.shapes.fulfilled],
    (_state, action) => {
      return { data: action.payload.data }
    }
  )
})
