import { api } from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getSitterDescriptionTemplateDescriptor } from './sitter_description_template.descriptors'

export const getSitterDescriptionTemplate = new ApiActionBuilder(getSitterDescriptionTemplateDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.internal('/api/v1/sitter_description_template'),
    headers: api.headers(),
  }))
  .build()
