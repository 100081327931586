import { createSelector } from 'reselect'

import { createReduxSlice } from '@app/store/redux_slice'
import { createThunk } from '@app/store/thunk'

const themeColorSlice = createReduxSlice<string>('ui_theme_color')

export const setThemeColor = (color: string) => themeColorSlice.set(color)
export const getThemeColor = () => createThunk((_dispatch, getState) => themeColorSelector(getState()))

export const themeColorSelector = createSelector([themeColorSlice.selector], color => color ?? '#fff')
