import { asError } from '@app/packages/asError/asError'
import { Result, resultError, resultOk } from '@app/packages/Result/Result'

export function tryJSONparse<T>(data?: string | null): Result<T> {
  try {
    const value = JSON.parse(data || '')
    return resultOk(value)
  } catch (e) {
    return resultError(asError(e))
  }
}
