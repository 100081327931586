import { ABORT_ERROR_CODE, CLIENT_ERROR_CODE, NETWORK_ERROR_CODE } from '@app/constants/Misc'

import { AbortError, isAbortError } from '@app/errors/AbortError'
import { createFetchDataError, FetchDataErrorMessage } from '@app/errors/FetchDataError'

import { asNetworkError, isApiError, isNetworkFailError } from '@app/store/apiMiddleware/errors'
import { ApiActionResult } from '@app/store/apiMiddleware/types'
import { StoreDispatch } from '@app/store/dispatch'
import { StoreState } from '@app/store/store'
import { createThunk } from '@app/store/thunk'

import { captureException } from './errorReport/errorReport'
import { StaticRedirect } from './routing/StaticRedirect'
import { Action, LocationDescriptorObject, MatchedRoute } from './routing/types'

export type StaticRouterState<M extends object = any> = MatchedRoute<M> & {
  location: LocationDescriptorObject
  action: Action
  /**
   * Equals to `true` on initial server run.
   * Use this to avoid double fetch on server/client transition
   */
  serverInitial: boolean
  /**
   * Equals to `true` on initial client run.
   * Use this to avoid double fetch on server/client transition
   */
  browserInitial: boolean
}

export type FetchDataObject<M extends object = any> = {
  routerState: StaticRouterState<M>
  store: { dispatch: StoreDispatch; getState: () => StoreState }
}

export type FetchData<M extends object = any> = (data: FetchDataObject<M>) => Promise<void | StaticRedirect> | void | StaticRedirect

export interface WithFetchData<M extends object = any> {
  fetchData: FetchData<M>
}

/**
 * Accepts an array of matched routes as returned from react-router's
 * `Router.run()` and calls the given static method on each. The methods may
 * return a promise.
 *
 * Returns a promise that resolves after any promises returned by the routes
 * resolve. The practical uptake is that you can wait for your data to be
 * fetched before continuing. Based off react-router's async-data example
 * https://github.com/rackt/react-router/blob/master/examples/async-data/app.js#L121
 * @param mathodName - Method to call
 * @param location - Location to pass via context
 * @param matchedRoutes - List of matched routes for location
 * @param store - Redux store
 *
 * @returns possibly returns instance of StaticRedirect
 */
export function performFetchData<M extends object = any>(location: LocationDescriptorObject, action: Action, matchedRoutes: MatchedRoute<M>[]) {
  return createThunk(async (dispatch, getState): Promise<null | StaticRedirect> => {
    const {
      routing: { isInitial },
    } = getState()

    const routerState: StaticRouterState<M> = {
      ...matchedRoutes.at(-1)!,
      location,
      action,
      serverInitial: isInitial && !IS_BROWSER,
      browserInitial: isInitial && IS_BROWSER,
    }

    const fetchers = matchedRoutes.flatMap<FetchData>(({ route }) => {
      const fetchData = extractFetchData(route.component)
      return fetchData ?? []
    })

    const store = { dispatch, getState }

    for (const fetcher of fetchers) {
      try {
        const result = await fetcher({ routerState, store })
        if (result instanceof StaticRedirect) return result
      } catch (e) {
        if (isAbortError(e)) continue
        throw e
      }
    }

    return null
  })
}

type ApiActionErrorOptions = {
  expose?: boolean
  handled?: boolean
}

export const assertApiActionResponse =
  (
    dispatch: StoreDispatch,
    errorString: FetchDataErrorMessage | ((error: Error) => FetchDataErrorMessage),
    options?: ApiActionErrorOptions | ((error: Error) => ApiActionErrorOptions)
  ) =>
  <R>(resp: ApiActionResult<R>) => {
    if (!resp) {
      const err = new AbortError('Api action was aborted')
      throw dispatch(createFetchDataError(typeof errorString === 'function' ? errorString(err) : errorString, ABORT_ERROR_CODE)).withCause(err)
    }
    if (resp.error) {
      const err = errorToFetchDataError(dispatch, resp.payload, typeof errorString === 'function' ? errorString(resp.payload) : errorString, options)
      if (isApiError(resp.payload) && resp.payload.status && resp.payload.status >= 500) {
        captureException(err)
      }

      throw err
    }
    return resp.payload
  }

export const errorToFetchDataError = (
  dispatch: StoreDispatch,
  error: Error,
  errorString: FetchDataErrorMessage,
  options?: ApiActionErrorOptions | ((error: Error) => ApiActionErrorOptions)
) => {
  const err = dispatch(
    createFetchDataError(
      errorString,
      isAbortError(error) ? ABORT_ERROR_CODE : isNetworkFailError(error) ? NETWORK_ERROR_CODE : (asNetworkError(error)?.status ?? CLIENT_ERROR_CODE)
    )
  ).withCause(error)
  const opts = (typeof options === 'function' ? options(error) : options) ?? {}
  for (const key of Reflect.ownKeys(opts)) {
    err[key] = opts[key]
  }
  err.handled = opts.handled ?? (error as any).handled ?? isNetworkFailError(error)
  return err
}

function extractFetchData(component: any): FetchData | null {
  if (component['fetchData']) return component['fetchData']
  const wrapped = component?.WrappedComponent
  if (wrapped) return extractFetchData(wrapped)
  return null
}
