export const LOCALES = [
  {
    locale: 'ru',
    country: 'ru',
    default: true,
  },
  {
    locale: 'en',
  },
] as const

export type Locale = (typeof LOCALES)[number]['locale']

export const DEFAULT_LOCALE: Locale = 'ru'

export const LOCALES_LIST = LOCALES.map(l => l.locale)

export const TRANSLATED_LOCALES = LOCALES_LIST.filter(l => l !== 'ru')
