import { createSelector } from 'reselect'

import { GetRequestsQuery } from '@app/constants/ApiTypes/requests'

import moment from '@app/utils/moment'
import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { getRequestsWithDebt } from '@app/store/actions/api/requests'
import { defaultAnnouncementMeta } from '@app/store/misc/announcements'
import { createReduxSlice } from '@app/store/redux_slice'
import { Selector, StoreState } from '@app/store/store'
import { StoreUserParent, StoreUserSitter } from '@app/store/types/users'

import { announcementsModelsMetaSelector, announcementsModelsSelector } from './announcement'
import { announcementResponsesSelector } from './announcementResponse'
import { locationsSelector } from './misc'
import { profileUserResultSelector } from './profile'
import { routingDateSelector } from './routing'
import { usersSelector } from './user'

export const requestsStateSelector = (state: StoreState) => state.requests
export const requestsModelsSelector = (state: StoreState) => state.requests.models

export const createRequestsFetchCompleteSelector = (metaSelector: (state: StoreState) => { next?: GetRequestsQuery | null }) => (state: StoreState) => {
  const meta = metaSelector(state)
  return meta.next === null
}

export const requestByIdSelectorActor = (
  requestId: string | null,
  models: ReturnType<typeof requestsModelsSelector>,
  announcementResponses: ReturnType<typeof announcementResponsesSelector>,
  announcements: ReturnType<typeof announcementsModelsSelector>,
  announcements_meta: ReturnType<typeof announcementsModelsMetaSelector>,
  users: ReturnType<typeof usersSelector>,
  locations: ReturnType<typeof locationsSelector>
) => {
  if (!requestId) return null

  const request = models[requestId]
  if (!request) return null

  const announcement =
    request.relationships.announcement && request.relationships.announcement.data ? announcements[request.relationships.announcement.data.id] : null
  if (announcement === undefined) return null
  const announcement_meta =
    (request.relationships.announcement && request.relationships.announcement.data && announcements_meta[request.relationships.announcement.data.id]) ||
    defaultAnnouncementMeta

  const announcement_response =
    request.relationships.announcement_response && request.relationships.announcement_response.data
      ? announcementResponses[request.relationships.announcement_response.data.id]
      : null
  if (announcement_response === undefined) return null

  if (!request.relationships.parent || !request.relationships.parent.data) return null
  const parent = users[request.relationships.parent.data.id] as StoreUserParent
  if (!parent) return null

  if (!request.relationships.sitter || !request.relationships.sitter.data) return null
  const sitter = users[request.relationships.sitter.data.id] as StoreUserSitter
  if (!sitter) return null

  if (!request.relationships.location || !request.relationships.location.data) return null
  const location = locations[request.relationships.location.data.id]
  if (!location) return null

  const announcement_response_bundle =
    announcement && announcement_response
      ? {
          response: announcement_response,
          announcement,
          location,
          parent,
          sitter,
        }
      : null

  const announcement_bundle = announcement
    ? {
        announcement,
        meta: announcement_meta,
        location,
        parent,
        sitter,
        responses: announcement_response_bundle ? [announcement_response_bundle] : [],
      }
    : null

  return {
    request,
    announcement: announcement_bundle,
    announcement_response: announcement_response_bundle,
    location,
    parent,
    sitter,
  }
}

export type RequestBundle = NonNullable<ReturnType<typeof requestByIdSelectorActor>>

export const makeRequestByIdSelector = <Props = undefined>(requestIdSelector: Selector<string | null, Props>) =>
  createSelector(
    [
      requestIdSelector,
      requestsModelsSelector,
      announcementResponsesSelector,
      announcementsModelsSelector,
      announcementsModelsMetaSelector,
      usersSelector,
      locationsSelector,
    ],
    requestByIdSelectorActor
  )

export const requestsListIdsSelector = (state: StoreState) => state.requests.list

export const requestsListSelector = createSelector(
  [
    requestsListIdsSelector,
    requestsModelsSelector,
    announcementResponsesSelector,
    announcementsModelsSelector,
    announcementsModelsMetaSelector,
    usersSelector,
    locationsSelector,
  ],
  (ids, models, responses, announcements, announcements_meta, users, locations) => {
    return ids.map(id => requestByIdSelectorActor(id, models, responses, announcements, announcements_meta, users, locations)!).filter(x => !!x)
  }
)

const requestsWithDebtSlice = createReduxSlice<{ loaded_at: number; hash: string }>('requests_with_debt')
export const requestsWithDebtSyncSlice = createReduxSlice<{ hash: string }>('requests_with_debt_sync')

export const requestsWithDebtIdsResultSelector = createSelector(
  [
    requestsWithDebtSlice.selector,
    requestsWithDebtSyncSlice.selector,
    profileUserResultSelector,
    routingDateSelector,
    (state: StoreState) => state.requests.with_debt,
  ],
  (meta, sync, userResult, date, list) =>
    intoResult(() => {
      const user = unwrapResult(userResult)
      if (user?.account_type !== 'parent') return []
      const hash = [user.id, sync?.hash ?? ''].join(':')
      if (!meta || meta.hash !== hash || moment.unix(meta.loaded_at).add(10, 'minutes').isBefore(date)) {
        throw ActionRequiredError.create('Requests with debt must be fetched', [hash, date.format()].join(':'), async dispatch => {
          await dispatch(getRequestsWithDebt()).then(assertApiActionResponse(dispatch, 'Requests with debt fetch failed'))
          dispatch(requestsWithDebtSlice.set({ hash, loaded_at: date.unix() }))
        })
      }
      if (!list) throw new Error('Requests with debt are missing')
      return list
    })
)

export const requestsWithDebtResultSelector = createSelector(
  [
    requestsWithDebtIdsResultSelector,
    requestsModelsSelector,
    announcementResponsesSelector,
    announcementsModelsSelector,
    announcementsModelsMetaSelector,
    usersSelector,
    locationsSelector,
  ],
  (idsResult, models, responses, announcements, announcements_meta, users, locations) =>
    intoResult(() => {
      const ids = unwrapResult(idsResult)
      return ids.map(id => requestByIdSelectorActor(id, models, responses, announcements, announcements_meta, users, locations))
    })
)

export const debtTotalResultSelector = createSelector([requestsWithDebtResultSelector], itemsResult =>
  intoResult(() => {
    const items = unwrapResult(itemsResult)
    return Object.entries(
      items.reduce<{ [key: string]: number }>((acc, r) => {
        const amount = r?.request.attributes.amount
        if (!amount) return acc
        acc[amount.service_fee_currency ?? ''] = (acc[amount.service_fee_currency ?? ''] || 0) + parseFloat(amount.service_fee)
        return acc
      }, {})
    )
  })
)
