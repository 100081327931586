import { v4 } from 'uuid'

import { ISO8601 } from '@app/constants/Misc'

import moment from '@app/utils/moment'
import { RouterState } from '@app/utils/routing/types'

import { beforeNavigate, navigate } from '@app/store/actions/router.descriptors'
import { postServer } from '@app/store/actions/server.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ isInitial: boolean; state: RouterState | null; uuid: string; date: ISO8601; isTransitioning: boolean }>(
  { isInitial: true, state: null, isTransitioning: false, uuid: v4(), date: moment().format() },
  builder => {
    builder.addCase(postServer.shape, (state, _action) => {
      return { ...state, date: moment().format() }
    })
    builder.addCase(beforeNavigate.shape, (state, action) => {
      return { ...state, isInitial: action.payload.initial, isTransitioning: true }
    })

    builder.addCase(navigate.shape, (state, action) => {
      return { ...state, isTransitioning: false, state: action.payload, uuid: state.isInitial ? state.uuid : v4(), date: moment().format() }
    })
  }
)
