import { Result, unwrapResult } from '@app/packages/Result/Result'

export { setActionRequiredErrorStore } from './useSelectorResult.shared'

export const useSelectorResult: typeof import('./useSelectorResult.client').useSelectorResult = IS_BROWSER
  ? require('./useSelectorResult.client').useSelectorResult
  : require('./useSelectorResult.server').useSelectorResult

export const useSelectorResultValue = <T>(selector: (...args: any[]) => Result<T>) => {
  const val = useSelectorResult(selector)
  return unwrapResult(val)
}

export const clearErrors: typeof import('./useSelectorResult.client').clearErrors = IS_BROWSER
  ? require('./useSelectorResult.client').clearErrors
  : require('./useSelectorResult.server').clearErrors
