import { createSelector } from 'reselect'

import { captureException } from '@app/utils/errorReport/errorReport'
import loggerCreator from '@app/utils/loggerCreator'
import moment, { Moment } from '@app/utils/moment'
import { wrapError, wrapPromise } from '@app/utils/wrapError'

import { withAbortSignal } from '@app/packages/abortContext/actions'
import { asError } from '@app/packages/asError/asError'
import { LazyPromise } from '@app/packages/LazyPromise/LazyPromise'
import { resolveSelectorResult } from '@app/packages/selectorResult/SelectorResult'

import { getCurrencies, restoreCurrency } from '@app/store/actions/currencies'
import { getRegions, restoreTestFlights } from '@app/store/actions/initial'
import { setConfig } from '@app/store/actions/misc'
import { getPlans, restorePlanModifier } from '@app/store/actions/plan'
import { restorePlace } from '@app/store/actions/profile'
import { unwrapApiActionResult } from '@app/store/apiMiddleware/utils'
import { profileUserResultSelector, profileUserSelector } from '@app/store/selectors/profile'
import { restoreRelatedApps } from '@app/store/slices/related_apps'
import { restoreBannersState } from '@app/store/slices/ui_banners'
import { Store, storeMonitoring } from '@app/store/store'

import { BuildChecker } from './BuildChecker'

type CheckerLabel = 'profile' | 'regions' | 'debts' | 'card' | 'news' | 'identities' | 'telegram_bot_connection'

export class DataRefreshService {
  private logger = loggerCreator('DataRefresh', 'lightcoral', undefined, true)
  private started = false
  private stopHandlers: (() => unknown)[] = []
  private store: Store

  private checkers = new Map<CheckerLabel, LazyPromise<any>>()

  private buildChecker: BuildChecker

  onPlaceRestore?: () => Promise<void>

  constructor(store: Store) {
    this.store = store
    this.buildChecker = new BuildChecker(this.store)
  }

  async initServer(abortSignal: AbortSignal): Promise<{ error: false } | { error: true; payload: Error }> {
    this.store.dispatch(withAbortSignal(abortSignal, restoreTestFlights()))
    this.store.dispatch(withAbortSignal(abortSignal, restorePlanModifier()))
    const planModifier = this.store.getState().plans.modifier

    const promises: Promise<any>[] = []

    this.store.dispatch(restoreBannersState())

    const regionsPromise = unwrapApiActionResult(this.store.dispatch(withAbortSignal(abortSignal, getRegions())))
    const plansPromise = unwrapApiActionResult(this.store.dispatch(withAbortSignal(abortSignal, getPlans(planModifier))))
    const currenciesPromise = unwrapApiActionResult(this.store.dispatch(withAbortSignal(abortSignal, getCurrencies())))

    const profilePromise = this.store
      .dispatch(withAbortSignal(abortSignal, resolveSelectorResult(profileUserResultSelector)))
      .then(() => Promise.all([regionsPromise, plansPromise, currenciesPromise]))
      .then(() => this.onProfileChange())

    promises.push(regionsPromise, plansPromise, currenciesPromise, profilePromise)

    try {
      await Promise.all(promises)
    } catch (e) {
      return { error: true, payload: asError(e) }
    }

    return { error: false }
  }

  async initBrowser() {
    this.logger('Init')

    if (this.isPolygon()) return

    await Promise.all([this.store.dispatch(restoreRelatedApps())])
    await this.check()
  }

  start() {
    if (this.started) return
    this.started = true
    this.logger('Start')

    const isApp = this.store.getState().config.isApp

    if (!isApp) {
      this.buildChecker.start(60 * 1000)
      const unsub = this.trackOnlineStatus()
      this.stopHandlers.push(unsub)
    }

    this.check()
    const interval = setInterval(() => {
      this.check().catch(e => {
        captureException(e, { tags: { service: 'DataRefreshService' } })
      })
    }, 60 * 1000)
    this.stopHandlers.push(() => {
      clearInterval(interval)
    })

    const unsubProfile = storeMonitoring(this.store, [profileDataSelector], async ([newData], [oldData]) => {
      if (newData && oldData?.id !== newData.id) this.onProfileChange()
    })
    this.stopHandlers.push(unsubProfile)
  }

  stop() {
    if (!this.started) return
    this.logger('Stop')
    this.buildChecker.stop()
    this.stopHandlers.forEach(h => h())
    this.stopHandlers = []
    this.started = false
  }

  private upsertCheckers<T>(key: CheckerLabel, val: LazyPromise<T>) {
    if (!this.checkers.has(key)) {
      this.checkers.set(key, val)
    }
    return this.checkers.get(key) as LazyPromise<T>
  }

  private isPolygon() {
    return this.store.getState().config.isPolygon
  }

  private async check() {
    if (this.isPolygon()) return

    this.logger('Check')

    try {
      this.checkRegions()
    } catch (e) {
      throw wrapError(new Error('DataRefresh check failed'), e)
    } finally {
      this.logger('Check ended')
    }
  }

  private async checkRegions() {
    await this.processChecker(
      'regions',
      new LazyPromise(async () => {
        const loadedAt = this.store.getState().regions.state.loadedAt
        if (loadedAt && !this.isExpired(moment(loadedAt), this.getExpirationDate(60))) return
        this.logger('Check regions')
        await unwrapApiActionResult(this.store.dispatch(getRegions()))
      }),
      this.getExpirationDate(60)
    )
  }

  private getExpirationDate(minutes: number) {
    return moment().subtract(minutes, 'minutes')
  }

  private async processChecker<T>(key: CheckerLabel, p: LazyPromise<T>, expireDate: Moment): Promise<T> {
    const checker = this.upsertCheckers(key, p)
    if (checker.loading.value) return checker.getValue()
    if (checker.loadedAt.value && !this.isExpired(checker.loadedAt.value, expireDate)) return checker.getValue()
    checker.unset()
    return await checker.getValue()
  }

  private isExpired(ts: Moment | null, base: Moment) {
    if (!ts) return true
    return ts.isBefore(base)
  }

  private async onProfileChange() {
    await this.store.dispatch(restorePlace())
    await this.store.dispatch(restoreCurrency())
    await wrapPromise(this.onPlaceRestore?.() ?? Promise.resolve(), new Error('Failed to restore place'))
  }

  /** tracks online/offline status so we can show bar that warns when connection lost */
  private trackOnlineStatus() {
    const handler = () => {
      this.store.dispatch(setConfig({ online: window.navigator.onLine }))
    }
    window.addEventListener('online', handler)
    window.addEventListener('offline', handler)

    return () => {
      window.removeEventListener('online', handler)
      window.removeEventListener('offline', handler)
    }
  }
}

const profileDataSelector = createSelector([profileUserSelector], profile => {
  if (!profile) return null
  if (profile.account_type === 'visitor') return null
  return { id: profile.id, account_type: profile.account_type }
})
