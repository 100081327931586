import * as valita from '@badrap/valita'
import { createSelector } from 'reselect'

import { JSONSerializableStatic } from '@app/utils/jsonSerializable'
import moment, { Moment } from '@app/utils/moment'
import { tryJSONparse } from '@app/utils/tryJSONparse'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, Result } from '@app/packages/Result/Result'

import { unwrapApiActionResult } from '@app/store/apiMiddleware/utils'
import { StoreState } from '@app/store/store'
import { createThunk, ThunkAction } from '@app/store/thunk'
import { createAction } from '@app/store/toolkit'

import { getKeyValuesById, putKeyValuesById } from './api/key_values'

type KeyValueSetter = {
  (key: string): {
    get: () => ThunkAction<Promise<string | null>>
    set: (value: string) => ThunkAction<Promise<void>>
    selector: (state: StoreState) => Result<{ fetchedAt: Moment; value: string | null }>
  }
  <T>(
    key: string,
    serialize: (value: T) => string,
    deserialize: (value: string) => T | null
  ): {
    get: () => ThunkAction<Promise<T | null>>
    set: (value: T) => ThunkAction<Promise<void>>
    selector: (state: StoreState) => Result<{ fetchedAt: Moment; value: T | null }>
  }
  <T extends JSONSerializableStatic>(
    key: string,
    cl: T
  ): {
    get: () => ThunkAction<Promise<InstanceType<T> | null>>
    set: (value: InstanceType<T>) => ThunkAction<Promise<void>>
    selector: (state: StoreState) => Result<{ fetchedAt: Moment; value: InstanceType<T> | null }>
  }
  <T extends valita.Type>(
    key: string,
    scheme: T
  ): {
    get: () => ThunkAction<Promise<valita.Infer<T> | null>>
    set: (value: valita.Infer<T>) => ThunkAction<Promise<void>>
    selector: (state: StoreState) => Result<{ fetchedAt: Moment; value: valita.Infer<T> | null }>
  }
}

export const setKeyValue = createAction<'setKeyValue', { key: string; value: null | string }>('setKeyValue')

export const createKeyValue: KeyValueSetter = (key: string, arg1?: unknown, arg2?: unknown) => {
  const mode = !arg1
    ? { type: 'string' as const }
    : typeof arg1 === 'function' && 'fromJSON' in arg1
      ? { type: 'serializable' as const, cl: arg1 as JSONSerializableStatic }
      : typeof arg1 === 'function'
        ? { type: 'custom' as const, serialize: arg1, deserialize: arg2 as (value: string) => any }
        : { type: 'scheme' as const, scheme: arg1 as valita.Type }

  const serialize = (value: any): string => {
    switch (mode.type) {
      case 'string':
        return value
      case 'serializable':
        return JSON.stringify(value.toJSON())
      case 'custom':
        return mode.serialize(value)
      case 'scheme':
        return JSON.stringify(value)
    }
  }

  const deserialize = (value: string) => {
    switch (mode.type) {
      case 'string':
        return value
      case 'serializable':
        try {
          return mode.cl.fromJSON(JSON.parse(value))
        } catch {
          return null
        }
      case 'custom':
        return mode.deserialize(value)
      case 'scheme': {
        const dataResult = tryJSONparse(value)
        const result = mode.scheme.try(dataResult.value, { mode: 'strip' })
        if (!result.ok) return null
        return result.value
      }
    }
  }

  const dataSelector = (state: StoreState) => state.key_values[key]

  const get = () => {
    return createThunk(async (dispatch): Promise<any | null> => {
      const resp = await unwrapApiActionResult(dispatch(getKeyValuesById(key)))
      if (resp.data.attributes.value === null) {
        dispatch(setKeyValue({ key, value: null }))
        return null
      }
      const value = deserialize(resp.data.attributes.value)
      dispatch(setKeyValue({ key, value: resp.data.attributes.value }))
      return value
    })
  }

  const selector = createSelector([dataSelector], keyStore =>
    intoResult(() => {
      if (!keyStore?.fetchedAt)
        throw ActionRequiredError.create(`Key "${key}" should be fetched`, '', async dispatch => {
          await dispatch(get())
        })
      const value = keyStore.value === null ? null : deserialize(keyStore.value)
      return { fetchedAt: moment(keyStore.fetchedAt), value }
    })
  )

  return {
    get,
    set: (value: any) => {
      return createThunk(async dispatch => {
        const strVal = serialize(value)

        await unwrapApiActionResult(dispatch(putKeyValuesById(key, { value: strVal })))
        dispatch(setKeyValue({ key, value: strVal }))
      })
    },
    selector,
  }
}
