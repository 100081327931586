import { createSelector } from 'reselect'

import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult } from '@app/packages/Result/Result'

import { getSitterDescriptionTemplate } from '@app/store/actions/api/sitter_description_template'
import { createReduxSlice } from '@app/store/redux_slice'
import { localeSelector } from '@app/store/selectors/misc'

const sitterDescriptionTemplateSlice = createReduxSlice<{ locale: string; template: string }>('sitter_description_template')

export const sitterDescriptionTemplateResultSelector = createSelector([sitterDescriptionTemplateSlice.selector, localeSelector], (meta, locale) =>
  intoResult(() => {
    if (!meta || meta.locale !== locale) {
      throw ActionRequiredError.create('Sitter Description Template must be loaded', locale, async dispatch => {
        const resp = await dispatch(getSitterDescriptionTemplate()).then(assertApiActionResponse(dispatch, 'Sitter description template fetch failed'))
        dispatch(sitterDescriptionTemplateSlice.set({ locale, template: resp.data.attributes.template }))
      }).withExtra({ locale: meta?.locale })
    }

    return meta.template.trim()
  })
)
