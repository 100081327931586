import { ApiIgnoredUser } from '@app/constants/ApiTypes/entities'
import { ApiGetIgnoredUserListRequest, ApiPostIgnoredUserRequest } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const postIgnoredUserDescriptor = new ApiActionBuilderDescriptor().setLabel('postIgnoredUser').setShape<unknown, ApiPostIgnoredUserRequest>()
export const deleteIgnoredUserDescriptor = new ApiActionBuilderDescriptor().setLabel('deleteIgnoredUser').setShape<unknown, { userId: string }>()
export const getIgnoredUsersDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getIgnoredUsers')
  .setShape<{ data: ApiIgnoredUser[]; meta: { current_page: number; total_pages: number; total: number } }, ApiGetIgnoredUserListRequest>()
export const getIgnoredUsersByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getIgnoredUsersById')
  .setShape<{ data: ApiIgnoredUser }, { userId: string }>()
