import React, { FunctionComponent, Suspense } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { NavLink } from '@app/utils/routing/NavLink'

import { intoResult } from '@app/packages/Result/Result'
import { useSelectorResultValue } from '@app/packages/selectorResult/useSelectorResult'

import { profileUserResultSelector } from '@app/store/selectors/profile'
import { profileAvatarSelector } from '@app/store/slices/profile_avatars'

import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'

import './UserLabel.scss'

export interface UserLabelProps {
  className?: string
  to?: string
}

export const UserLabel: FunctionComponent<UserLabelProps> = ({ className, to }) => {
  const name = useSelectorResultValue(userNameSelector)
  const avatar = useSelector(profileAvatarSelector)
  const { formatMessage } = useIntl()

  const content = (
    <React.Fragment>
      <Suspense fallback={null}>
        <Avatar className="user-label-od67__avatar" name={name} src={avatar?.attributes.image.thumb} />
      </Suspense>
      <span className="user-label-od67__label">{formatMessage(messages.label)}</span>
    </React.Fragment>
  )

  if (to)
    return (
      <NavLink className={cn(ControlClasses.m_no_appearance, 'user-label-od67', className)} eventName="layout.user_label.user_link" to={to}>
        {content}
      </NavLink>
    )

  return <span className={cn('user-label-od67', className)}>{content}</span>
}

const userNameSelector = createSelector([profileUserResultSelector], userResult =>
  intoResult(() => {
    const user = userResult.value
    if (!user) return '??'
    if ('name' in user) return user.name
    return '??'
  })
)

const Avatar = React.lazy(() => import('@app/components/Avatar/Avatar').then(m => ({ default: m.Avatar })))

const messages = defineMessages({
  label: 'Личный кабинет',
})
