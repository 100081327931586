import { createSelector } from 'reselect'

import { getInstalledRelatedApps, RelatedApp } from '@app/utils/getInstalledRelatedApps'

import { createReduxSlice } from '@app/store/redux_slice'
import { createThunk } from '@app/store/thunk'

const relatedAppsSlice = createReduxSlice<{ list: RelatedApp[] }>('related_apps')

export const relatedAppsSelector = createSelector(relatedAppsSlice.selector, state => state?.list ?? [])

export function restoreRelatedApps() {
  return createThunk(async dispatch => {
    const apps = await getInstalledRelatedApps()
    dispatch(relatedAppsSlice.set({ list: apps }))
  })
}
