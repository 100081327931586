import { RouterState } from '@app/utils/routing/types'

import { createAction } from '@app/store/toolkit'

export const beforeNavigate = createAction<
  'ROUTER_BEFORE_NAVIGATE',
  {
    state: RouterState
    initial: boolean
  }
>('ROUTER_BEFORE_NAVIGATE')

export const navigate = createAction<'ROUTER_NAVIGATE', RouterState>('ROUTER_NAVIGATE')
