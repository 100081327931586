import { defineMessages } from 'react-intl'
import { createSelector } from 'reselect'
import { v4 } from 'uuid'

import { ApiOnboardingStepTraining } from '@app/constants/ApiTypes/entities'
import { SitterStatus } from '@app/constants/ApiTypes/sitterStatus'

import { assertNever } from '@app/utils/assertNever'
import moment from '@app/utils/moment'
import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { getSitterOnboarding } from '@app/store/actions/api/sitter_onboarding'
import {
  approvedBannerSeenStateKeyValue,
  onboardingApplicationStepsSeenStateKeyValue,
  onboardingTestPassedSeenStateKeyValue,
  onboardingTrainingPassedSeenKeyValue,
} from '@app/store/actions/sitter_onboarding'
import { createReduxSlice } from '@app/store/redux_slice'
import { contactsListResultSelector } from '@app/store/slices/contacts'
import { onboardingV2EnabledResultSelector } from '@app/store/slices/features'
import { intlSelector } from '@app/store/slices/intl'
import { StoreState } from '@app/store/store'

import { profileUserResultSelector } from './profile'
import { schoolModelsSelector } from './schools'

const onboardingSlice = createReduxSlice<{ hash: string }>('sitter_onboarding_v2')
const onboardingSyncSlice = createReduxSlice<{ hash: string }>('sitter_onboarding_v2_sync')

export const resyncOnboarding = () => onboardingSyncSlice.set({ hash: v4() })

const onboardingResultSelector = createSelector(
  [
    onboardingSlice.selector,
    onboardingSyncSlice.selector,
    profileUserResultSelector,
    onboardingV2EnabledResultSelector,
    (state: StoreState) => state.sitter_onboarding,
  ],
  (meta, sync, userResult, enabledResult, onboarding) =>
    intoResult(() => {
      const user = unwrapResult(userResult)
      if (!user) throw new Error('User is missing')
      if (user.account_type !== 'sitter') throw new Error('User is not sitter')
      const enabled = unwrapResult(enabledResult)
      if (!enabled) throw new Error('Onboarding v2 is not enabled')
      const hash = [user.id, user.has_training_request, user.training_completed, sync?.hash ?? ''].join(':')
      if (!meta || meta.hash !== hash) {
        throw ActionRequiredError.create('Sitter onboarding must be fetched', hash, async dispatch => {
          await dispatch(getSitterOnboarding()).then(assertApiActionResponse(dispatch, 'Onboarding load failed'))
          dispatch(onboardingSlice.set({ hash }))
        })
      }
      if (!onboarding) throw new Error('Onboarding is missing')
      return onboarding
    })
)
const onboardingStepsSelector = (state: StoreState) => state.sitter_onboarding_steps

export const sitterStatusV2Selector = createSelector(
  [
    onboardingV2EnabledResultSelector,
    profileUserResultSelector,
    onboardingResultSelector,
    onboardingStepsSelector,
    onboardingApplicationStepsSeenStateKeyValue.selector,
    onboardingTestPassedSeenStateKeyValue.selector,
    onboardingTrainingPassedSeenKeyValue.selector,
    approvedBannerSeenStateKeyValue.selector,
    schoolModelsSelector,
    contactsListResultSelector,
    intlSelector,
  ],
  (
    enabledResult,
    userResult,
    onboardingResult,
    onboardingSteps,
    stepsSeenResult,
    testPassedSeenResult,
    trainingPassedSeenResult,
    approvedBannerSeenResult,
    schools,
    contactsResult,
    intl
  ) =>
    intoResult((): SitterStatus => {
      const { formatMessage } = intl
      const user = unwrapResult(userResult)
      if (user?.account_type !== 'sitter') throw new Error('User is not sitter')

      const shouldApprovedBannerBeVisible = () => {
        if (!user.approved) throw new Error('User is not yet approved')
        if (!user.approved_at) throw new Error('User has missing approve date')
        if (moment(user.approved_at).isBefore('2025-02-20T14:00:00+03:00')) return false

        const approvedBannerSeen = unwrapResult(approvedBannerSeenResult)
        return !approvedBannerSeen.value?.seen
      }

      if (user.approved) {
        return { type: 'approved', banner_visible: shouldApprovedBannerBeVisible() }
      }

      const enabled = unwrapResult(enabledResult)
      if (!enabled) return { type: 'v2_disabled' }
      if (typeof user.age === 'number' && user.age < 18) return { type: 'application_on_hold', reason: 'underage', min_age: 18 }

      const onboarding = unwrapResult(onboardingResult)
      if (!onboarding.relationships.steps.data) throw new Error('No onboarding relationships')
      const steps = onboarding.relationships.steps.data.map(d => {
        const step = onboardingSteps[d.id]
        if (!step) throw new Error(`Step ${d.id} not found`)
        return step
      })

      const stepsSeen = unwrapResult(stepsSeenResult)
      if (!stepsSeen.value?.seen) return { type: 'application_accepted' }

      const contacts = unwrapResult(contactsResult)
      const contact = (() => {
        const region_id = String(user.region_id)
        const c =
          contacts.find(c => c.attributes.department === 'school' && c.region && c.region.id === region_id) ||
          contacts.find(c => c.attributes.department === 'school' && !c.region)
        if (!c) return null
        const result = {
          whatsapp: c.attributes.whatsapp ?? undefined,
          telegram: c.attributes.telegram ?? undefined,
        }
        return result
      })()

      const hasRejectedStep = steps.find(s => {
        if (s.attributes.name === 'testing' && s.attributes.status === 'failed') return true
        if (s.attributes.name === 'training' && s.attributes.status === 'rejected') return true
        if (s.attributes.name === 'profile' && s.attributes.status === 'disapproved') return true
        return false
      })

      if (hasRejectedStep) {
        const trainingStep = steps.find((s): s is ApiOnboardingStepTraining => s.attributes.name === 'training')
        if (!trainingStep) throw new Error('Training step is missing')
        const feedbackLink = trainingStep.attributes.feedback_link
        return {
          type: 'application_rejected',
          feedback_link: feedbackLink,
          refund: hasRejectedStep.attributes.name === 'training' ? { refund_duration_days: 10 } : undefined,
        }
      }

      for (const step of steps) {
        if (step.attributes.bypass) continue
        const name = step.attributes.name
        switch (name) {
          case 'testing': {
            const status = step.attributes.status
            switch (status) {
              case 'pending':
                return {
                  type: 'testing_required',
                  test_link: step.attributes.psych_test_link,
                  contacts: {
                    telegram: contact?.telegram,
                    whatsapp: contact?.whatsapp,
                  },
                }
              case 'failed':
                throw new Error('Unexpected testing status failed')
              case 'passed': {
                const testPassedSeen = unwrapResult(testPassedSeenResult)
                if (!testPassedSeen.value?.seen) {
                  return {
                    type: 'testing_passed',
                    telegram_link: applyText(contact?.telegram, formatMessage(messages.want_to_know_test_results)),
                    whatsapp_link: applyText(contact?.whatsapp, formatMessage(messages.want_to_know_test_results)),
                  }
                }
                continue
              }
              default:
                return assertNever(status, () => new Error(`Unexpected testing status ${status}`))
            }
          }
          case 'training': {
            const status = step.attributes.status
            switch (status) {
              case 'not_selected': {
                return {
                  type: 'training_required',
                  programs: [{ id: 'offline', link: '/school_program.pdf', name: formatMessage(messages.offline) }],
                  telegram_link: applyText(contact?.telegram, formatMessage(messages.want_to_choose_school_dates)),
                  whatsapp_link: applyText(contact?.whatsapp, formatMessage(messages.want_to_choose_school_dates)),
                }
              }
              case 'selected': {
                const schoolId = (step as ApiOnboardingStepTraining).relationships.school.data?.id
                if (!schoolId) throw new Error('No school id')
                const school = schools[schoolId]
                if (!school) throw new Error(`School ${schoolId} not found`)
                const online = school.attributes.format.includes('online')
                return {
                  type: 'training_pending',
                  training: online
                    ? {
                        type: 'online',
                        start: school.attributes.start_time.datetime,
                        end: school.attributes.end_time.datetime,
                        start_timezone: school.attributes.start_time.timezone,
                        end_timezone: school.attributes.end_time.timezone,
                      }
                    : {
                        type: 'offline',
                        start: school.attributes.start_time.datetime,
                        end: school.attributes.end_time.datetime,
                        start_timezone: school.attributes.start_time.timezone,
                        end_timezone: school.attributes.end_time.timezone,
                        address: school.attributes.address,
                      },
                  telegram_link: applyText(contact?.telegram, formatMessage(messages.question_regarding_the_school)),
                  whatsapp_link: applyText(contact?.whatsapp, formatMessage(messages.question_regarding_the_school)),
                }
              }
              case 'rejected':
                throw new Error('Unexpected training status rejected')
              case 'finished': {
                const trainingPassedSeen = unwrapResult(trainingPassedSeenResult)
                if (!trainingPassedSeen.value?.seen) return { type: 'training_passed' }
                continue
              }
              default:
                return assertNever(status, () => new Error(`Unexpected training status ${status}`))
            }
          }
          case 'profile': {
            const status = step.attributes.status
            switch (status) {
              case 'pending': {
                const profileIncomplete = Object.entries(step.attributes.completeness)
                  .filter(([key, _value]) => key !== 'schedule')
                  .some(([_key, value]) => !value)
                return { type: 'profile_complete_required', schedule_completed: step.attributes.completeness.schedule, profile_completed: !profileIncomplete }
              }
              case 'profile_completed':
                return { type: 'profile_approval_required' }
              case 'in_review':
                return { type: 'profile_in_review' }
              case 'approved': {
                continue
              }
              case 'disapproved':
                return { type: 'profile_rejected' }
              default:
                return assertNever(status, () => new Error(`Unexpected profile status ${status}`))
            }
          }
          default:
            return assertNever(name, () => new Error(`Unexpected onboarding step ${name}`))
        }
      }

      return { type: 'approved', banner_visible: shouldApprovedBannerBeVisible() }
    })
)

export type CandidateSitterRouting = {
  main: 'registration' | 'profile'
  profile_available: boolean
  schedule_available: boolean
  messages_available: boolean
}

export const sitterCandidateRoutingResultSelector = createSelector([profileUserResultSelector, sitterStatusV2Selector], (userResult, v2statusResult) =>
  intoResult((): CandidateSitterRouting | null => {
    const user = unwrapResult(userResult)
    if (!user) return null

    if (user.account_type !== 'sitter') return null
    if (user.approved) return null

    const v2status = unwrapResult(v2statusResult)
    if (v2status.type === 'v2_disabled') {
      if (!user.training_completed) {
        return {
          main: 'registration' as const,
          schedule_available: false,
          profile_available: false,
          messages_available: false,
        }
      }

      return {
        main: 'profile',
        schedule_available: true,
        profile_available: true,
        messages_available: true,
      }
    }

    if (v2status.type === 'approved') return null

    const settingsAvailable = (() => {
      switch (v2status.type) {
        case 'profile_complete_required':
        case 'profile_approval_required':
        case 'profile_in_review':
        case 'profile_rejected':
          return true
        case 'application_accepted':
        case 'application_rejected':
        case 'application_on_hold':
        case 'testing_required':
        case 'testing_passed':
        case 'training_required':
        case 'training_pending':
        case 'training_passed':
        default:
          return false
      }
    })()

    const mainMode = (() => {
      switch (v2status.type) {
        case 'profile_complete_required':
        case 'profile_approval_required':
        case 'profile_in_review':
        case 'profile_rejected':
          return 'profile' as const
        case 'application_accepted':
        case 'application_rejected':
        case 'application_on_hold':
        case 'testing_required':
        case 'testing_passed':
        case 'training_required':
        case 'training_pending':
        case 'training_passed':
        default:
          return 'registration' as const
      }
    })()

    return {
      main: mainMode,
      schedule_available: settingsAvailable,
      profile_available: settingsAvailable,
      messages_available: settingsAvailable,
    }
  })
)

const applyText = (url: string | undefined, text: string) => {
  if (!url) return undefined
  return `${url}?text=${encodeURIComponent(text)}`
}

const messages = defineMessages({
  offline: 'Офлайн',
  want_to_know_test_results: 'Хочу узнать результаты теста',
  want_to_choose_school_dates: 'Хочу выбрать даты школы',
  question_regarding_the_school: 'У меня есть вопрос по школе',
})
