import { api } from '@app/utils/api'
import { urlEscaped } from '@app/utils/urlEscaped'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { deleteAvatarsDescriptor, postAvatarsDescriptor } from './avatars.descriptors'

export const postAvatars = new ApiActionBuilder(postAvatarsDescriptor)
  .setInit((formData: FormData) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/avatars'),
    headers: api.headers(undefined, { formData: true }),
    body: formData,
  }))
  .build()

export const deleteAvatars = new ApiActionBuilder(deleteAvatarsDescriptor)
  .setInit((avatarId: string) => ({
    method: 'DELETE',
    endpoint: api.path(urlEscaped`/api/v2/avatars/${avatarId}`),
    headers: api.headers(),
    meta: { avatarId },
  }))
  .build()
