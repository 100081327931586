import uniqBy from 'lodash/uniqBy'

import { isAbortError } from '@app/errors/AbortError'
import { AppError } from '@app/errors/AppError'

import { sleep } from '@app/utils/sleep'

import { getRootContext } from '@app/packages/actionContext/actions'
import { asError } from '@app/packages/asError/asError'
import { intoResultAsync, Result, resultError, resultOk } from '@app/packages/Result/Result'

import { createThunk, ThunkAction } from '@app/store/thunk'

import { ActionRequiredError } from './ActionRequiredError'

export const resolveActionRequired = <T>(action: () => T | Promise<T>) => {
  return createThunk(async dispatch => {
    let attempt = 0
    mainloop: while (true) {
      const state = dispatch(getActionRequiredState(await intoResultAsync((async () => await action())())))
      if (state.type === 'success') return state.value
      if (state.type === 'error') {
        if (state.value instanceof CircularResolveError) {
          if (attempt < 10) {
            ++attempt
            await sleep(10)
            continue mainloop
          }
        }
        throw state.value
      }
      await state.promise
      continue mainloop
    }
  })
}

type ActionRequiredState<T> = { type: 'success'; value: T } | { type: 'loading'; promise: Promise<void> } | { type: 'error'; value: Error }

export const getActionRequiredState = <T>(res: Result<T>) => {
  return createThunk((dispatch): ActionRequiredState<T> => {
    const errMap = dispatch(getActionRequiredErrMap())

    const getError = (error: ActionRequiredError) => {
      const state = errMap[error.message]
      if (!state || state.err.key !== error.key) return null
      if (state.resolved?.error && isAbortError(state.resolved.value)) return null

      return state
    }

    if (res.error) {
      const errors = getErrors(res.value)
      const notAction = errors.find(e => !(e instanceof ActionRequiredError))
      if (notAction) return { type: 'error', value: asError(notAction) }
      const actions: ThunkAction<any>[] = []
      const actionErrors = uniqBy(
        errors.filter((e): e is ActionRequiredError => e instanceof ActionRequiredError),
        e => [e.message, e.key].join(':')
      )
      for (const err of actionErrors) {
        const memoized = getError(err)
        if (memoized) {
          if (memoized.resolved) {
            if (memoized.resolved.error) {
              return { type: 'error', value: memoized.resolved.value }
            }
            return { type: 'error', value: new CircularResolveError(`Circular resolve issue: ${err.message}`).withCause(err) }
          }
          actions.push(resolveActionRequiredState(memoized))
        } else {
          const state: ActionRequiredErrorState = { err }
          errMap[err.message] = state
          actions.push(resolveActionRequiredState(state))
        }
      }
      if (actions.length) {
        return {
          type: 'loading',
          promise: Promise.all(actions.map(a => dispatch(a)))
            .then(() => {})
            .catch(() => {}),
        }
      }
    }
    if (res.error) {
      return { type: 'error', value: res.value }
    }

    return { type: 'success', value: res.value }
  })
}

export class CircularResolveError extends AppError {}

const getErrors = (err: any): unknown[] => {
  if (err instanceof AggregateError) {
    return err.errors.flatMap(e => getErrors(e))
  }
  return [err]
}

export type ActionRequiredErrorState = {
  err: ActionRequiredError
  promise?: Promise<void>
  resolved?: Result<void>
}

export const resolveActionRequiredState = (state: ActionRequiredErrorState) => {
  return createThunk(dispatch => {
    if (!state.promise) {
      state.promise = (async () => {
        try {
          await dispatch(state.err.action)
          state.resolved = resultOk(undefined)
        } catch (e) {
          state.resolved = resultError(asError(e))
          throw e
        }
      })()
    }

    return state.promise
  })
}

export const getActionRequiredErrMap = () =>
  createThunk((dispatch): Record<string, ActionRequiredErrorState> => {
    const context = dispatch(getRootContext())
    if (!(context as any)[ERR_MAP]) {
      ;(context as any)[ERR_MAP] = {}
    }
    return (context as any)[ERR_MAP]
  })

const ERR_MAP = Symbol('ACTION_REQUIRED_ERR_MAP')
