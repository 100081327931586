import { ISO8601 } from '@app/constants/Misc'

import { excludeAbortError } from '@app/errors/AbortError'

import moment from '@app/utils/moment'

import {
  postAuthEmailTokenVerificationDescriptor,
  postAuthPhoneVerifyDescriptor,
  postAuthSocialDescriptor,
  postAuthTelegramDescriptor,
} from '@app/store/actions/api/auth.descriptors'
import { getUsersMeDescriptor, putUsersByIdDescriptor } from '@app/store/actions/api/users.descriptors'
import {
  editProfile,
  phoneChangeConfirmDescriptor,
  postEmailCodeVerificationDescriptor,
  postTOSAcceptanceDescriptor,
} from '@app/store/actions/profile.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  loading: boolean
  loadedAt: ISO8601 | null
  error: Error | null
}>({ loading: false, loadedAt: null, error: null }, builder => {
  builder.addCase(putUsersByIdDescriptor.shapes.pending, (state, _action) => {
    return { ...state, error: null, loading: true }
  })

  builder.addCases(
    [
      getUsersMeDescriptor.shapes.fulfilled,
      postAuthSocialDescriptor.shapes.fulfilled,
      postAuthTelegramDescriptor.shapes.fulfilled,
      postTOSAcceptanceDescriptor.shapes.fulfilled,
      postAuthPhoneVerifyDescriptor.shapes.fulfilled,
      phoneChangeConfirmDescriptor.shapes.fulfilled,
      editProfile.shape,
      putUsersByIdDescriptor.shapes.fulfilled,
      postEmailCodeVerificationDescriptor.shapes.fulfilled,
      postAuthEmailTokenVerificationDescriptor.shapes.fulfilled,
    ],
    (state, _action) => {
      return { ...state, loading: false, loadedAt: moment().format(), error: null }
    }
  )

  builder.addCase(putUsersByIdDescriptor.shapes.rejected, (state, action) => {
    return { ...state, loading: false, error: excludeAbortError(action.payload) }
  })
})
