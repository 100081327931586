import React, { memo, ReactNode, Ref, Suspense, useCallback, useEffect, useMemo, useRef } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { IMPORT_MAP } from '@app/importMap'

import { Progress } from '@app/services/Progress/Progress'

import { useAppDispatch } from '@app/utils/redux'
import { createStructuredSelector } from '@app/utils/reselect'
import { NavLink } from '@app/utils/routing/NavLink'

import { useClickHandler } from '@app/hooks/useClickHandler'
import { useEvent } from '@app/hooks/useEvent'
import { useIsClient } from '@app/hooks/useIsClient'

import { withProgress } from '@app/store/actions/initial'
import { handleCallback, showError } from '@app/store/actions/ui'
import { currenciesEnabledSelector, currentCurrencySelector } from '@app/store/selectors/currencies'
import { isAppSelector } from '@app/store/selectors/misc'
import { profileRegionIsChangeableSelector, profileUserSelector } from '@app/store/selectors/profile'
import { sideMenuOpenedSelector, toggleSideMenu } from '@app/store/slices/ui_menu'

import { Button } from '@app/components/Button/Button'
import { ButtonTypeBordered, ButtonTypeInline } from '@app/components/Button/ButtonType'
import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'
import { Hamburger } from '@app/components/Hamburger/Hamburger'
import { Icon, InlineIcon } from '@app/components/Icon/Icon'
import { LoginButton } from '@app/components/LoginButton'
import { Logo } from '@app/components/Logo/Logo'
import { LogoutButton } from '@app/components/LogoutButton'
import { useMount } from '@app/components/Mount/useMount'
import { ScrollableItemsGetScrollButtonCallback } from '@app/components/ScrollableItems/ScrollableItems'
import { Typography } from '@app/components/Typography/Typography'

import { NavItem, NavItemTitleComponent, NavItemVisibility, NavMenuItem, NavSubItem } from './navItemsUtils'
import { PlaceInput } from './PlaceInput'
import { ScrollableMenu } from './ScrollableMenu'
import { UserLabel } from './UserLabel'
import { shouldShowWorkTodayButton } from './utils'

import classes from './Header.module.scss'

export type HeaderProps = {
  items: NavMenuItem[]
  currentMainItem?: NavItem
  currentSecondaryItem?: NavSubItem
  inject?: ReactNode
  showSecondary?: boolean
  mainRef?: Ref<HTMLDivElement>
}

export const Header = memo<HeaderProps>(function Header({ items, currentMainItem, currentSecondaryItem, inject, showSecondary = true, mainRef }) {
  const nodeRef = useRef<HTMLDivElement>(null)
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const mount = useMount()
  const currenciesEnabled = useSelector(currenciesEnabledSelector)
  const isClient = useIsClient()
  const isApp = useSelector(isAppSelector)

  const { sideMenu, user, regionSelectVisible, currency } = useSelector(mapStateToProps)

  const settingsItem = useMemo(() => items.find((i): i is NavItem => i.type === 'item' && i.subtype === 'settings'), [items])
  const subItems = useMemo(() => {
    if (!showSecondary) return null
    if (!currentMainItem?.children) return null
    if (isApp) return currentMainItem.children.filter(i => i.type === 'item' && !!i.app)
    return currentMainItem.children
  }, [currentMainItem?.children, isApp, showSecondary])
  const workTodayVisible = shouldShowWorkTodayButton(user)

  const handleDestroy = useEvent(async () => {
    if (window.confirm(formatMessage(messages.destroy_confirm))) {
      Progress.shared.start()
      const { destroyUser } = await dispatch(withProgress(IMPORT_MAP.actions.session()))
      await dispatch(handleCallback(() => dispatch(destroyUser()), true))
    }
  })

  const changeCurrencyClickHandler = useClickHandler(async () => {
    const { changeCurrency } = await dispatch(withProgress(IMPORT_MAP.actions.currencies()))

    mount.push(
      IMPORT_MAP.modals.CurrencySelectModal().then(m => m.CurrencySelectModal),
      {
        props: {
          async callback(currency) {
            const res = await Progress.shared.wrap(dispatch(changeCurrency(currency, true)), false)
            if (res?.error) dispatch(showError({ error: res.payload }))
          },
        },
      }
    )
  })

  const handleClickHamburger = useClickHandler(() => {
    dispatch(toggleSideMenu())
  })

  const getNavScrollButton = useCallback<ScrollableItemsGetScrollButtonCallback>(
    (type, scrollable, onClick) => (
      <Button
        className={cn(classes.nav_scroll_button, classes[`m_${type}`], {
          [classes.m_scrollable]: scrollable,
        })}
        eventName={`header.nav_scroll_${type === 'start' ? 'start' : 'end'}_button`}
        onClick={onClick}
        type={ButtonTypeInline.getInstance()}
      >
        <InlineIcon icon={type === 'start' ? 'chevron-left' : 'chevron-right'} />
      </Button>
    ),
    []
  )

  const getSecondaryScrollButton = useCallback<ScrollableItemsGetScrollButtonCallback>(
    (type, scrollable, onClick) => (
      <Button
        className={cn(classes.secondary_scroll_button, classes[`m_${type}`], {
          [classes.m_scrollable]: scrollable,
        })}
        eventName={`header.secondary_nav_scroll_${type === 'start' ? 'start' : 'end'}_button`}
        onClick={onClick}
        type={ButtonTypeInline.getInstance()}
      >
        <span className={classes.secondary_scroll_button_wrapper}>
          <InlineIcon icon={type === 'start' ? 'chevron-left' : 'chevron-right'} />
        </span>
      </Button>
    ),
    []
  )

  useEffect(() => {
    const currentItem = document.body.querySelector<HTMLElement>(cn(classes.nav_item, classes.m_active))
    const currentSubItem = document.body.querySelector<HTMLElement>(cn(classes.subitem, classes.m_active))

    const centerInView = (el: HTMLElement) => {
      const parent = el.parentElement!
      const margin = parseFloat(window.getComputedStyle(el).marginLeft) || 0
      const val = el.offsetLeft - (parent.clientWidth - el.clientWidth) / 2 - margin

      parent.scrollTo(val, parent.scrollTop)
    }

    if (currentItem) {
      centerInView(currentItem)
    }
    if (currentSubItem) {
      centerInView(currentSubItem)
    }
  }, [])

  const subItemsHash = subItems?.map(s => s.id).join(':') ?? ''

  const shouldResetScroll = lastHash !== subItemsHash
  lastHash = subItemsHash

  if (isApp && !subItems?.length) return null

  const renderTitle = (Title: ReactNode | NavItemTitleComponent) => {
    return typeof Title === 'function' ? <Title type="header" /> : Title
  }

  return (
    <div className={classes.header} ref={nodeRef}>
      {!isApp && (
        <div className={classes.main} ref={mainRef}>
          <div className={classes.logo_container}>
            <Logo className={classes.logo} />
          </div>

          {currentMainItem && (
            <div className={cn(ControlClasses.hide({ desktop: true }), classes.main_title)}>
              <NavLink eventName={`layout.header.${currentMainItem.id}_link`} target={currentMainItem.external ? '_blank' : undefined} to={currentMainItem.url}>
                {renderTitle(currentMainItem.title)}
              </NavLink>
            </div>
          )}

          <ScrollableMenu
            className={cn(ControlClasses.hide({ mobile: true, tablet: true }), classes.nav)}
            containerClassName={classes.nav_items}
            getScrollButton={getNavScrollButton}
            id={MAIN_MENU_ID}
            items={items}
            renderItem={item => {
              if (item.type === 'item' && !((item.visibility ?? NavItemVisibility.All) & NavItemVisibility.Header)) return null

              const count =
                ('children' in item && item.children && item.children.reduce<number>((c, x) => ('count' in x && x.count ? c + x.count : c), 0)) ||
                ('count' in item && item.count) ||
                0

              return item.type === 'item' && !item.subtype ? (
                <NavLink
                  className={cn(classes.nav_item, { [classes.m_active]: item === currentMainItem })}
                  eventName={`layout.header.${item.id}_link`}
                  key={item.id}
                  region={item.region}
                  target={item.external ? '_blank' : undefined}
                  to={item.url}
                >
                  {item.icon && <Icon className={classes.nav_item_icon} icon={item.icon} />}
                  <span className={classes.nav_item_text}>{renderTitle(item.title)}</span>
                  {!!count && <span className={cn(classes.count, classes.m_small)}>{count}</span>}
                </NavLink>
              ) : item.type === 'spacer' ? (
                <div className={cn(classes.nav_item, classes.nav_spacer)} key={item.id} />
              ) : item.type === 'expander' ? (
                <div className={cn(classes.nav_item, classes.nav_expander)} key={item.id} />
              ) : null
            }}
          />

          <div className={classes.spacer} />

          {inject}

          {currenciesEnabled && (
            <div className={cn(classes.currency_select, ControlClasses.hide({ mobile: true, tablet: true }))} {...changeCurrencyClickHandler}>
              <span className={cn(Typography.Subheader, classes.currency_select_text)}>{currency.attributes.iso_code}</span>
              <InlineIcon icon="chevron-down-small" />
            </div>
          )}

          {regionSelectVisible && (
            <div className={cn(ControlClasses.hide({ mobile: true, tablet: true }), classes.place_select)}>
              <span className={Typography.Subheader}>
                <Icon className={classes.place_select_icon} icon="pin" />
                <span className={classes.place_select_label}>{formatMessage(messages.region)}</span>
              </span>
              <div className={classes.place_select_container}>
                <PlaceInput className={classes.place_select_input} initiator="layout_place_select_place_input" />
              </div>
            </div>
          )}
          {workTodayVisible && (
            <Suspense>
              <WorkTodayCheckbox className={classes.work_today} />
            </Suspense>
          )}

          {user && user.account_type !== 'visitor' ? (
            <UserLabel
              className={cn(ControlClasses.hide({ mobile: true, tablet: true }), classes.user_label, {
                [classes.m_active]: settingsItem === currentMainItem,
              })}
              to={settingsItem?.url}
            />
          ) : (
            <LoginButton
              className={cn(ControlClasses.hide({ mobile: true, tablet: true }), classes.login_btn)}
              id="header"
              type={ButtonTypeBordered.getInstance({ size: 'small' })}
            >
              <FormattedMessage {...messages.sign_in} />
            </LoginButton>
          )}

          <Hamburger
            className={cn(ControlClasses.hide({ desktop: true }), classes.hamburger, { [classes.m_disabled]: !isClient })}
            lineClassName={classes.hamburger_line}
            open={sideMenu}
            {...handleClickHamburger}
          />
        </div>
      )}
      {subItems && (
        <ScrollableMenu
          className={classes.secondary}
          containerClassName={classes.subitems}
          getScrollButton={getSecondaryScrollButton}
          id={SECONDARY_MENU_ID}
          items={subItems}
          renderItem={item =>
            item.type === 'item' ? (
              item.url && (item.wrapInLink ?? true) ? (
                <NavLink
                  className={cn(classes.subitem, { [classes.m_active]: item === currentSecondaryItem })}
                  eventName={`layout.header.${item.id}_link`}
                  key={item.id}
                  onClick={item.onClick ? () => dispatch(item.onClick) : undefined}
                  region={item.region}
                  to={item.url}
                >
                  {renderTitle(item.title)}
                  {!!item.count && <span className={classes.count}>{item.count > 99 ? '99+' : item.count}</span>}
                </NavLink>
              ) : (
                <span
                  className={cn(classes.subitem, { [classes.m_active]: item === currentSecondaryItem })}
                  key={item.id}
                  onClick={item.onClick ? () => dispatch(item.onClick) : undefined}
                >
                  {renderTitle(item.title)}
                  {!!item.count && <span className={classes.count}>{item.count > 99 ? '99+' : item.count}</span>}
                </span>
              )
            ) : item.type === 'expander' ? (
              <div key={item.id} />
            ) : item.type === 'signout' ? (
              <LogoutButton
                className={cn(classes.subitem, classes.subitem_signout)}
                eventName="header.logout_button"
                key={item.id}
                type={ButtonTypeInline.getInstance()}
              >
                <FormattedMessage {...messages.sign_out} />
              </LogoutButton>
            ) : item.type === 'user-desupervise' ? (
              <a className={classes.subitem} href="/?desupervise=true" key={item.id}>
                <FormattedMessage {...messages.desupervise} values={{ name: item.name }} />
              </a>
            ) : item.type === 'user-destroy' ? (
              <Button
                className={cn(classes.subitem, classes.subitem_destroy)}
                eventName="header.destroy_user_button"
                key={item.id}
                onClick={handleDestroy}
                type={ButtonTypeInline.getInstance()}
              >
                <FormattedMessage {...messages.destroy} values={{ name: item.name }} />
              </Button>
            ) : null
          }
          shouldResetScroll={shouldResetScroll}
        />
      )}
    </div>
  )
})

const MAIN_MENU_ID = Symbol()
const SECONDARY_MENU_ID = Symbol()
let lastHash = ''

const WorkTodayCheckbox = React.lazy(() => IMPORT_MAP.components.WorkTodayCheckbox().then(m => ({ default: m.WorkTodayCheckbox })))

const mapStateToProps = createStructuredSelector({
  sideMenu: sideMenuOpenedSelector,
  user: profileUserSelector,
  regionSelectVisible: profileRegionIsChangeableSelector,
  currency: currentCurrencySelector,
})

const messages = defineMessages({
  sign_in: 'Войти',
  sign_out: 'Выход',
  desupervise: 'Выйти из {name}',
  destroy_confirm: 'Вы точно хотите удалить пользователя?',
  destroy: 'Удалить {name}',
  region: 'Ваш регион',
})
