import { ApiParentProfile, ApiPhoneCodeDelivery, ApiPhoneVerification, ApiSitterProfile, ApiUserProfile } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse, PhoneVerificationMethod } from '@app/constants/ApiTypes/misc'
import { PhoneVerificationRequest } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const deleteAuthDescriptor = new ApiActionBuilderDescriptor().setLabel('deleteAuth').setShape()

export const postAuthSocialDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postAuthSocial')
  .setShape<ApiDataResponse<(ApiParentProfile | ApiSitterProfile) & { meta: { access_token: string } }>>()

export const getAuthPhoneDeliveryMethodsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getAuthPhoneDeliveryMethods')
  .setShape<ApiDataResponse<ApiPhoneCodeDelivery[]>>()

export const postAuthPhoneRequestCodeDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postAuthPhoneRequestCode')
  .setShape<ApiDataResponse<ApiPhoneVerification>, { phone: string; purpose: 'auth' | 'phone_change'; delivery: PhoneVerificationMethod }>()

export const postAuthPhoneVerifyDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postAuthPhoneVerify')
  .setShape<ApiDataResponse<ApiUserProfile & { meta: { access_token: string } }>, { data: PhoneVerificationRequest }>()

export const postAuthEmailConfirmDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postAuthEmailConfirm')
  .setShape<void, { email: string; cause?: string }>()

export const postAuthEmailTokenVerificationDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postAuthEmailTokenVerification')
  .setShape<
    ApiDataResponse<ApiUserProfile & { meta: { access_token: string } }> & { meta: { email_token_payload: { email: string; cause: string } } },
    { token: string }
  >()

export const postAuthTelegramDescriptor = new ApiActionBuilderDescriptor().setLabel('postAuthTelegram').setShape<
  ApiDataResponse<
    (ApiParentProfile | ApiSitterProfile) & {
      meta: { access_token: string }
    }
  >
>()
