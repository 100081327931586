import { createElement } from 'react'
import { unreadCountSelector } from '@kidsout-kidsout/chat/lib/selectors'
import { defineMessages } from 'react-intl'
import { createSelector } from 'reselect'

import { TEST_USERS } from '@app/constants/TestUsers'

import { Link } from '@app/utils/routing/Link'
import { trimRegionalRoute } from '@app/utils/routing/region'
import { pathIsSubPathOf } from '@app/utils/url'

import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { confirm } from '@app/store/actions/ui'
import { affiliatesEnabledSelector, configSelector } from '@app/store/selectors/misc'
import { profileUserResultSelector } from '@app/store/selectors/profile'
import { availableRegionsSlugsSelector } from '@app/store/selectors/regions'
import { sessionSelector } from '@app/store/selectors/session'
import { sitterCandidateRoutingResultSelector } from '@app/store/selectors/sitter_onboarding'
import { createUserURLResultSelector } from '@app/store/selectors/user'
import { intlSelector } from '@app/store/slices/intl'
import { newsUnreadCountResultSelector } from '@app/store/slices/news'
import { StoreState } from '@app/store/store'

import { createAcademyNavigationSelector } from '@app/routes/Academy/navigationSelector'

import { intlFormatters, NavItemVisibility, NavMenuChild, NavMenuItem } from './navItemsUtils'

const idSelector = createSelector([profileUserResultSelector], userResult =>
  intoResult(() => {
    const user = unwrapResult(userResult)
    if (!user) throw new Error('Profile user is missing')
    return user.id
  })
)

const sitterUrlResultSelector = createUserURLResultSelector(idSelector)
const academySelector = createAcademyNavigationSelector(() => 'academy')
const newYearPartySelector = createAcademyNavigationSelector(() => 'new_year_party')

export const createNavItemsSelector = (pathnameSelector: (state: StoreState) => string) =>
  createSelector(
    [
      intlSelector,
      pathnameSelector,
      availableRegionsSlugsSelector,
      academySelector,
      newYearPartySelector,
      profileUserResultSelector,
      configSelector,
      sessionSelector,
      unreadCountSelector,
      newsUnreadCountResultSelector,
      affiliatesEnabledSelector,
      sitterUrlResultSelector,
      sitterCandidateRoutingResultSelector,
    ],
    (
      intl,
      pathname,
      availableRegionSlugs,
      academyMenu,
      newYearPartyMenu,
      userResult,
      config,
      session,
      messagesUnreadCount,
      newsUnreadCountResult,
      affiliatesEnabled,
      sitterUrlResult,
      sitterCandidateRoutingResult
    ) =>
      intoResult(() => {
        const { formatMessage } = intl

        pathname = trimRegionalRoute(availableRegionSlugs, pathname)
        const user = unwrapResult(userResult)

        const isRemovable =
          config.accountManagement &&
          !session.supervisor &&
          session.access_token &&
          !TEST_USERS.find(u => u.id === user?.id || u.token === session.access_token)
        const isSupervised = !!session.supervisor
        const isVisitor = !user || user.account_type === 'visitor'

        const getUserControlItems = () => {
          if (!user || user.account_type === 'visitor') return []
          const adminItems: NavMenuChild[] = []
          if (isRemovable) adminItems.push({ type: 'user-destroy', id: 'user-destroy', name: user.name || user.phone || '{name}' })
          if (isSupervised) adminItems.push({ type: 'user-desupervise', id: 'user-desupervise', name: user.name || user.phone || '{name}' })
          adminItems.push({ type: 'signout', id: 'signout' })
          return adminItems
        }

        const settingsItems = (() => {
          const settingsItem = ((): NavMenuItem | null => {
            if (!user || user.account_type === 'visitor') return null

            if (user.account_type === 'parent')
              return {
                type: 'item',
                id: 'settings',
                icon: 'percent',
                url: '/settings',
                subtype: 'settings',
                title: formatMessage(messages.main_settings, intlFormatters),
                children: user.registration_completed
                  ? [
                      {
                        type: 'item',
                        id: 'settings',
                        url: '/settings',
                        title: formatMessage(messages.settings),
                      },
                      {
                        type: 'item',
                        id: 'reviews',
                        url: '/reviews',
                        title: formatMessage(messages.reviews),
                      },
                      {
                        type: 'item',
                        id: 'billing',
                        url: '/billing',
                        title: formatMessage(messages.billing),
                      },
                      {
                        type: 'item',
                        id: 'search_promocode',
                        url: '/promocode',
                        title: formatMessage(messages.promocode),
                      },
                      {
                        type: 'item',
                        id: 'search_free_subscription',
                        url: '/referrals',
                        title: formatMessage(messages.free_subscription),
                      },
                      {
                        type: 'item',
                        id: 'notification_settings',
                        url: '/notification-settings',
                        title: formatMessage(messages.notifications),
                      },
                      ...getUserControlItems(),
                    ]
                  : [
                      {
                        type: 'item',
                        id: 'settings',
                        url: '/settings',
                        title: formatMessage(messages.settings),
                      },
                      ...getUserControlItems(),
                    ],
              }

            if (user.account_type === 'sitter') {
              if (!user.approved) {
                const children: NavMenuChild[] = []
                const status = unwrapResult(sitterCandidateRoutingResult)
                if (!status) throw new Error('Candidate sitter routing is missing')
                if (status.messages_available) {
                  children.push({
                    type: 'item',
                    id: 'messages',
                    app: true,
                    url: '/m',
                    matches: path => pathIsSubPathOf('/m', path),
                    title: formatMessage(messages.messages),
                    count: messagesUnreadCount,
                  })
                }
                if (status.main === 'profile') {
                  children.push({
                    type: 'item',
                    id: 'settings',
                    app: true,
                    url: '/settings',
                    title: formatMessage(messages.settings),
                  })
                } else {
                  children.push({
                    type: 'item',
                    id: 'registration',
                    app: true,
                    url: '/registration',
                    title: formatMessage(messages.registration),
                  })
                }
                children.push(
                  {
                    type: 'item',
                    id: 'knowledge',
                    app: true,
                    url: '/knowledge',
                    title: formatMessage(messages.main_knowledge),
                  },
                  ...getUserControlItems()
                )

                return {
                  type: 'item',
                  id: 'settings',
                  icon: 'percent',
                  url: '/',
                  subtype: 'settings',
                  title: formatMessage(messages.main_settings, intlFormatters),
                  children,
                }
              }

              return {
                type: 'item',
                id: 'settings',
                icon: 'percent',
                url: '/settings',
                subtype: 'settings',
                title: formatMessage(messages.main_settings, intlFormatters),
                children: [
                  {
                    type: 'item',
                    id: 'settings',
                    url: '/settings',
                    title: formatMessage(messages.settings),
                  },
                  {
                    type: 'item',
                    id: 'schedule',
                    onClick: dispatch => {
                      dispatch(
                        confirm({
                          name: 'schedule-moved-modal',
                          title: formatMessage(messages.schedule_moved),
                          content: formatMessage(messages.now_schedule_is_in_settings, {
                            link: msg =>
                              pathname === '/settings'
                                ? msg
                                : createElement(Link, {
                                    eventName: 'schedule_moved_modal.link',
                                    to: '/settings',
                                    children: msg,
                                  }),
                          }),
                        })
                      )
                    },
                    title: formatMessage(messages.schedule),
                  },
                  {
                    type: 'item',
                    id: 'profile',
                    url: unwrapResult(sitterUrlResult),
                    title: formatMessage(messages.sitter_profile),
                  },
                  {
                    type: 'item',
                    id: 'search_free_subscription',
                    url: '/referrals',
                    title: formatMessage(messages.bonuses),
                  },
                  {
                    type: 'item',
                    id: 'notification_settings',
                    url: '/notification-settings',
                    title: formatMessage(messages.notifications),
                  },
                  ...getUserControlItems(),
                ],
              }
            }

            return null
          })()

          const settingsItems = settingsItem ? [settingsItem] : []
          return settingsItems
        })()

        if (user?.account_type === 'sitter' && !user.approved) return settingsItems

        const sitterItems = ((): NavMenuItem[] =>
          isVisitor
            ? [
                { type: 'expander', id: 'prebabysitter-spacer' },
                {
                  type: 'item',
                  id: 'babysitter',
                  icon: 'profile',
                  url: '/babysitter',
                  region: true,
                  title: formatMessage(messages.main_babysitter_link, intlFormatters),
                  visibility: NavItemVisibility.Side | NavItemVisibility.Footer,
                  children: [
                    {
                      type: 'item',
                      id: 'babysitter_main',
                      url: '/babysitter',
                      region: true,
                      title: formatMessage(messages.become_babysitter),
                    },
                    {
                      type: 'item',
                      id: 'babysitter_find_orders',
                      url: '/announcements',
                      region: false,
                      title: formatMessage(messages.find_orders),
                    },
                    {
                      type: 'item',
                      id: 'babysitter_school',
                      url: '/babysitter/school',
                      region: true,
                      title: formatMessage(messages.babysitter_school),
                    },
                  ],
                },
              ]
            : [])()

        const helpItem = ((): NavMenuItem => ({
          type: 'item',
          id: 'help',
          icon: 'phone',
          url: '/contacts',
          title: formatMessage(messages.main_help, intlFormatters),
          children: [
            {
              type: 'item',
              id: 'help_contacts',
              url: '/contacts',
              title: formatMessage(messages.contacts),
            },
            {
              type: 'item',
              id: 'help_faq',
              url: '/faq',
              title: formatMessage(messages.faq),
            },
            {
              type: 'item',
              id: 'help_how_it_works',
              url: '/how-it-works',
              title: formatMessage(messages.how_it_works),
            },
            {
              type: 'item',
              id: 'help_insurance',
              url: '/insurance',
              title: formatMessage(messages.insurance),
            },
          ],
        }))()

        return ((): NavMenuItem[] => {
          if (pathIsSubPathOf('/babysitter', pathname) || (pathname === '/announcements' && (!user || user.account_type === 'visitor'))) {
            const items: NavMenuItem[] = [
              {
                type: 'item',
                id: 'babysitter_main_item',
                icon: 'profile',
                url: '/babysitter',
                region: true,
                title: formatMessage(messages.main_become_babysitter, intlFormatters),
              },
              {
                type: 'item',
                id: 'babysitter_find_orders_main_item',
                icon: 'magnifier',
                url: '/announcements',
                region: false,
                title: formatMessage(messages.main_find_orders, intlFormatters),
              },
              {
                type: 'item',
                id: 'babysitter_school_main_item',
                icon: 'school-outlined',
                url: '/babysitter/school',
                region: true,
                title: formatMessage(messages.main_babysitter_school, intlFormatters),
              },
              {
                type: 'item',
                id: 'blog',
                icon: 'pen',
                external: true,
                url: 'https://blog.kidsout.ru',
                title: formatMessage(messages.main_blog, intlFormatters),
              },
              {
                type: 'item',
                id: 'help',
                icon: 'phone',
                url: '/contacts',
                title: formatMessage(messages.main_help, intlFormatters),
              },
              ...settingsItems,
            ]

            return items
          }

          if (!user || user.account_type === 'visitor')
            return [
              {
                type: 'item',
                id: 'search',
                icon: 'magnifier',
                url: '/search',
                title: formatMessage(messages.main_search, intlFormatters),
              },
              academyMenu,
              {
                type: 'item',
                id: 'giftcard',
                icon: 'gift',
                url: '/giftcard',
                region: true,
                matches: (path: string) => path.startsWith('/giftcard'),
                title: formatMessage(messages.main_giftcard, intlFormatters),
              },
              {
                type: 'item',
                id: 'playground',
                icon: 'playground',
                url: '/playground',
                region: true,
                visibility: NavItemVisibility.Footer | NavItemVisibility.Side,
                title: formatMessage(messages.main_playground, intlFormatters),
              },
              newYearPartyMenu,
              {
                type: 'item',
                id: 'affiliate',
                icon: 'percent',
                url: '/affiliate',
                visibility: affiliatesEnabled ? NavItemVisibility.All : NavItemVisibility.None,
                title: formatMessage(messages.main_affiliate, intlFormatters),
              },
              {
                type: 'item',
                id: 'blog',
                icon: 'pen',
                external: true,
                url: 'https://blog.kidsout.ru',
                title: formatMessage(messages.main_blog, intlFormatters),
              },
              helpItem,
              ...sitterItems,
            ]

          if (user.account_type === 'parent')
            return [
              {
                type: 'item',
                id: 'search',
                icon: 'magnifier',
                url: '/',
                matches: pathname => pathname === '/' || pathname.startsWith('/m/'),
                title: formatMessage(messages.main_search, intlFormatters),
                children: [
                  {
                    type: 'item',
                    id: 'search_messages',
                    url: '/',
                    matches: pathname => pathname === '/' || pathname.startsWith('/m/'),
                    title: formatMessage(messages.messages),
                    count: messagesUnreadCount,
                  },
                  {
                    type: 'item',
                    id: 'search_new_announcement',
                    url: '/announcements/new',
                    title: formatMessage(messages.new_announcement),
                  },
                  {
                    type: 'item',
                    id: 'search_main',
                    url: '/search',
                    title: formatMessage(messages.sitter_search),
                  },
                  {
                    type: 'item',
                    id: 'search_parent_announcements',
                    url: '/announcements',
                    matches: path => pathIsSubPathOf('/announcements', path),
                    title: formatMessage(messages.parent_announcements),
                  },
                  {
                    type: 'item',
                    id: 'history',
                    url: '/history',
                    title: formatMessage(messages.history),
                  },
                  {
                    type: 'item',
                    id: 'favorites',
                    url: '/favorites',
                    title: formatMessage(messages.favorites),
                  },
                  {
                    type: 'item',
                    id: 'trusted',
                    url: '/trusted',
                    title: formatMessage(messages.trusted),
                  },
                ],
              },
              academyMenu,
              {
                type: 'item',
                id: 'news',
                icon: 'scroll',
                url: '/news',
                count: unwrapResult(newsUnreadCountResult),
                title: formatMessage(messages.main_news, intlFormatters),
              },
              {
                type: 'item',
                id: 'giftcard',
                icon: 'gift',
                url: '/giftcard',
                matches: path => pathIsSubPathOf('/giftcard', path),
                title: formatMessage(messages.main_giftcard, intlFormatters),
              },
              {
                type: 'item',
                id: 'playground',
                icon: 'playground',
                url: '/playground',
                visibility: NavItemVisibility.Footer | NavItemVisibility.Side,
                title: formatMessage(messages.main_playground, intlFormatters),
              },
              newYearPartyMenu,
              {
                type: 'item',
                id: 'affiliate',
                icon: 'percent',
                url: '/affiliate',
                visibility: affiliatesEnabled ? NavItemVisibility.All : NavItemVisibility.None,
                title: formatMessage(messages.main_affiliate, intlFormatters),
              },
              {
                type: 'item',
                id: 'blog',
                icon: 'pen',
                external: true,
                url: 'https://blog.kidsout.ru',
                title: formatMessage(messages.main_blog, intlFormatters),
              },
              helpItem,
              ...sitterItems,
              ...settingsItems,
            ]

          return [
            {
              type: 'item',
              id: 'search',
              icon: 'magnifier',
              url: '/',
              matches: path => path === '/' || pathIsSubPathOf('/announcements', path) || pathIsSubPathOf('/m', path),
              title: formatMessage(messages.main_search_sitter, intlFormatters),
              children: [
                {
                  type: 'item',
                  id: 'search_sitter_announcements',
                  url: '/',
                  matches: path => path === '/' || pathIsSubPathOf('/announcements', path),
                  title: formatMessage(messages.sitter_announcements),
                },
                {
                  type: 'item',
                  id: 'search_messages',
                  url: '/m',
                  matches: path => pathIsSubPathOf('/m', path),
                  title: formatMessage(messages.messages),
                  count: messagesUnreadCount,
                },
                {
                  type: 'item',
                  id: 'history',
                  url: '/history',
                  title: formatMessage(messages.history),
                },
                {
                  type: 'item',
                  id: 'favorites',
                  url: '/favorites',
                  title: formatMessage(messages.favorites),
                },
                {
                  type: 'item',
                  id: 'ignored',
                  url: '/ignored',
                  title: formatMessage(messages.excluded),
                },
                {
                  type: 'item',
                  id: 'trusting',
                  url: '/trusting',
                  title: formatMessage(messages.trusted),
                },
              ],
            },
            {
              type: 'item',
              id: 'knowledge',
              icon: 'book',
              url: '/knowledge',
              title: formatMessage(messages.main_knowledge, intlFormatters),
            },
            {
              type: 'item',
              id: 'news',
              icon: 'scroll',
              url: '/news',
              count: unwrapResult(newsUnreadCountResult),
              title: formatMessage(messages.main_news, intlFormatters),
            },
            {
              type: 'item',
              id: 'affiliate_sitter',
              icon: 'percent',
              url: '/affiliate',
              visibility: affiliatesEnabled ? NavItemVisibility.Footer | NavItemVisibility.Side : NavItemVisibility.None,
              title: formatMessage(messages.main_affiliate_sitter, intlFormatters),
            },
            {
              type: 'item',
              id: 'blog',
              icon: 'pen',
              external: true,
              url: 'https://blog.kidsout.ru',
              title: formatMessage(messages.main_blog, intlFormatters),
            },
            helpItem,
            ...sitterItems,
            ...settingsItems,
          ]
        })()
      })
  )

const messages = defineMessages({
  main_settings: 'Личный{whitespace}кабинет',
  main_search: 'Найти{whitespace}бебиситтера',
  main_search_sitter: 'Работа{whitespace}и общение',
  main_quiz: 'Kidsout{whitespace}Quiz',
  main_giftcard: 'Подарочная{whitespace}карта',
  main_blog: 'Блог',
  main_playground: 'Детские{whitespace}площадки',
  main_affiliate: 'Партнерские{whitespace}программы',
  main_affiliate_sitter: 'Скидки{whitespace}для\u00a0ситтеров',
  main_babysitter_link: 'Беби{dashspace}ситтерам',
  main_become_babysitter: 'Стать{whitespace}бебиситтером',
  main_find_orders: 'Найти{whitespace}заказы',
  main_babysitter_school: 'Школа{whitespace}бебиситтеров',
  main_news: 'Новости{whitespace}сервиса',
  main_knowledge: 'Знания',
  main_new_year_2021: 'Новогодний{whitespace}Kidsout',
  main_help: 'Справка',

  parent_announcements: 'Мои объявления',
  sitter_announcements: 'Объявления родителей',
  new_announcement: 'Найти ситтера',
  messages: 'Сообщения',
  sitter_search: 'Наши ситтеры',
  promocode: 'Промокод',
  free_subscription: 'Бесплатные подписки',
  bonuses: 'Бонусы',

  become_babysitter: 'Стать бебиситтером',
  find_orders: 'Найти заказы',
  babysitter_school: 'Школа бебиситтеров',

  contacts: 'Контакты',
  faq: 'Вопросы и\u00a0ответы',
  how_it_works: 'Как это работает',
  insurance: 'Страхование',

  sitter_rules: 'Правила взаимодействия',
  sitter_profile_rules: 'Правила заполнения анкеты',

  registration: 'Регистрация',
  settings: 'Настройки',
  notifications: 'Настройки уведомлений',
  favorites: 'Избранные',
  excluded: 'Исключенные',
  trusted: 'Постоянные',
  history: 'История заказов',
  reviews: 'Отзывы',
  billing: 'Оплата',
  sitter_profile: 'Анкета и\u00a0отзывы',
  schedule: 'Расписание',
  schedule_moved: 'Расписание переехало',
  now_schedule_is_in_settings: 'Теперь оно находится в <link>настройках профиля</link>',
})
