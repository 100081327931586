import React, { FunctionComponent } from 'react'
import memoize from 'lodash/memoize'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { createFetchDataError } from '@app/errors/FetchDataError'

import { invariant } from '@app/utils/invariant'
import { WithFetchData } from '@app/utils/performFetchData'

import { useSetMeta } from '@app/hooks/useSetMeta'

import { setStaticContent, staticSelector } from '@app/store/slices/static'

import { NavItem } from '@app/routes/Static/staticMenu'

import { HTMLText } from '@app/components/HTMLText'

import StaticLayout from './StaticLayout/StaticLayout'

export const createStaticPageRenderer = memoize((item: NavItem) => {
  const contentSelector = createSelector(staticSelector, state => state?.[item.event_id] ?? invariant(new Error(`Static content ${item.event_id} not found`)))

  const Cmp: FunctionComponent & WithFetchData = () => {
    const content = useSelector(contentSelector)

    useSetMeta(item.meta)

    if (item.side) return <HTMLText html={content} />

    return (
      <StaticLayout headerInject={item.headerInject}>
        <HTMLText html={content} />
      </StaticLayout>
    )
  }

  Cmp.displayName = item.event_id

  Cmp.fetchData = async fetchDataObject => {
    const { routerState, store } = fetchDataObject
    const dataPromise = item.data().catch(e => {
      throw store.dispatch(createFetchDataError('Page fetch failed', 400)).withCause(e)
    })
    const injectPromise = item.inject && !routerState.browserInitial ? item.inject(fetchDataObject) : Promise.resolve(null)

    let data = await dataPromise
    const inject = await injectPromise

    if (inject) {
      for (const [key, value] of Object.entries(inject)) {
        data = data.replace(new RegExp(`\\{${key}\\}`, 'g'), value)
      }
    }
    store.dispatch(setStaticContent(item.event_id, data))
  }

  return Cmp
})
