import { api } from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getNewsDescriptor, postNewsReadDescriptor } from './news.descriptors'

export const getNews = new ApiActionBuilder(getNewsDescriptor)
  .setInit((query: { page: number; per_page: number }) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/news', query),
    headers: api.headers(),
  }))
  .build()

export const postNewsRead = new ApiActionBuilder(postNewsReadDescriptor)
  .setInit(() => ({
    method: 'POST',
    endpoint: api.path('/api/v2/news/read'),
    headers: api.headers(),
  }))
  .build()
