import uniq from 'lodash/uniq'

import {
  addToTrustedDescriptor,
  fetchTrustedSittersDescriptor,
  fetchTrustingParentsDescriptor,
  removeFromTrustedDescriptor,
} from '@app/store/actions/trusted.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[] | null>(null, builder => {
  builder.addCases([fetchTrustedSittersDescriptor.shapes.fulfilled, fetchTrustingParentsDescriptor.shapes.fulfilled], (_state, action) => {
    const ids = action.payload.data.map((u: { id: string }) => u.id)
    return ids
  })

  builder.addCases([removeFromTrustedDescriptor.shapes.rejected, addToTrustedDescriptor.shapes.pending], (state, action) => {
    if (!state) return state
    return uniq([action.meta.sitter_id].concat(state))
  })

  builder.addCases([removeFromTrustedDescriptor.shapes.pending, addToTrustedDescriptor.shapes.rejected], (state, action) => {
    if (!state) return state
    const index = state.indexOf(action.meta.sitter_id)
    if (index === -1) return state

    return [...state.slice(0, index), ...state.slice(index + 1)]
  })
})
