// Contains utils to download the locale data for the current language, eventually
// requiring the `Intl` polyfill for browser not supporting it
// It is used in ../intl.js *before* rendering the root component.

import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import memoize from 'lodash/memoize'
import { createIntl, IntlConfig } from 'react-intl'

import { IMPORT_MAP } from '@app/importMap'

import { Locale, LOCALES_LIST } from '@app/constants/Locales'

import { isTruthy } from './isTruthy'

const loaders: {
  LocaleData: Record<Locale, () => Promise<unknown>>
  PluralRules: Record<Locale, () => Promise<unknown>>
  RelativeTimeFormat: Record<Locale, () => Promise<unknown>>
} = {
  LocaleData: IMPORT_MAP.intl.LocaleData,
  PluralRules: IMPORT_MAP.intl.PluralRules,
  RelativeTimeFormat: IMPORT_MAP.intl.RelativeTimeFormat,
}

export async function loadNodeIntlPolyfill() {
  if (!global.Intl) {
    global.Intl = require('intl')

    if ((global.Intl as any).__disableRegExpRestore) {
      ;(global.Intl as any).__disableRegExpRestore()
    }

    if (!Intl.NumberFormat) {
      const IntlPolyfill = require('intl')
      Intl.NumberFormat = IntlPolyfill.NumberFormat
    }
    if (!Intl.DateTimeFormat) {
      const IntlPolyfill = require('intl')
      Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat
    }

    await Promise.all(
      LOCALES_LIST.reduce<Promise<unknown>[]>((c, locale) => {
        if (global.Intl.NumberFormat.supportedLocalesOf(locale)[0] !== locale || global.Intl.DateTimeFormat.supportedLocalesOf(locale)[0] === locale) {
          c.push(loaders.LocaleData[locale]())
        }
        return c
      }, [])
    )
  }

  if (shouldPolyfillLocale()) {
    await IMPORT_MAP.intl.localePolyfill()
  }

  await Promise.all(
    [
      !Intl.PluralRules ? IMPORT_MAP.intl.pluralRulesPolyfill() : null,
      !(Intl as any).RelativeTimeFormat ? IMPORT_MAP.intl.relativeTimeFormatPolyfill() : null,
    ].filter(isTruthy)
  )

  await Promise.all(
    LOCALES_LIST.reduce<Promise<unknown>[]>((c, locale) => {
      if (global.Intl.PluralRules.supportedLocalesOf(locale)[0] !== locale) {
        c.push(loaders.PluralRules[locale]())
      }
      if ((global.Intl as any).RelativeTimeFormat.supportedLocalesOf(locale)[0] !== locale) {
        c.push(loaders.RelativeTimeFormat[locale]())
      }
      return c
    }, [])
  )
}

export async function loadBrowserIntlPolyfill(locale: 'ru' | 'en' = 'ru') {
  if (!window.Intl) {
    await IMPORT_MAP.intl.intl()
    if ((window.Intl as any).__disableRegExpRestore) {
      ;(window.Intl as any).__disableRegExpRestore()
    }

    const promises: Promise<unknown>[] = []
    if (window.Intl.NumberFormat.supportedLocalesOf(locale)[0] !== locale || window.Intl.DateTimeFormat.supportedLocalesOf(locale)[0] === locale) {
      promises.push(loaders.LocaleData[locale]())
    }
    await Promise.all(promises)
  }

  if (shouldPolyfillLocale()) {
    await IMPORT_MAP.intl.localePolyfill()
  }

  await Promise.all(
    [
      !window.Intl.PluralRules ? IMPORT_MAP.intl.pluralRulesPolyfill() : null,
      !(window.Intl as any).RelativeTimeFormat ? IMPORT_MAP.intl.relativeTimeFormatPolyfill() : null,
    ].filter(isTruthy)
  )

  await Promise.all(
    (() => {
      const promises: Promise<unknown>[] = []

      if (window.Intl.PluralRules.supportedLocalesOf(locale)[0] !== locale) {
        promises.push(loaders.PluralRules[locale]())
      }
      if ((window.Intl as any).RelativeTimeFormat.supportedLocalesOf(locale)[0] !== locale) {
        promises.push(loaders.RelativeTimeFormat[locale]())
      }

      return promises
    })()
  )
}

export const INTL_FORMATS = {
  number: {
    distance: {
      maximumFractionDigits: 1,
      useGrouping: false,
    },
  },
}

export const getIntlInstance = memoize((locale: string, messages?: IntlConfig['messages']) =>
  createIntl({
    defaultFormats: INTL_FORMATS,
    defaultLocale: 'ru',
    formats: INTL_FORMATS,
    locale,
    messages,
  })
)
