import { AnalyticsEvent } from '@app/services/AnalyticsEvent'

import { api } from '@app/utils/api'

import { createTypelessApiAction } from '@app/store/apiMiddleware/utils'
import { createThunk } from '@app/store/thunk'

import { setPushNotificationsState, setPushNotificationsSubscribed } from './pushNotifications.descriptors'

export function restorePushNotifications() {
  return createThunk((dispatch, getState) => {
    if (!IS_BROWSER) return
    const state = getState()
    const userId = state.profile.user?.id
    if (state.profile.pushNotifications.restored || !userId) {
      dispatch(setPushNotificationsState({ blocked: isBlocked() }))
      return
    }
    const subscribed = !!storage.get(userId)
    dispatch(
      setPushNotificationsState({
        restored: true,
        subscribed,
        pending: false,
        blocked: isBlocked(),
      })
    )

    // set permissions so we can track change of it
    navigator.permissions?.query({ name: 'notifications' }).then(permissions => {
      const handler = () => {
        AnalyticsEvent.create({ id: 'change_notifications_permissions_state', state: permissions.state }).sendGAYS()

        if (permissions.state !== 'granted') {
          storage.unset(userId)
        }

        dispatch(
          setPushNotificationsState({
            ...(permissions.state !== 'granted' ? { subscribed: false } : {}),
            blocked: permissions.state === 'denied',
          })
        )
      }

      permissions.addEventListener('change', handler)
    })
  })
}

export function updatePushNotificationsSubscribed(token: string | null) {
  return createThunk((dispatch, getState) => {
    const state = getState()
    const userId = state.profile.user?.id
    if (!userId) return

    if (token) {
      storage.set(userId, token)
    } else {
      storage.unset(userId)
    }
    dispatch(setPushNotificationsSubscribed(!!token))
  })
}

export function sendTokenToServer(token: string) {
  return createTypelessApiAction<unknown>({
    method: 'POST',
    endpoint: api.path('/api/v2/firebase/fcm/subscribe'),
    headers: api.headers(),
    body: JSON.stringify({ instance_id: token }),
  })
}

const LC_KEY = 'kidsout__fcm_token:'
const storage = {
  get(id: string): string | null {
    if (typeof window === 'undefined' || !window.localStorage) return null
    const val = window.localStorage.getItem(LC_KEY + id)
    if (typeof val !== 'string') return null
    return val
  },
  unset(id: string) {
    if (typeof window === 'undefined' || !window.localStorage) return
    window.localStorage.removeItem(LC_KEY + id)
  },
  set(id: string, value: string) {
    if (typeof window === 'undefined' || !window.localStorage) return
    window.localStorage.setItem(LC_KEY + id, value)
  },
}

const isBlocked = () => typeof window === 'undefined' || !('Notification' in window) || window.Notification.permission === 'denied'
