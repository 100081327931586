import React, { lazy, Suspense, useEffect } from 'react'
import { defineMessages } from 'react-intl'
import { createSelector } from 'reselect'

import { IMPORT_MAP } from '@app/importMap'

import { useAppDispatch } from '@app/utils/redux'
import { pathIsSubPathOf } from '@app/utils/url'

import { intlSelector } from '@app/store/slices/intl'

import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'
import { Icon18 } from '@app/components/Icon/Icon'
import { intlFormatters, NavItemTitleComponent, NavItemVisibility, NavMenuItem } from '@app/components/LayoutMilk/navItemsUtils'

import { AcademyEventName, URLS } from './config'

export const createAcademyNavigationSelector = (eventSelector: () => AcademyEventName) =>
  createSelector([eventSelector, intlSelector], (event, intl): NavMenuItem => {
    const { formatMessage } = intl

    return {
      type: 'item',
      id: event,
      icon: headerIcons[event] || headerIcons.academy,
      url: URLS.main(event),
      matches: path => path !== URLS.cart(event) && pathIsSubPathOf(URLS.main(event), path),
      title: formatMessage(messages[`label__${event}`] || messages.label__academy, intlFormatters),
      visibility: event === 'academy' ? NavItemVisibility.All : NavItemVisibility.None,
      children: [
        {
          type: 'item',
          id: `${event}_main`,
          url: URLS.main(event),
          title: formatMessage(messages.label_main),
        },
        {
          type: 'item',
          id: `${event}_memo`,
          url: '/memo',
          title: formatMessage(messages.label_memo),
        },
        {
          type: 'item',
          id: `${event}_purchased`,
          url: URLS.purchased(event),
          title: formatMessage(messages.label_purchased),
        },
        {
          type: 'item',
          id: `${event}_cart`,
          url: URLS.cart(event),
          wrapInLink: false,
          title: HeaderCardNavItem,
        },
      ],
    }
  })

const HeaderCartButton = lazy(() => IMPORT_MAP.routes.Academy.HeaderCartButton().then(m => ({ default: m.HeaderCartButton })))
const HeaderCardNavItem: NavItemTitleComponent = ({ type }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    IMPORT_MAP.actions.academy().then(({ restoreCart }) => {
      dispatch(restoreCart())
    })
  }, [dispatch])

  return (
    <div className={type === 'header' ? ControlClasses.hide({ mobile: true, tablet: true }) : undefined} data-cart-header-pointer>
      <Suspense>
        <HeaderCartButton type={type} />
      </Suspense>
    </div>
  )
}

const messages = defineMessages({
  label__academy: 'Онлайн-{zspace}занятия',
  label__new_year_party: 'Утренники',
  label_main: 'Расписание',
  label_memo: 'Памятка онлайн-занятий',
  label_purchased: 'Мои занятия',
})

const headerIcons: { [K in AcademyEventName]?: Icon18 } = {
  academy: 'smile',
  new_year_party: 'tree2',
}
