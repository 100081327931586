import React, { FunctionComponent, memo, useCallback, useState } from 'react'

import { IMPORT_MAP } from '@app/importMap'

import { useAppDispatch } from '@app/utils/redux'

import { useStackContext } from '@app/packages/StackContext/StackContext'

import { withProgress } from '@app/store/actions/initial'

import { Button, ButtonProps } from './Button/Button'

type Props = {
  className?: string
} & React.JSX.LibraryManagedAttributes<typeof Button, ButtonProps>

export const LogoutButton: FunctionComponent<Props> = memo<Props>(function LogoutButton({ type, className, eventName, children }) {
  const [suspense, setSuspense] = useState<Promise<void> | null>(null)
  if (suspense) throw suspense
  const { handleAction } = useStackContext()
  const dispatch = useAppDispatch()
  const handleClick = useCallback(async () => {
    setSuspense(handleAction(new Promise(() => {})))
    setTimeout(async () => {
      const { destroySession } = await dispatch(withProgress(IMPORT_MAP.actions.session()))
      dispatch(destroySession())
    }, 10)
  }, [dispatch, handleAction])

  return (
    <Button className={className} eventName={eventName} onClick={handleClick} type={type}>
      {children}
    </Button>
  )
})
