import { createSelector } from 'reselect'

import { navigate } from '@app/store/actions/router.descriptors'
import { createReduxSlice } from '@app/store/redux_slice'
import { createThunk } from '@app/store/thunk'

const uiMenuSlice = createReduxSlice<{ opened: boolean }>('ui_menu')
uiMenuSlice.addCase(navigate.shape, (state, action) => {
  if (action.payload.action !== 'REPLACE' || action.payload.location.state?.link) {
    return { ...state, opened: false }
  }
  return state
})

export const sideMenuOpenedSelector = createSelector(uiMenuSlice.selector, state => !!state?.opened)

export function toggleSideMenu() {
  return createThunk(dispatch => {
    const opened = !!dispatch(uiMenuSlice.get())?.opened

    dispatch(uiMenuSlice.set({ opened: !opened }))
  })
}

export function hideSideMenuIfOpened() {
  return createThunk(dispatch => {
    const opened = !!dispatch(uiMenuSlice.get())?.opened
    if (opened) dispatch(uiMenuSlice.set({ opened: false }))
  })
}
