import md5 from 'blueimp-md5'
import trim from 'lodash/trim'
import { defineMessages } from 'react-intl'
import { createSelector } from 'reselect'

import { AbortError } from '@app/errors/AbortError'

import { AddressManager } from '@app/utils/AddressManager'
import { assertNever } from '@app/utils/assertNever'
import { captureException } from '@app/utils/errorReport/errorReport'
import { isValidEmail } from '@app/utils/isValidEmail'
import { assertTimezoneValid } from '@app/utils/moment'
import { DEFAULT_CURRENCY } from '@app/utils/printCurrency'
import { RegionsExtensionData } from '@app/utils/regions'
import { matchRegion } from '@app/utils/routing/region'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { createAddress } from '@app/packages/geo/Address'
import { intoResult, Result, unwrapResult } from '@app/packages/Result/Result'

import { getUsersMe } from '@app/store/actions/api/users'
import { setSession } from '@app/store/actions/session'
import { asNetworkError } from '@app/store/apiMiddleware/errors'
import { createReduxSlice } from '@app/store/redux_slice'
import { intlSelector } from '@app/store/slices/intl'
import { profileHasAvatarsSelector } from '@app/store/slices/profile_avatars'
import { sitterDescriptionTemplateResultSelector } from '@app/store/slices/sitter_description_template'
import { StoreState } from '@app/store/store'

import { defaultLabelExtractor } from '@app/components/PlaceInput/shared'

import { countriesSelector } from './countries'
import { currenciesEnabledSelector } from './currencies'
import { localitiesSelector } from './localities'
import { placesSelector } from './places'
import { availableRegionsSlugsSelector, regionsModelsSelector } from './regions'
import { accessTokenSelector } from './session'

export const profileSelector = (state: StoreState) => state.profile
export const profileMetaSelector = (state: StoreState) => state.profile.meta
export const profileUpdateSelector = (state: StoreState) => state.profile.update

export const profileUserSelector = (state: StoreState) => state.profile.user

export const profileTimezoneOffsetSelector = createSelector([profileUserSelector], profile => {
  if (profile) return profile.utc_offset / 60
  if (IS_BROWSER) {
    return new Date().getTimezoneOffset() * -1
  }

  return 0
})

const profileUserTimezoneStringSelector = createSelector([profileUserSelector], profile => {
  return profile?.timezone
})

export const profilePhoneSelector = createSelector(profileUserSelector, user => {
  let phone: string | null = user && user.phone ? user.phone : null
  if (phone && phone.length > 0 && phone.indexOf('+') !== 0) {
    phone = `+${phone}`
  }

  return phone
})

export const profileUserAccountTypeSelector = createSelector(
  [profileUserSelector],
  user => user && user.account_type && (user.account_type.toLowerCase() as 'parent' | 'visitor' | 'sitter')
)
export const isProfileUserSignedInSelector = createSelector(
  [profileUserSelector],
  user => !!(user && (user.account_type === 'parent' || (user.account_type === 'sitter' && (user.approved || user.training_completed))))
)

export const profileUserIdSelector = (state: StoreState) => state.profile.user?.id ?? null
export const profileUserTokenSelector = (state: StoreState) => (state.profile.user?.account_type === 'sitter' ? state.profile.user.token : null)

const profileUserMetaSlice = createReduxSlice<{ hash: string }>('profile_user')
export const profileUserSyncSlice = createReduxSlice<{ hash: string }>('profile_user_sync')

export const profileUserResultSelector = createSelector(
  [profileUserMetaSlice.selector, profileUserSyncSlice.selector, accessTokenSelector, profileUserSelector],
  (meta, sync, token, user) =>
    intoResult(() => {
      if (!token) return null
      const hash = [md5(token), sync?.hash ?? ''].join(':')
      if (!meta || meta.hash !== hash)
        throw ActionRequiredError.create('User must be fetched', hash, async dispatch => {
          const resp = await dispatch(getUsersMe())
          if (!resp) throw new AbortError('User fetch aborted')
          if (resp.error && asNetworkError(resp.payload)?.status === 401) {
            await dispatch(setSession({ access_token: '' }))
            return undefined
          }

          await dispatch(setSession({ access_token: token }))

          dispatch(profileUserMetaSlice.set({ hash }))
        })
      return user
    })
)

export const profileUserAccountTypeResultSelector = createSelector([profileUserResultSelector], userResult =>
  intoResult(() => {
    const user = unwrapResult(userResult)
    return user && user.account_type && (user.account_type.toLowerCase() as 'parent' | 'visitor' | 'sitter')
  })
)

export const registrationCompleteSelector = (state: StoreState) =>
  !!(state.profile.user && state.profile.user.account_type !== 'visitor' && state.profile.user.registration_completed)

export const profileIsParentSelector = createSelector([profileUserAccountTypeSelector], accountType => accountType === 'parent')
export const profileIsSitterSelector = createSelector([profileUserAccountTypeSelector], accountType => accountType === 'sitter')
export const profileIsVisitorSelector = createSelector([profileUserAccountTypeSelector], accountType => accountType === 'visitor')

export const profilePlaceStateSelector = (state: StoreState) => state.profile.place
export const profilePlaceIdSelector = (state: StoreState) => state.profile.place.place_id
export const profilePlaceDataSelector = createSelector(
  [profilePlaceIdSelector, placesSelector, regionsModelsSelector, countriesSelector, localitiesSelector],
  (id, places, regions, countries, localities) => {
    if (!id) throw new Error('Profile place id is empty')
    const place = places[id]
    const region = regions[place.relationships.region.data!.id]
    const country = (place.relationships.country?.data?.id && countries[place.relationships.country.data.id]) || null
    const locality = (place.relationships.locality?.data?.id && localities[place.relationships.locality.data.id]) || null
    return { place, region, country, locality }
  }
)
export const profilePlaceSelector = createSelector([profilePlaceDataSelector], data => data.place)
export const profileRegionSelector = createSelector([profilePlaceDataSelector], data => {
  return {
    ...data.region,
    attributes: { ...data.region.attributes, ...RegionsExtensionData[data.region.attributes.slug as keyof typeof RegionsExtensionData] },
  }
})
export const profilePlaceInputValueSelector = profilePlaceDataSelector

export const profileUserTimezoneSelector = createSelector([profileUserTimezoneStringSelector, profileRegionSelector], (profileTimezone, region) => {
  const tz = (() => {
    if (profileTimezone) return profileTimezone
    if (IS_BROWSER) return Intl.DateTimeFormat().resolvedOptions().timeZone

    return 'Europe/Moscow'
  })()

  try {
    assertTimezoneValid(tz)
  } catch (e) {
    captureException(e)
    return region.attributes.timezone
  }
  return tz
})

export const profilePlaceNameSelector = createSelector([profilePlaceDataSelector], data => defaultLabelExtractor(data))

export const profilePlaceAddressSelector = createSelector([profilePlaceNameSelector, profilePlaceDataSelector], (name, data) =>
  createAddress({
    label: name,
    location: { lat: data.place.attributes.latitude, lon: data.place.attributes.longitude },
  })
)

export const profileRegionIsChangeableSelector = (state: StoreState) =>
  !(
    state.profile.user &&
    (('region_id' in state.profile.user && state.profile.user.region_id) || ('place_id' in state.profile.user && state.profile.user.place_id))
  )

/** landing page's region */
export const profileRegionSlugSelector = createSelector([profileRegionSelector, availableRegionsSlugsSelector], (region, slugs) =>
  matchRegion(slugs, region.attributes.slug)
)
/** landing page's region */
export const profileRegionIdSelector = createSelector(profileRegionSelector, region => region.id)

export const profileSubscriptionPriceSelector = createSelector(
  profileRegionSelector,
  region => region.attributes.subscription_options.find(o => o.period_months === 1)!.price_for_month
)
export const profileCommissionPriceSelector = createSelector(profileRegionSelector, region => region.attributes.commission_fee)

export const profileMapLocationSelector = createSelector([profilePlaceNameSelector, profilePlaceSelector], (name, place) =>
  createAddress({
    label: name,
    location: { lat: place.attributes.latitude, lon: place.attributes.longitude },
  })
)

export const profileCurrencySelector = createSelector([profileUserSelector, currenciesEnabledSelector], (profile, enabled) => {
  if (!enabled) return DEFAULT_CURRENCY
  if (profile?.account_type === 'sitter') return profile.rate_currency
  if (profile?.account_type === 'parent') return profile.display_currency
  return DEFAULT_CURRENCY
})

export const profileSitterAddressSelector = createSelector([profileUserSelector], user => {
  if (user?.account_type !== 'sitter') return undefined
  if (!user.location) return undefined
  return AddressManager.fromLocation(user.location).address
})

export type ProfileCheckUser =
  | {
      account_type: 'sitter'
      first_name: string
      last_name: string
      birthday: string
      email: string
      timezone: string
      location: unknown
      about: string
      hasAvatars: boolean
      rate: string
      rate_for_2_kids: string
      rate_for_3_kids: string
      rate_for_4_kids: string
      rate_for_5_kids: string
      rate_online: string
      animals: string
    }
  | { account_type: 'parent'; first_name: string; last_name: string; email: string; place: unknown; timezone: string }

export const createProfileCompletionErrorsResultSelector = (profileSelector: (state: StoreState) => Result<ProfileCheckUser | null>) =>
  createSelector([profileSelector, sitterDescriptionTemplateResultSelector, intlSelector], (profileResult, templateResult, { formatMessage }) =>
    intoResult(() => {
      const profile = unwrapResult(profileResult)
      if (!profile) return null
      const errors: string[] = []
      if (profile.account_type === 'sitter') {
        if (!isValidString(profile.first_name)) errors.push(formatMessage(completionErrors.name))
        if (!isValidString(profile.last_name)) errors.push(formatMessage(completionErrors.lastname))
        if (!profile.birthday || profile.birthday.length !== 10) errors.push(formatMessage(completionErrors.birthday))
        if (!isValidEmail(profile.email)) errors.push(formatMessage(completionErrors.email))
        if (!profile.timezone) errors.push(formatMessage(completionErrors.address))
        if (!profile.location) errors.push(formatMessage(completionErrors.address))

        const about = profile.about?.trim()
        const template = unwrapResult(templateResult)
        if (!isValidString(about) || about === template) errors.push(formatMessage(completionErrors.about))

        if (!profile.hasAvatars) errors.push(formatMessage(completionErrors.avatar))
        if (!isValidRate(profile.rate)) errors.push(formatMessage(completionErrors.rate))
        if (!isValidRate(profile.rate_for_2_kids)) errors.push(formatMessage(completionErrors.rate_2))
        if (!isValidRate(profile.rate_for_3_kids)) errors.push(formatMessage(completionErrors.rate_3))
        if (!isValidRate(profile.rate_for_4_kids)) errors.push(formatMessage(completionErrors.rate_4))
        if (!isValidRate(profile.rate_for_5_kids)) errors.push(formatMessage(completionErrors.rate_5))
        if (!isValidRate(profile.rate_online)) errors.push(formatMessage(completionErrors.rate_online))
        if (!isValidString(profile.animals)) errors.push(formatMessage(completionErrors.animals))
      } else if (profile.account_type === 'parent') {
        if (!profile.first_name) errors.push(formatMessage(completionErrors.name))
        if (!profile.last_name) errors.push(formatMessage(completionErrors.lastname))
        if (!profile.email) errors.push(formatMessage(completionErrors.email))
        if (!profile.place) errors.push(formatMessage(completionErrors.region))
        if (!profile.timezone) errors.push(formatMessage(completionErrors.timezone))
      } else {
        assertNever(profile)
      }
      if (!errors.length) return null
      return errors
    })
  )

const profileCheckUserResultSelector = createSelector([profileUserResultSelector, profileHasAvatarsSelector], (profileResult, hasAvatars) =>
  intoResult((): ProfileCheckUser | null => {
    const user = unwrapResult(profileResult)
    if (!user) return null
    switch (user.account_type) {
      case 'parent': {
        return {
          account_type: 'parent',
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email ?? '',
          place: String(user.place_id),
          timezone: user.timezone,
        }
      }
      case 'sitter': {
        return {
          account_type: 'sitter',
          first_name: user.first_name,
          last_name: user.last_name,
          birthday: user.birthday,
          email: user.email,
          timezone: user.timezone,
          location: user.location,
          about: user.about,
          hasAvatars,
          rate: String(user.rate),
          rate_for_2_kids: String(user.rate_for_2_kids),
          rate_for_3_kids: String(user.rate_for_3_kids),
          rate_for_4_kids: String(user.rate_for_4_kids),
          rate_for_5_kids: String(user.rate_for_5_kids),
          rate_online: String(user.rate_online),
          animals: user.animals,
        }
      }
      case 'visitor':
      default:
        return null
    }
  })
)

export const profileCompletionErrorsSelector = createProfileCompletionErrorsResultSelector(profileCheckUserResultSelector)
export const profileCompletedSelector = createSelector([profileCompletionErrorsSelector], list => !list)

function isValidString(val: string) {
  const trimmedString = trim(val || '', ' \n')
  return !!trimmedString && trimmedString.length > 0
}

function isValidRate(string: string) {
  const val = parseFloat(string)
  return !!val && val > 0
}

const completionErrors = defineMessages({
  name: 'Укажите имя',
  lastname: 'Укажите фамилию',
  birthday: 'Укажите дату рождения',
  region: 'Укажите регион',
  email: 'Укажите Email',
  address: 'Укажите адрес',
  about: 'Напишите несколько слов о себе',
  avatar: 'Добавьте аватар',
  rate: 'Укажите оплату за час',
  rate_2: 'Укажите оплату за двух детей',
  rate_3: 'Укажите оплату за трех детей',
  rate_4: 'Укажите оплату за 4-х детей',
  rate_5: 'Укажите оплату за 5-x детей',
  rate_online: 'Укажите оплату за онлайн',
  animals: 'Опишите отношение к животным',
  timezone: 'Укажите часовой пояс',
})
