import { beforeNavigate } from '@app/store/actions/router.descriptors'
import { ReduxMiddleware } from '@app/store/reduxMiddleware/types'

import { getMount } from './actions'

export const mountMiddleware: ReduxMiddleware<typeof beforeNavigate.shape> = store => next => action => {
  if (IS_BROWSER && action.type && action.type === beforeNavigate.shape.type) {
    store.dispatch(getMount())?.autoclose()
  }
  return next(action)
}
