import { UNICODE_CHAR } from './unicodeChar'

export enum Currency {
  RUB = 'RUB',
  KZT = 'KZT',
  USD = 'USD',
}

export const DEFAULT_CURRENCY = Currency.RUB

/** Prints number as currency */
export function printCurrency(value: string | number, currency: string | null | undefined): string {
  if (!value) value = 0
  if (typeof currency === 'undefined' || currency === '') currency = DEFAULT_CURRENCY
  if (typeof value === 'string' && !currencyRegex.test(value)) return 'NaN'
  const nbsp = UNICODE_CHAR.narrow_non_breaking_space
  value = typeof value === 'string' ? value.replace(comma, '.').replace(invalidChars, '') : value.toString(10)
  if (/^0+$/.test(value)) {
    value = '0'
  } else {
    value = value.replace(/^0+/, '')
  }
  const parts = splitToBaseAndRemain(value)
  const formatted = onlyZeros.test(parts[1]) ? splitToDivisions(parts[0]).join(nbsp) : `${splitToDivisions(parts[0]).join(nbsp)}.${parts[1]}`

  if (currency === null) {
    return formatted
  } else if (SUFFIX_SYMBOL[currency]) {
    return `${formatted}${nbsp}${SUFFIX_SYMBOL[currency]}`
  } else if (PREFIX_SYMBOL[currency]) {
    return `${PREFIX_SYMBOL[currency]}${formatted}`
  }
  return `${formatted}${nbsp}${currency}`
}

export function getCurrencySymbol(currency: string) {
  return SUFFIX_SYMBOL[currency] || PREFIX_SYMBOL[currency] || currency
}

const SUFFIX_SYMBOL: Record<string, string> = {
  RUB: '₽',
  KZT: '₸',
  AMD: '֏',
  TRY: '₺',
  AZN: '₼',
}

const PREFIX_SYMBOL: Record<string, string> = {
  EUR: '€',
  USD: '$',
  GEL: '₾',
}

const currencyRegex = /\d+(\.|,)?\d?/
const onlyZeros = /^0+$/
const comma = /,/g
const invalidChars = /[^\d.]+/g

const splitToBaseAndRemain = (input: string) => {
  const splitted = input.split('.')
  if (splitted.length < 2) {
    splitted.push('00')
  }
  splitted[1] = splitted[1].replace(/0+$/g, '').padEnd(2, '0')
  return splitted as [base: string, remain: string]
}

const splitToDivisions = (input: string): string[] => {
  const l = input.length
  if (l <= 3) return [input]
  const r = l % 3
  const p: string[] = []
  if (r !== 0) {
    p.push(input.substring(0, r))
  }
  for (let i = r; i < input.length; i = i + 3) {
    p.push(input.substring(i, i + 3))
  }
  return p
}
