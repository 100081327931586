import {
  Breadcrumb,
  getCurrentScope,
  addBreadcrumb as sentryAddBreadcrumb,
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
  SeverityLevel,
  withScope,
} from '@sentry/react'

import config from '@app/config'

import { getCurrentStackPlace } from '@app/utils/getCurrentStackPlace'

import { asError } from '@app/packages/asError/asError'

import { StoreProfileUser } from '@app/store/types/profile'

export type Severity = SeverityLevel

interface CaptureParams {
  /** data must be JSON serializable */
  extra?: { [key: string]: any }
  tags?: { [key: string]: string }
  level?: Severity
  silent?: boolean
}

export function captureMessage(message: string, params?: CaptureParams) {
  if (config.sentry) {
    withScope(scope => {
      if (params) {
        if (params.extra) {
          for (const [key, value] of Object.entries(params.extra)) {
            scope.setExtra(key, value)
          }
        }
        if (params.tags) {
          for (const [key, value] of Object.entries(params.tags)) {
            scope.setTag(key, value)
          }
        }
        if (params.level) {
          scope.setLevel(params.level)
        }
      }
      sentryCaptureMessage(message)
    })
  } else {
    console.warn('[Capture message]', getCurrentStackPlace(1))
    console.warn(message)
  }
}

/**
 * Captures exceptions and sends to Sentry. Client only.
 *
 * @param err error string
 */
export function captureException(err: any, params?: CaptureParams) {
  if (config.sentry) {
    withScope(scope => {
      if (params) {
        if (params.extra) {
          for (const [key, value] of Object.entries(params.extra)) {
            scope.setExtra(key, value)
          }
        }
        if (params.tags) {
          for (const [key, value] of Object.entries(params.tags)) {
            scope.setTag(key, value)
          }
        }
        if (params.level) {
          scope.setLevel(params.level)
        }
      }
      sentryCaptureException(err)
    })
  } else {
    logLocal(err)
    console.error('[Capture exception]', getCurrentStackPlace(1))
    console.error(err)
  }
  if (params && !params.silent) console?.error?.(err, params)
}

export function addBreadcrumb(breadcrumb: Breadcrumb) {
  if (config.sentry) {
    sentryAddBreadcrumb(breadcrumb)
  }
}

export function identify(user: StoreProfileUser) {
  if (!user) return
  const token = 'token' in user ? user.token : undefined
  getCurrentScope().setUser({ id: user.id, token })
}

const logLocal = (err: Error) => {
  window.navigator.sendBeacon('/internal/sentry', JSON.stringify(serizlizeError(err)))
}

const serizlizeError = (err: Error) => {
  return { name: err.name, message: err.message, stack: err.stack, cause: err.cause ? serizlizeError(asError(err.cause)) : undefined }
}
